import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { NavLink, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { LucyToaster } from '../../../components/Toaster';
import { LucyApi } from '../../../config';
import { ICommentResponse, ICreateComment, IPrecedentResponse } from '../../../types';
import { getAccessToken } from '../../../utils';
import PrecedentDelete from './PrecedentDelete';
import PrecedentDetails from './PrecedentDetails';
import PrecedentEdit from './PrecedentEdit';
import { LucyBackdrop } from '../../../components/Backdrop';
import { Button, TextField } from '@material-ui/core';
import { CAN } from '../../../permissions';

const validationSchema = Yup.object().shape({
  subject: Yup.string().trim().required('Required'),
  body: Yup.string().trim().required('Required'),
});
interface params {
  pId: string;
}

interface IData {
  subject: ICreateComment['comment']['subject'];
  body: ICreateComment['comment']['body'];
}
const fetchPrecedent = async (pId: string) => {
  const response = await LucyApi.get(`/precedent/${pId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};
const fetchComment = async (pId: string) => {
  const response = await LucyApi.get(`/pcomment/${pId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};
const PrecedentLanding = () => {
  let { path } = useRouteMatch();
  const params = useParams<params>();
  const queryClient = useQueryClient();

  const precedentDetails = useQuery<IPrecedentResponse, Error>(
    ['precedent-details', params.pId],
    async (context) => {
      return await fetchPrecedent(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  const precedentComments = useQuery<ICommentResponse[], Error>(
    ['precedent-comments', params.pId],
    async (context) => {
      return await fetchComment(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const body: ICreateComment = {
        comment: data,
        pId: parseInt(params.pId),
        parentCommentId: 0,
      };
      await LucyApi.post('/pcomment', body, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      queryClient.invalidateQueries(['precedent-comments', params.pId]);
      reset();
      LucyToaster('New comment added.', 'success');
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <div className="space-y-6 xl:flex xl:space-y-0 xl:space-x-6">
      <div className="bg-gray-100 space-y-5 py-4 px-6 xl:w-64 xl:h-full">
        <p className="text-sm text-primary-400 font-semibold">Comments</p>
        <LucyBackdrop open={isSubmitting} />
        <div className="space-y-2">
          {precedentComments.data && precedentComments.data.length > 0 ? (
            precedentComments.data.map((r, i) => (
              <div className="space-y-2 pb-2 border-b-2" key={i}>
                <h2>
                  <Link
                    to={`/user/${r.createdBy.uId}`}
                    className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                  >
                    {r.createdBy.userName}
                  </Link>
                </h2>
                <p>{format(new Date(r.createdAt), 'hh:mm a, do MM, yyyy')}</p>
                <div className="space-y-1">
                  <h2 className="font-semibold">{r.comment.subject}</h2>
                  <p>"{r.comment.body}"</p>
                </div>
              </div>
            ))
          ) : precedentComments.isLoading ? (
            <p>Loading...</p>
          ) : (
            <p>No comments</p>
          )}
        </div>
        <form onSubmit={onSubmit} className="space-y-4">
          <div>
            <TextField
              className="text-sm"
              fullWidth
              inputRef={register}
              error={!!errors.subject}
              name="subject"
              variant="outlined"
            />
            <p className="text-gray-700 text-sm mt-4">Comment Subject</p>
            {errors.subject && <p className="text-red-600 text-sm font-semibold mt-1">{errors.subject.message}</p>}
            <p>
              <br />
            </p>
            <TextField fullWidth inputRef={register} error={!!errors.body} name="body" variant="outlined" />
            <p className="text-gray-700 text-sm mt-4">Comment Body</p>
            {errors.body && <p className="text-red-600 text-sm font-semibold mt-1">{errors.body.message}</p>}
          </div>
          <div className="flex flex-row-reverse mt-10">
            <Button size="small" variant="contained" color="primary" type="submit">
              Add comment
            </Button>
          </div>
        </form>
      </div>
      <div className="space-y-4 xl:flex-1">
        <h1 className="font-medium text-3xl">{precedentDetails.data?.precedentId}</h1>
        <div className="space-y-8">
          <div className="flex">
            <NavLink
              exact
              to={`/precedent/${params.pId}`}
              activeClassName="text-secondary-400"
              className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
            >
              View
            </NavLink>
            {CAN('update', 'precedent') && (
              <NavLink
                to={`/precedent/${params.pId}/edit`}
                activeClassName="text-secondary-400"
                className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
              >
                Edit
              </NavLink>
            )}
            {CAN('delete', 'precedent') && (
              <NavLink
                to={`/precedent/${params.pId}/delete`}
                activeClassName="text-secondary-400"
                className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
              >
                Delete
              </NavLink>
            )}
          </div>
          <div>
            <Switch>
              <Route exact path={`${path}`} component={PrecedentDetails} />
              <Route exact path={`${path}/edit`} component={PrecedentEdit} />
              <Route exact path={`${path}/delete`} component={PrecedentDelete} />
            </Switch>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 space-y-5 py-4 px-6 xl:w-64 xl:h-full">
        <p className="text-sm text-primary-400 font-semibold">Related Precedents</p>
      </div>
    </div>
  );
};

export default PrecedentLanding;
