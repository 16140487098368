import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import {
  calculatePaginationFromTo,
  completionStatusValues,
  generateQueryString,
  getAccessToken,
  precedentStatusValues,
} from '../../../utils';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer } from '@material-ui/core';
import { useQuery } from 'react-query';
import { LucyApi, s3FileUrl } from '../../../config';
import { completionStatusType, IPaginationInfo, IPrecedentResponse, precedentStatusType } from '../../../types';
import LucyIcon from '../../../components/icons';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
  formControl: {
    width: '100%',
  },
});

interface IPrecedentList {
  data: IPrecedentResponse[];
  paginationInfo: IPaginationInfo;
}

interface IStatusCountResponse {
  completionStatus: {
    null?: number;
    medium?: number;
    high?: number;
    low?: number;
    complete?: number;
  };
  precedentStatus: {
    null?: number;
    needsWork?: number;
    'n/a'?: number;
    approved?: number;
    termForUx?: number;
  };
}

interface IQueryData {
  completionStatus: string;
  precedentStatus: string;
  pageSize: number;
  pageNumber: number;
}

const fetchPrecedentStatusCount = async () => {
  const response = await LucyApi.get('/precedent/statusCount', {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const fetchPrecedents = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/precedent?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const Overview = () => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const [completionStatus, setCompletionStatus] = useState<string>('');
  const [precedentStatus, setPrecedentStatus] = useState<string>('');

  const handlePrecedentStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageNumber(1);
    setPrecedentStatus(event.target.value as string);
  };
  const handleCompletionStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageNumber(1);
    setCompletionStatus(event.target.value as string);
  };

  const statusCount = useQuery<IStatusCountResponse, Error>('precedent-status-count', fetchPrecedentStatusCount, {
    refetchOnWindowFocus: false,
  });

  const precedents = useQuery<IPrecedentList, Error>(
    ['precedent-overview', { completionStatus, precedentStatus, pageSize, pageNumber }],
    async (context) => {
      return await fetchPrecedents(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return (
    <div className="space-y-6 lg:space-y-0 lg:flex mx-auto">
      <div className="lg:w-64 flex-shrink-0 space-y-5 bg-gray-100 py-4 px-6">
        <p className="text-sm text-primary-400 font-semibold">Filter by completion / status</p>
        <div className="flex flex-col divide-y divide-gray-400 space-y-6">
          <div className="space-y-5">
            <FormControl color="primary" variant="outlined" className={classes.formControl}>
              <InputLabel id="completionStatus-select-filled-label">Completion Status</InputLabel>
              <Select
                labelId="completionStatus-select-filled-label"
                id="completionStatus-select-filled"
                name="completionStatus"
                label="Completion Status"
                value={completionStatus}
                onChange={handleCompletionStatusChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.entries(completionStatusValues).map((status, i) => (
                  <MenuItem key={i} value={status[0]}>
                    {status[1]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Degree of Completion</FormHelperText>
            </FormControl>
            <FormControl color="primary" variant="outlined" className={classes.formControl}>
              <InputLabel id="precedentStatus-select-filled-label">Precedent Status</InputLabel>
              <Select
                labelId="precedentStatus-select-filled-label"
                id="precedentStatus-select-filled"
                name="precedentStatus"
                label="Precedent Status"
                value={precedentStatus}
                onChange={handlePrecedentStatusChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.entries(precedentStatusValues).map((status, i) => (
                  <MenuItem key={i} value={status[0]}>
                    {status[1]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Status of Precedent</FormHelperText>
            </FormControl>
          </div>
          <div className="pt-5 space-y-5">
            <p className="text-sm text-primary-400 font-semibold">Precedent Levels of Completion</p>
            <div className="flex flex-col space-y-2">
              {statusCount.data &&
                Object.entries(statusCount.data.completionStatus)
                  .filter((status) => status[0] !== 'null')
                  .map((status, i) => (
                    <div key={i}>
                      {completionStatusValues[status[0] as completionStatusType]} (<span>{status[1]}</span>)
                    </div>
                  ))}
            </div>
          </div>
          <div className="pt-5">
            <p className="text-sm text-primary-400 font-semibold mb-5">Precedent Status</p>
            <div className="flex flex-col space-y-2">
              {statusCount.data &&
                Object.entries(statusCount.data.precedentStatus)
                  .filter((status) => status[0] !== 'null')
                  .map((status, i) => (
                    <div key={i}>
                      {precedentStatusValues[status[0] as precedentStatusType]} (<span>{status[1]}</span>)
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 px-6">
        <div className="space-y-5">
          <p className="text-xl">Precedent Overview</p>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    Precedent ID
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Logo
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Precedent Title
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Actor
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {precedents.data && precedents.data.data.length > 0 ? (
                  precedents.data.data.map(({ pId, precedentId, logo, precedentTitle, actor }) => (
                    <TableRow key={pId}>
                      <TableCell component="th">
                        <Link
                          to={`/precedent/${pId}`}
                          className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                        >
                          {precedentId}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        {logo ? (
                          <div className="h-14 w-14 ring-2 rounded-sm ring-primary-400 overflow-hidden">
                            <img
                              className="w-full h-full object-cover"
                              src={`${s3FileUrl}${logo?.url}`}
                              alt={logo?.name}
                            />
                          </div>
                        ) : (
                          'N / A'
                        )}
                      </TableCell>
                      <TableCell align="left">{precedentTitle}</TableCell>
                      <TableCell align="left">{actor}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      {precedents.isLoading ? 'Loading . . .' : 'No precedents found.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
