import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LucyIcon from '../../../components/icons';
import { lowercaseRegex, numericRegex, specialCharRegex, uppercaseRegex, getAccessToken } from '../../../utils';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LucyBackdrop } from '../../../components/Backdrop';
import { LucyApi } from '../../../config';
import { useHistory, useParams } from 'react-router-dom';
import { LucyToaster } from '../../../components/Toaster';
import { useState } from 'react';

interface IData {
  newPassword: string;
}
const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});
interface params {
  uId: string;
}
const ChangePassword = () => {
  const params = useParams<params>();
  const history = useHistory();
  const [error, setError] = useState<string>();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await LucyApi.post(
        '/cms/change-password',
        { ...data, uId: params.uId },
        {
          headers: {
            Authorization: getAccessToken(),
          },
        },
      );

      LucyToaster('Password updated', 'success');
      history.push(`/user/${params.uId}`);
    } catch (error) {
      setError(error?.response?.data?.message || 'Something went wrong!');
    }
  });

  return (
    <div>
      <div className="flex space-x-3 items-center">
        <h1 className="text-xl">Change Password</h1>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>

      <div className="max-w-3xl mt-10 mb-10">
        <LucyBackdrop open={isSubmitting} />
        <form onSubmit={onSubmit}>
          <div className="space-y-6">
            <div>
              <h2 className="text-lg text-primary-400 mb-4">New Password</h2>
              <TextField fullWidth variant="outlined" name="newPassword" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">New password of the user.</p>
              {errors.newPassword && (
                <p className="text-red-500 text-sm font-semibold mt-1">{errors.newPassword.message}</p>
              )}
            </div>

            <br />
          </div>

          <div className="space-y-2">
            {error && <p className="text-red-500 mt-1 text-sm font-semibold">{error}</p>}
            <Button size="large" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
              Edit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
