import decode from 'jwt-decode';
import {
  completionStatusType,
  fieldType,
  IPaginationInfo,
  precedentStatusType,
  subscriptionPlanType,
  isActiveType,
  userType,
  ITokenPayload,
  PermissionActions,
  PermissionSubjects,
} from '../types';
import { store } from '../stores';

// access token
export const setAccessToken = (token: string) => {
  window.localStorage.setItem('access-token', token);
};
export const removeAccessToken = () => {
  window.localStorage.removeItem('access-token');
};
export const getAccessToken = (): string | null => {
  let accessToken: string | null = null;
  if (typeof window !== undefined && window.localStorage.getItem('access-token')) {
    accessToken = window.localStorage.getItem('access-token');
    return accessToken;
  } else {
    removeAccessToken();
  }
  return accessToken;
};

// Auth checker
export const isAuthenticate = () => (store.getState().userReducer.user ? true : false);

// token expiry check
export const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = decode(token) as ITokenPayload;
    if (decoded.exp > Date.now() / 1000) {
      return false;
    } else return true;
  } catch (err) {
    return true;
  }
};

export const fixedVocabularies = {
  tag: { vId: 62, machine_name: 'tag' },
  category: { vId: 63, machine_name: 'category' },
  question: { vId: 64, machine_name: 'question' },
};

export const calculatePaginationFromTo = (
  data: IPaginationInfo,
): {
  from: number;
  to: number;
} => {
  let to = 0,
    from = 0;

  if (data.numberOfElements < data.pageSize) {
    from = data.numberOfElements === 0 ? 0 : data.pageSize * data.pageNumber - data.pageSize + 1;
    to = data.totalElements;
  } else {
    from = data.pageSize * data.pageNumber - data.pageSize + 1;
    to = data.pageSize * data.pageNumber;
  }

  return {
    from,
    to,
  };
};

export const logoMimetypeValues = ['image/webp', 'image/png', 'image/jpeg', 'image/jpg'];
export const PictureMimetypeValues = ['image/webp', 'image/png', 'image/jpeg', 'image/jpg'];

export const truncateReverse = (str: string, n: number) =>
  str.length > n
    ? '...' +
      str
        .split('')
        .reverse()
        .join('')
        .substr(0, n - 1)
        .split('')
        .reverse()
        .join('')
    : str;

// values
export const completionStatusValues: {
  [key in completionStatusType]: string;
} = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  complete: 'Complete',
};
export const precedentStatusValues: {
  [key in precedentStatusType]: string;
} = {
  'n/a': 'N/A',
  needsWork: 'Needs work',
  termForUx: 'Term for UX',
  approved: 'Approved',
};
export const subscriptionPlanValues: {
  [key in subscriptionPlanType]: string;
} = {
  free: 'free',
  base: 'base',
  enterprise: 'enterprise',
};

export const isActiveValues: {
  [key in isActiveType]: string;
} = {
  true: 'Active',
  false: 'Inactive',
};

export const userTypeValues: {
  [key in userType]: string;
} = {
  client: 'client',
  cms: 'cms',
};

export const fieldValues: {
  [key in fieldType]: string;
} = {
  text: 'Text',
  number: 'Numeric',
};

// Size
export const logoSizeLimit = 5;

// all regex
export const emailRegex = /^([a-z\d._-]+)@([a-z\d_-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*[0-9])/;
export const specialCharRegex = /(?=.*[\^$*.[\]{}()?"!@#%&/,><':;|_~`/-])/;
export const durationRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;

export const generateQueryString = <T>(data: T) => {
  let queryString = '';
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key as keyof T];
      if (value || typeof value === 'number') {
        if (queryString.length === 0) {
          queryString += `${key}=${value}`;
        } else {
          queryString += `&${key}=${value}`;
        }
      }
    }
  }
  return queryString;
};

export const permissionsData: {
  node: PermissionSubjects;
  action: PermissionActions;
}[] = [
  {
    node: 'precedent',
    action: 'create',
  },
  {
    node: 'precedent',
    action: 'read',
  },
  {
    node: 'precedent',
    action: 'update',
  },
  {
    node: 'precedent',
    action: 'delete',
  },
  {
    node: 'precedent',
    action: 'publish',
  },
  {
    node: 'precedent',
    action: 'approve',
  },
  {
    node: 'precedent',
    action: 'unlock',
  },
  {
    node: 'user',
    action: 'create',
  },
  {
    node: 'user',
    action: 'read',
  },
  {
    node: 'user',
    action: 'update',
  },
  {
    node: 'user',
    action: 'delete',
  },
  {
    node: 'role',
    action: 'create',
  },
  {
    node: 'role',
    action: 'read',
  },
  {
    node: 'role',
    action: 'update',
  },
  {
    node: 'role',
    action: 'delete',
  },
  {
    node: 'vocabulary',
    action: 'create',
  },
  {
    node: 'vocabulary',
    action: 'read',
  },
  {
    node: 'vocabulary',
    action: 'update',
  },
  {
    node: 'vocabulary',
    action: 'delete',
  },
];
