import React, { useCallback, useState } from 'react';
import { LucyModal } from '../../../components/Modal';
import { DeletePrecedent } from '../../../components/DeletePrecedent';

const PrecedentDelete = () => {
  const [showModal, setShowModal] = useState(false);
  const handleModalOnClose = useCallback(() => setShowModal(false), []);
  return (
    <div>
      <div className="" onClick={() => setShowModal(true)}>
        <button className="bg-primary-400 text-white text-xl px-8 py-4 rounded-md">Delete Precedent</button>
      </div>
      <LucyModal open={showModal} onClose={handleModalOnClose} title="Delete Precedent">
        <DeletePrecedent onClose={handleModalOnClose} />
      </LucyModal>
    </div>
  );
};

export default PrecedentDelete;
