import React from 'react';
import User from './User';
import Add from './Add';
import Cross from './Cross';
import ArrowUp from './ArrowUp';
import ArrowDown from './ArrowDown';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import Mic from './Mic';
import VisibilityOn from './VisibilityOn';
import VisibilityOff from './VisibilityOff';
import Check from './Check';
import Menu from './Menu';
import CheckboxChecked from './CheckboxChecked';
import I from './I';
import Growth from './Growth';
import Cost from './Cost';
import Differentiation from './Differentiation';
import CustomerEngagement from './CustomerEngagement';
import BusinessModelDisruption from './BusinessModelDisruption';
import DigitalDisruption from './DigitalDisruption';
import DigitalRoi from './DigitalRoi';
import Partnering from './Partnering';
import OrganizationalEffectiveness from './OrganizationalEffectiveness';
import Culture from './Culture';
import LucySearch from './LucySearch';
import LucyBrainstorm from './LucyBrainstorm';
import LucyCrowd from './LucyCrowd';
import ArrowCurved from './ArrowCurved';
import Bell from './Bell';
import Star from './Star';
import Plus from './Plus';
import Download from './Download';
import List from './List';
import Swap from './Swap';
import ArrowBoth from './ArrowBoth';

export type IconNames =
  | 'user'
  | 'circle-plus'
  | 'cross'
  | 'menu'
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'eye-visible'
  | 'eye-hidden'
  | 'circle-check'
  | 'mic'
  | 'checkbox-checked'
  | 'i'
  | 'bell'
  | 'growth'
  | 'cost'
  | 'differentiation'
  | 'customerEngagement'
  | 'businessModelDisruption'
  | 'digitalDisruption'
  | 'digitalRoi'
  | 'partnering'
  | 'organizationalEffectiveness'
  | 'culture'
  | 'arrow-curved'
  | 'lucySearch'
  | 'lucyBrainstorm'
  | 'lucyCrowd'
  | 'plus'
  | 'download'
  | 'star'
  | 'swap'
  | 'arrowboth'
  | 'list';

export interface IconProps {
  name: IconNames;
  className: string;
  style?: Object;
  fill?: string;
  width?: string | number;
  height?: string | number;
}

const LucyIcon = (props: IconProps) => {
  switch (props.name) {
    case 'menu':
      return <Menu {...props} />;
    case 'user':
      return <User {...props} />;
    case 'circle-plus':
      return <Add {...props} />;
    case 'cross':
      return <Cross {...props} />;
    case 'arrow-up':
      return <ArrowUp {...props} />;
    case 'arrow-down':
      return <ArrowDown {...props} />;
    case 'arrow-left':
      return <ArrowLeft {...props} />;
    case 'arrow-right':
      return <ArrowRight {...props} />;
    case 'mic':
      return <Mic {...props} />;
    case 'eye-visible':
      return <VisibilityOn {...props} />;
    case 'eye-hidden':
      return <VisibilityOff {...props} />;
    case 'i':
      return <I {...props} />;
    case 'circle-check':
      return <Check {...props} />;
    case 'checkbox-checked':
      return <CheckboxChecked {...props} />;
    case 'growth':
      return <Growth {...props} />;
    case 'cost':
      return <Cost {...props} />;
    case 'differentiation':
      return <Differentiation {...props} />;
    case 'customerEngagement':
      return <CustomerEngagement {...props} />;
    case 'businessModelDisruption':
      return <BusinessModelDisruption {...props} />;
    case 'digitalDisruption':
      return <DigitalDisruption {...props} />;
    case 'digitalRoi':
      return <DigitalRoi {...props} />;
    case 'partnering':
      return <Partnering {...props} />;
    case 'organizationalEffectiveness':
      return <OrganizationalEffectiveness {...props} />;
    case 'culture':
      return <Culture {...props} />;
    case 'lucySearch':
      return <LucySearch {...props} />;
    case 'lucyBrainstorm':
      return <LucyBrainstorm {...props} />;
    case 'lucyCrowd':
      return <LucyCrowd {...props} />;
    case 'arrow-curved':
      return <ArrowCurved {...props} />;
    case 'bell':
      return <Bell {...props} />;
    case 'star':
      return <Star {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'download':
      return <Download {...props} />;
    case 'swap':
      return <Swap {...props} />;
    case 'arrowboth':
      return <ArrowBoth {...props} />;
    case 'list':
      return <List {...props} />;
    default:
      return <span>no icon found with name: {props.name}</span>;
  }
};

export default LucyIcon;
