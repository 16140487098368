import React, { useState } from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ICurrentUserUpdate } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { emailRegex, getAccessToken } from '../utils';
import { LucyToaster } from './Toaster';
import { LucyApi, s3FileUrl } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from '../stores/IndexReducer';
import userImg from './../resources/images/user.png';
import axios from 'axios';
import { userUpdateAction } from '../stores/user/Actions';

const validationSchema = Yup.object().shape({
  userName: Yup.string().trim().required('Required'),
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
});

interface IProps {
  onClose: () => void;
}

export const pictureMimetypeValues = ['image/webp', 'image/png', 'image/jpeg', 'image/jpg'];
export const pictureSizeLimit = 5;

export const AccountEdit: React.FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const user = useSelector((state: IReducer) => state.userReducer.user);

  // * Picture
  const [isPictureLoading, setPictureLoading] = useState(false);
  const [pictureProgress, setPictureProgress] = useState(0);
  const [picture, setPicture] = useState<File>();
  const [pictureError, setPictureError] = useState<string>();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<ICurrentUserUpdate>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      userName: user?.userName,
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  });

  const handleChangePictureFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPicture(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (!['image/webp', 'image/png', 'image/jpeg', 'image/jpg'].includes(image.type)) {
          setPictureError('Only png, webp, jpg, and jpeg is allowed.');
          return;
        } else if (image.size / 1024 / 1024 > 5) {
          setPictureError(`Picture should be less then ${5} MB`);
          return;
        } else {
          setPicture(image);
          setPictureError(undefined);
          return;
        }
      }
    }
  };
  const handleResetPicture = () => {
    setPictureLoading(false);
    setPictureProgress(0);
    setPicture(undefined);
    setPictureError(undefined);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      setPictureProgress(0);
      if (user && picture) {
        setPictureLoading(true);
        const pictureType = picture.name.split('.').pop();
        const filename = `user/${user.uId}.${pictureType}`;

        const presignedurlRes = await LucyApi.post(
          '/presignedurl',
          { file: filename },
          {
            headers: {
              Authorization: getAccessToken(),
            },
          },
        );

        await axios.put(presignedurlRes.data.presigned_url, picture, {
          headers: {
            'Content-Type': picture.type,
          },
          onUploadProgress: (progress) => setPictureProgress(Math.round((progress.loaded / progress.total) * 100)),
        });

        data.picture = filename;
      }

      await LucyApi.put('/common/user-profile', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      dispatch(userUpdateAction(data));
      LucyToaster('Account information updated.', 'success');
      onClose();
    } catch (error) {
      setError(error?.response?.data?.message || 'Something went wrong!');
    }
  });

  return (
    <form onSubmit={onSubmit} className="sm:w-80 space-y-6">
      <div className="space-y-4">
        <div className="space-y-1">
          <h2 className="text-primary-400">User Avatar</h2>
          <div
            className="flex items-center flex-wrap"
            onDrop={(e) => {
              e.preventDefault();
              setPicture(undefined);
              const files = Array.from(e.dataTransfer.files);
              if (files.length > 1) {
                setPictureError('Drop only One file.');
              } else if (files.length === 1) {
                if (!pictureMimetypeValues.includes(files[0].type)) {
                  setPictureError('Only png, webp, jpg, and jpeg is allowed.');
                  return;
                } else if (files[0].size / 1024 / 1024 > pictureSizeLimit) {
                  setPictureError(`Picture should be less then ${pictureSizeLimit} MB`);
                  return;
                } else {
                  setPicture(files[0]);
                  setPictureError(undefined);
                  return;
                }
              }
            }}
          >
            <input
              accept="image/*"
              className="hidden"
              id="browse-picture-file-button"
              type="file"
              onChange={handleChangePictureFile}
            />
            <img
              className="h-20 w-20 object-cover border rounded-full mr-4"
              src={picture ? URL.createObjectURL(picture) : user?.picture ? `${s3FileUrl}${user?.picture}` : userImg}
              alt={user?.picture || 'user picture'}
            />

            <div className="flex-1 flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
              <label htmlFor="browse-picture-file-button" className="w-full">
                <div className="w-full text-center cursor-pointer bg-primary-400 text-white rounded-lg font-medium py-2">
                  Upload
                </div>
              </label>
              {picture && (
                <button onClick={handleResetPicture} className="w-full bg-gray-200 rounded-lg font-medium py-2">
                  Delete
                </button>
              )}
            </div>
          </div>
          {isPictureLoading && (
            <div className="flex items-center">
              <div className="flex-grow">
                <LinearProgress variant="determinate" value={pictureProgress} />
              </div>
              <p className="text-typGreen text-sm ml-2 font-semibold">{`${pictureProgress}%`}</p>
            </div>
          )}
          {pictureError && <p className="text-red-500 text-sm font-semibold">{pictureError}</p>}
        </div>

        <div className="space-y-1">
          <h2 className="text-primary-400">User Name</h2>
          <TextField fullWidth variant="outlined" name="userName" inputRef={register} />
          {errors.userName && <p className="text-red-500 text-sm font-semibold">{errors.userName.message}</p>}
        </div>
        <div className="space-y-1">
          <h2 className="text-primary-400">First Name</h2>
          <TextField fullWidth variant="outlined" name="firstName" inputRef={register} />
          {errors.firstName && <p className="text-red-500 text-sm font-semibold">{errors.firstName.message}</p>}
        </div>
        <div className="space-y-1">
          <h2 className="text-primary-400">Last Name</h2>
          <TextField fullWidth variant="outlined" name="lastName" inputRef={register} />
          {errors.lastName && <p className="text-red-500 text-sm font-semibold">{errors.lastName.message}</p>}
        </div>
        <div className="space-y-1">
          <h2 className="text-primary-400">Email</h2>
          <TextField fullWidth variant="outlined" name="email" inputRef={register} />
          {errors.email && <p className="text-red-500 text-sm font-semibold">{errors.email.message}</p>}
        </div>
      </div>
      {error && <p className="text-center text-red-500 mt-1 text-xs font-medium">{error}</p>}
      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full border border-secondary-400 text-black font-semibold rounded-xl bg-secondary-400 py-3 ${
          isSubmitting ? 'opacity-75 cursor-not-allowed' : 'opacity-100'
        }`}
      >
        {isSubmitting ? 'Updating ...' : 'Update Profile'}
      </button>
    </form>
  );
};
