import React, { useState } from 'react';
import { useQuery } from 'react-query';
import LucyIcon from '../../../components/icons';
import { LucyApi } from '../../../config';
import { IPaginationInfo } from '../../../types';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';

interface IData {
  precedentId: string;
  actor: string;
  pId: number;
  questions: number;
  outcomes: number;
  insights: number;
}

interface IPrecedentList {
  data: IData[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  pageSize: number;
  pageNumber: number;
}

const fetchPrecedents = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/term_data/filter/question-insights-outcomes?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const Questions = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const precedents = useQuery<IPrecedentList, Error>(
    ['precedent-list', { pageSize, pageNumber }],
    async (context) => {
      return await fetchPrecedents(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return (
    <div className="space-y-6 md:space-y-0 md:flex mx-auto">
      <div className="px-6 w-full">
        <div>
          <div className="space-y-5">
            <p className="text-xl">Questions / Insights / Outcomes Heat Map</p>
            <div className="flex flex-wrap justify-between items-baseline">
              {precedents.data && (
                <p className="text-sm text-gray-400">
                  Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                  {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                  {precedents.data.paginationInfo.totalElements}
                </p>
              )}
              {precedents.data && (
                <div className="flex">
                  <button
                    onClick={() => setPageNumber((prev) => prev - 1)}
                    disabled={!precedents.data.paginationInfo.previous}
                    className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                      precedents.data.paginationInfo.previous
                        ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    <LucyIcon name="arrow-left" className="fill-current h-6" />
                  </button>
                  <button
                    onClick={() => setPageNumber((prev) => prev + 1)}
                    disabled={!precedents.data.paginationInfo.next}
                    className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                      precedents.data.paginationInfo.next
                        ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    <LucyIcon name="arrow-right" className="fill-current h-6" />
                  </button>
                </div>
              )}
            </div>
            <div className=" overflow-x-auto">
              <table className="bg-gray-100 w-full border-collapse border-2 border-white">
                <thead>
                  <tr className=" bg-gray-400 text-white text-left font-semibold italic">
                    <th className="pl-2 text-center border-2 border-white">Precedent Name</th>
                    <th className="pl-2 text-center border-2 border-white">Actor</th>
                    <th className="pl-2 text-center border-2 border-white">Questions</th>
                    <th className="pl-2 text-center border-2 border-white">Insights</th>
                    <th className="pl-2 text-center border-2 border-white">Outcomes</th>
                  </tr>
                </thead>
                <tbody>
                  {precedents.data && precedents.data.data.length > 0 ? (
                    precedents.data.data.map((v) => {
                      return (
                        <tr key={v.pId} className="">
                          <td className="p-3 border-2 border-white">{v.precedentId}</td>
                          <td className="p-3 border-2 border-white">{v.actor}</td>
                          <td className="p-3 border-2 border-white">
                            {v.questions <= 2 ? (
                              <div className="bg-red-600 px-10 text-center">{v.questions}</div>
                            ) : v.questions <= 5 ? (
                              <div className="bg-yellow-300 px-10 text-center">{v.questions}</div>
                            ) : v.questions >= 6 ? (
                              <div className="bg-green-300 px-10 text-center">{v.questions}</div>
                            ) : null}
                          </td>
                          <td className="p-3 border-2 border-white">
                            {v.insights < 2 ? (
                              <div className="bg-red-600 px-10 text-center">{v.insights}</div>
                            ) : v.insights <= 5 ? (
                              <div className="bg-yellow-300 px-10 text-center">{v.insights}</div>
                            ) : v.insights <= 6 ? (
                              <div className="bg-green-300 px-10 text-center">{v.insights}</div>
                            ) : null}
                          </td>
                          <td className="p-3 border-2 border-white">
                            {v.outcomes === 0 ? (
                              <div className="bg-red-600 px-10 text-center">{v.outcomes}</div>
                            ) : v.outcomes === 1 ? (
                              <div className="bg-yellow-300 px-10 text-center">{v.outcomes}</div>
                            ) : v.outcomes > 1 ? (
                              <div className="bg-green-300 px-10 text-center">{v.outcomes}</div>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>{precedents.isLoading ? 'Loading . . .' : 'No precedents found.'}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="flex flex-wrap justify-between items-baseline">
                {precedents.data && (
                  <p className="text-sm text-gray-400">
                    Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                    {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                    {precedents.data.paginationInfo.totalElements}
                  </p>
                )}
                {precedents.data && (
                  <div className="flex">
                    <button
                      onClick={() => setPageNumber((prev) => prev - 1)}
                      disabled={!precedents.data.paginationInfo.previous}
                      className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                        precedents.data.paginationInfo.previous
                          ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                          : 'opacity-50 cursor-not-allowed'
                      }`}
                    >
                      <LucyIcon name="arrow-left" className="fill-current h-6" />
                    </button>
                    <button
                      onClick={() => setPageNumber((prev) => prev + 1)}
                      disabled={!precedents.data.paginationInfo.next}
                      className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                        precedents.data.paginationInfo.next
                          ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                          : 'opacity-50 cursor-not-allowed'
                      }`}
                    >
                      <LucyIcon name="arrow-right" className="fill-current h-6" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
