import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import Background from './Background';
import InsightsOutcomes from './InsightsOutcomes';
import Overview from './Overview';
import Questions from './Questions';

const OverviewLanding = () => {
  return (
    <div className="space-y-8 mt-2">
      <div className="md:flex-row flex flex-col space-y-2 md:space-y-0">
        <NavLink
          exact
          to="/precedent/overview"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          View Precedent List
        </NavLink>
        <NavLink
          to="/precedent/overview/view-precedent-backgrounds"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          View Precedent Backgrounds
        </NavLink>
        <NavLink
          to="/precedent/overview/view-insights-&-outcomes"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          View Insights & Outcomes
        </NavLink>
        <NavLink
          to="/precedent/overview/questions-insights-outcomes-heat-map"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          Questions Insights Outcomes Heat Map
        </NavLink>
      </div>
      <div>
        <Switch>
          <Route exact path="/precedent/overview" component={Overview} />
          <Route exact path="/precedent/overview/view-precedent-backgrounds" component={Background} />
          <Route exact path="/precedent/overview/view-insights-&-outcomes" component={InsightsOutcomes} />
          <Route exact path="/precedent/overview/questions-insights-outcomes-heat-map" component={Questions} />
        </Switch>
      </div>
    </div>
  );
};

export default OverviewLanding;
