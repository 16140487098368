import useMediaQuery from '@material-ui/core/useMediaQuery';

const screens = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};
export default function useBreakpoint(breakpoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl') {
  return useMediaQuery(`(min-width: ${screens[breakpoint]})`);
}
