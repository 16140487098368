import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getAccessToken, lowercaseRegex, numericRegex, specialCharRegex, uppercaseRegex } from '../utils';
import { LucyToaster } from './Toaster';
import { LucyApi } from '../config';
import LucyIcon from './icons';

interface IData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
  newPassword: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!')
    .notOneOf([Yup.ref('oldPassword')], 'Should not match with old password!'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword')], 'Password must be matched with new password!'),
});

interface IProps {
  onClose: () => void;
}

export const ChangePassword: React.FC<IProps> = ({ onClose }) => {
  const [error, setError] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async ({ confirmPassword, ...data }) => {
    try {
      await LucyApi.put('/common/change-password', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      LucyToaster('Successfully changed password.', 'success');
      onClose();
    } catch (error) {
      setError(error?.response?.data?.message || 'Something went wrong!');
    }
  });

  return (
    <form onSubmit={onSubmit} className="sm:w-80 space-y-6">
      <div className="space-y-4">
        <div className="space-y-1">
          <h2 className="text-primary-400">Old Password</h2>
          <TextField
            fullWidth
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            name="oldPassword"
            inputRef={register}
            error={!!errors.oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle oldPassword visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <LucyIcon name="eye-visible" className="h-5" />
                    ) : (
                      <LucyIcon name="eye-hidden" className="h-5" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.oldPassword && <p className="text-red-500 text-sm font-semibold">{errors.oldPassword.message}</p>}
        </div>
        <div className="space-y-1">
          <h2 className="text-primary-400">New Password</h2>
          <TextField
            fullWidth
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            name="newPassword"
            inputRef={register}
            error={!!errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle newPassword visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <LucyIcon name="eye-visible" className="h-5" />
                    ) : (
                      <LucyIcon name="eye-hidden" className="h-5" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.newPassword && <p className="text-red-500 text-sm font-semibold">{errors.newPassword.message}</p>}
        </div>
        <div className="space-y-1">
          <h2 className="text-primary-400">Confirm Password</h2>
          <TextField
            fullWidth
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            name="confirmPassword"
            inputRef={register}
            error={!!errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirmPassword visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <LucyIcon name="eye-visible" className="h-5" />
                    ) : (
                      <LucyIcon name="eye-hidden" className="h-5" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.confirmPassword && (
            <p className="text-red-500 text-sm font-semibold">{errors.confirmPassword.message}</p>
          )}
        </div>
      </div>
      {error && <p className="text-center text-red-500 mt-1 text-xs font-medium">{error}</p>}
      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full border border-secondary-400 text-black font-semibold rounded-xl bg-secondary-400 py-3 ${
          isSubmitting ? 'opacity-75 cursor-not-allowed' : 'opacity-100'
        }`}
      >
        {isSubmitting ? 'Saving ...' : 'SAVE'}
      </button>
    </form>
  );
};
