import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import LucyIcon from '../../../components/icons';
import { LucyApi } from '../../../config';
import { IPaginationInfo, IPrecedentResponse, ITerm, ITermResponse } from '../../../types';
import { calculatePaginationFromTo, fixedVocabularies, generateQueryString, getAccessToken } from '../../../utils';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
});

interface ITopCounts {
  tTitle: string;
  counts: number;
}

interface ITag extends Pick<ITermResponse, 'tId' | 'tTitle' | 'machine_name'> {}
interface ICategory extends Pick<ITermResponse, 'tId' | 'tTitle' | 'machine_name'> {}

interface IPrecedentList {
  data: {
    pId: IPrecedentResponse['pId'];
    precedentId: IPrecedentResponse['precedentId'];
    actor: IPrecedentResponse['actor'];
    tags?: ITag[];
    categories?: ICategory[];
  }[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  pageSize: number;
  pageNumber: number;
  terms: ITerm['tId'][];
}

interface ITermListResponse {
  data: ITermResponse[];
  paginationInfo: IPaginationInfo;
}

const fetchTopCategories = async () => {
  const response = await LucyApi.get('/vocabulary/topCategories', {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const fetchTopTags = async () => {
  const response = await LucyApi.get('/vocabulary/topTags', {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const fetchCategoryAndTags = async ({ terms, ...queryData }: IQueryData) => {
  if (terms.length > 0) {
    const response = await LucyApi.post(
      `/precedent/category-and-tags?${generateQueryString(queryData)}`,
      {
        tId: terms,
      },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      },
    );
    return response.data;
  } else {
    const response = await LucyApi.post(`/precedent/category-and-tags?${generateQueryString(queryData)}`, undefined, {
      headers: {
        Authorization: getAccessToken(),
      },
    });
    return response.data;
  }
};

const fetchTerms = async (queryData: { vId: ITerm['vId'] }) => {
  const response = await LucyApi.get(`/term_data?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const FilterByCategoriesAndTags = () => {
  const classes = useStyles();
  const [pageSize] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedTerms, setSelectedTerms] = useState<ITerm['tId'][]>([]);

  const topCategories = useQuery<ITopCounts[], Error>('top-categories', fetchTopCategories, {
    refetchOnWindowFocus: false,
  });

  const topTags = useQuery<ITopCounts[], Error>('top-tags', fetchTopTags, {
    refetchOnWindowFocus: false,
  });

  // * Categories
  const [selectedCategories, setSelectedCategories] = useState<{
    [key: number]: boolean;
  }>({});
  const categoryMethods = useQuery<ITermListResponse, Error>(
    ['terms', { vId: fixedVocabularies.category.vId }],
    async () => {
      return await fetchTerms({ vId: fixedVocabularies.category.vId });
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (categories) => {
        let newData = selectedCategories;
        if (categories.data.length > 0) {
          categories.data.forEach((c) => {
            newData[c.tId] = false;
          });
        }

        setSelectedCategories(newData);
      },
    },
  );
  const handleCategoryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategories((prev) => {
      return { ...prev, [parseInt(event.target.name)]: event.target.checked };
    });
  }, []);

  // * Tags
  const [selectedTags, setSelectedTags] = useState<ITerm['tId'][]>([]);
  const tagMethods = useQuery<ITermListResponse, Error>(
    ['terms', { vId: fixedVocabularies.tag.vId }],
    async () => {
      return await fetchTerms({ vId: fixedVocabularies.tag.vId });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  const handleTagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { options } = event.target as HTMLSelectElement;
    const values = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        values.push(parseInt(options[i].value));
      }
    }
    setSelectedTags(values);
  };

  const handleResetForm = () => {
    let resetData: {
      [key: number]: boolean;
    } = {};
    if (categoryMethods.data && categoryMethods.data.data.length > 0) {
      categoryMethods.data.data.forEach((c) => {
        resetData[c.tId] = false;
      });
    }

    setPageNumber(1);
    setSelectedCategories(resetData);
    setSelectedTags([]);
    setSelectedTerms([]);
  };
  const onSubmit = async () => {
    const categories: number[] = Object.entries(selectedCategories)
      .filter((v) => v[1])
      .map((v) => parseInt(v[0]));
    const termsArray = [...new Set([...selectedTags, ...categories])];

    setPageNumber(1);
    setSelectedTerms(termsArray);
  };

  const precedents = useQuery<IPrecedentList, Error>(
    ['filter-category-and-tag', { pageSize, pageNumber, terms: selectedTerms }],
    async (context) => {
      return await fetchCategoryAndTags(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return (
    <div className="space-y-6 md:space-y-0 md:flex mx-auto">
      <div className="flex-grow-0 bg-gray-100 py-4 px-6 space-y-5">
        <div className="flex flex-col divide-y divide-gray-400 space-y-6">
          <div>
            <p className="text-sm text-primary-400 font-semibold mb-5">Popular Categories</p>
            <div className="flex flex-col space-y-2">
              {topCategories.data && topCategories.data.length > 0 ? (
                topCategories.data.map((v, i) => (
                  <div key={i}>
                    <p>
                      {v.tTitle}
                      <span> ({v.counts})</span>
                    </p>
                  </div>
                ))
              ) : topCategories.isLoading ? (
                <p>Loading . . .</p>
              ) : (
                <p>No categories found.</p>
              )}
            </div>
          </div>
          <div>
            <p className="text-sm text-primary-400 font-semibold my-5">Popular Tags</p>
            <div className="flex flex-col space-y-2">
              {topTags.data && topTags.data.length > 0 ? (
                topTags.data.map((v, i) => (
                  <div key={i}>
                    <p>
                      {v.tTitle}
                      <span> ({v.counts})</span>
                    </p>
                  </div>
                ))
              ) : topTags.isLoading ? (
                <p>Loading . . .</p>
              ) : (
                <p>No tags found.</p>
              )}
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-primary-400 font-semibold mt-5">Filter by Categories</p>
            <FormGroup>
              {categoryMethods.data && categoryMethods.data.data.length > 0 ? (
                categoryMethods.data.data.map((c) => (
                  <FormControlLabel
                    key={c.tId}
                    control={
                      <Checkbox
                        color="primary"
                        name={c.tId.toString()}
                        checked={selectedCategories[c.tId] ? true : false}
                        onChange={handleCategoryChange}
                      />
                    }
                    label={c.tTitle}
                  />
                ))
              ) : categoryMethods.isLoading ? (
                <p className="text-sm">Loading ...</p>
              ) : (
                <p className="text-red-500 text-sm">No categories found.</p>
              )}
            </FormGroup>
            <p className="text-gray-700 text-sm">Filters selected category, including and children.</p>
          </div>
          <div>
            <p className="text-sm text-primary-400 font-semibold my-5">Filter by Tags</p>
            <FormControl className="w-full h-32">
              <Select
                color="primary"
                variant="outlined"
                multiple
                native
                value={selectedTags}
                onChange={handleTagChange}
              >
                {tagMethods.data &&
                  tagMethods.data.data.map((t) => (
                    <option key={t.tId} value={t.tId} className="mb-2">
                      {t.tTitle}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex space-x-4 pt-5">
            <Button onClick={handleResetForm} variant="outlined" color="primary">
              RESET
            </Button>
            <Button onClick={onSubmit} variant="contained" color="primary">
              APPLY
            </Button>
          </div>
        </div>
      </div>
      <div className="px-6 w-full">
        <div className="space-y-5">
          <p className="text-xl">Filter by Categories and Tags</p>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    Precedent
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Actor
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Tag
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Category
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {precedents.data && precedents.data.data.length > 0 ? (
                  precedents.data.data.map(({ pId, precedentId, actor, tags, categories }) => (
                    <TableRow key={pId}>
                      <TableCell component="td" scope="user">
                        <Link
                          to={`/precedent/${pId}`}
                          className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                        >
                          {precedentId}
                        </Link>
                      </TableCell>
                      <TableCell align="left">{actor}</TableCell>
                      <TableCell align="left">
                        <div className="flex flex-wrap -m-1">
                          {tags &&
                            tags.map((v) => (
                              <Link
                                key={v.tId}
                                to={`/vocabulary/term/${v.tId}`}
                                className="text-primary-400 text-sm font-semibold cursor-pointer underline m-1"
                              >
                                {v.tTitle}
                              </Link>
                            ))}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="flex flex-wrap -m-1">
                          {categories &&
                            categories.map((v) => (
                              <Link
                                key={v.tId}
                                to={`/vocabulary/term/${v.tId}`}
                                className="text-primary-400 text-sm font-semibold cursor-pointer underline m-1"
                              >
                                {v.tTitle}
                              </Link>
                            ))}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      {precedents.isLoading ? 'Loading . . .' : 'No precedents found.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterByCategoriesAndTags;
