import { useHistory, useParams } from 'react-router';
import { TextField, Button, MenuItem, LinearProgress } from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useQuery } from 'react-query';

import { LucyBackdrop } from '../../../components/Backdrop';
import { ReactHookFormSelect } from '../../../components/hooks/ReactHookForm';
import LucyIcon from '../../../components/icons';
import { LucyToaster } from '../../../components/Toaster';
import { LucyApi } from '../../../config';
import { IUpdateUser, IUserResponse, IRoleResponse, IPaginationInfo } from '../../../types';
import {
  emailRegex,
  getAccessToken,
  userTypeValues,
  isActiveValues,
  subscriptionPlanValues,
  PictureMimetypeValues,
  truncateReverse,
} from '../../../utils';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@material-ui/core';
import { useCallback } from 'react';
import axios from 'axios';

interface params {
  uId: string;
}

interface IRoleList {
  data: IRoleResponse[];
  paginationInfo: IPaginationInfo;
}

const fetchRoles = async () => {
  const response = await LucyApi.get(`/roles`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const fetchUser = async (uId: string) => {
  const response = await LucyApi.get(`/user/${uId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const validationSchema = Yup.object().shape({
  userName: Yup.string().trim().required('Required'),
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
  userType: Yup.string().required('Required').oneOf(['client', 'cms']),
  isActive: Yup.boolean().required('Required').default(true),
  subscriptionPlan: Yup.string().required('Required').oneOf(['free', 'base', 'enterprise']),
  subscriptionStart: Yup.string().trim().required('Required'),
  subscriptionEnd: Yup.string().trim().required('Required'),
});

function dateFormat(date: string) {
  let sd = new Date(date).toISOString().split(':');
  sd.pop();

  return sd.join(':');
}

const UserEdit = () => {
  const params = useParams<params>();
  const history = useHistory();
  const s3url = 'https://lucy-resources.s3.amazonaws.com';
  const [isPictureLoading, setPictureLoading] = useState(false);
  const [pictureProgress, setPictureProgress] = useState(0);
  const [isPictureHoverOnDrop, setPictureHoverOnDrop] = useState(false);
  const [picture, setPicture] = useState<File>();
  const [pictureError, setPictureError] = useState<string>();
  const handleChangePictureFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPicture(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (!PictureMimetypeValues.includes(image.type)) {
          setPictureError('Only png, webp, jpg, and jpeg is allowed.');
          return;
        } else {
          setPicture(image);
          setPictureError(undefined);
          return;
        }
      }
    }
  };

  const [selectedRoles, setSelectedRoles] = useState<{
    [key: number]: boolean;
  }>({});

  const roleMethods = useQuery<IRoleList, Error>(
    ['role-list'],
    async () => {
      return await fetchRoles();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleRoleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRoles((prev) => {
      return { ...prev, [parseInt(event.target.name)]: event.target.checked };
    });
  }, []);

  const userDetails = useQuery<IUserResponse, Error>(
    ['user-details', params.uId],
    async () => {
      return await fetchUser(params.uId);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (data) => {
        // * set default values
        reset({
          userName: data.userName,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          picture: data.picture,
          subscriptionPlan: data.subscriptionPlan,
          subscriptionStart: dateFormat(data.subscriptionStart),
          subscriptionEnd: dateFormat(data.subscriptionEnd),
          userType: data.userType,
          isActive: data.isActive,
        });
        let tempRoles = selectedRoles;
        data.roles.forEach((role) => {
          tempRoles[role.rId] = true;
        });
      },
    },
  );

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<IUpdateUser>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subscriptionPlan: 'free',
      isActive: true,
      userType: 'client',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      data.subscriptionStart = new Date(data.subscriptionStart).toISOString();
      data.subscriptionEnd = new Date(data.subscriptionEnd).toISOString();

      setPictureProgress(0);
      if (picture) {
        setPictureLoading(true);
        const pictureType = picture.name.split('.').pop();
        const filename = `user/${data.userName}.${pictureType}`;
        const presignedurlRes = await LucyApi.post(
          '/presignedurl',
          { file: filename },
          {
            headers: {
              Authorization: getAccessToken(),
            },
          },
        );
        await axios.put(presignedurlRes.data.presigned_url, picture, {
          headers: {
            'Content-Type': picture.type,
          },
          onUploadProgress: (progress) => setPictureProgress(Math.round((progress.loaded / progress.total) * 100)),
        });
        data.picture = filename;
      }

      await LucyApi.put(`/user/${params.uId}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      const roles: number[] = Object.entries(selectedRoles)
        .filter((v) => v[1])
        .map((v) => parseInt(v[0]));
      if (roles.length > 0) {
        await LucyApi.put(
          '/user_roles',
          {
            uId: params.uId,
            roles,
          },
          {
            headers: {
              Authorization: getAccessToken(),
            },
          },
        );
      }
      LucyToaster('User updated.', 'success');
      history.push(`/user/${params.uId}`);
    } catch (error) {
      if (error?.response?.data?.message === 'unique_userName') {
        setError('userName', {
          type: 'manual',
          message: 'User Already exists with the given username.',
          shouldFocus: true,
        });
      }
      if (error?.response?.data?.message === 'unique_email') {
        setError('email', {
          type: 'manual',
          message: 'User Already exists with the given email.',
          shouldFocus: true,
        });
      }
    }
  });
  return (
    <>
      {userDetails.data ? (
        <div>
          <div className="flex space-x-3 items-center">
            <h1 className="text-xl">Edit User</h1>
            <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
          </div>

          <div className="max-w-3xl mt-10 mb-10">
            <LucyBackdrop open={isSubmitting} />
            <form onSubmit={onSubmit}>
              <div className="space-y-6">
                {/* <input type="text" ref={register} name="logo.url" className="hidden" /> */}
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">User Name</h2>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="userName"
                    inputRef={register}
                    error={!!errors.userName}
                  />
                  {errors.userName && (
                    <p className="text-red-500 text-sm font-semibold mt-1">{errors.userName.message}</p>
                  )}
                </div>
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">First Name</h2>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="firstName"
                    inputRef={register}
                    error={!!errors.firstName}
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm font-semibold mt-1">{errors.firstName.message}</p>
                  )}
                </div>
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">Last Name</h2>
                  <TextField fullWidth variant="outlined" name="lastName" inputRef={register} />
                </div>
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">Email</h2>
                  <TextField fullWidth variant="outlined" name="email" inputRef={register} />
                </div>

                <div>
                  <h2 className="text-lg text-primary-400 mb-4">User Image</h2>
                  <div
                    className={`border ${isPictureHoverOnDrop ? 'border-dashed border-gray-300' : 'border-gray-300'} ${
                      pictureError ? 'border-red-500' : ''
                    } rounded-md`}
                    onDragEnter={() => {
                      setPictureHoverOnDrop(true);
                    }}
                    onDragLeave={() => {
                      setPictureHoverOnDrop(false);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      setPictureHoverOnDrop(true);
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      setPictureHoverOnDrop(false);
                      setPicture(undefined);
                      const files = Array.from(e.dataTransfer.files);
                      if (files.length > 1) {
                        setPictureError('Drop only One file.');
                      } else if (files.length === 1) {
                        if (!PictureMimetypeValues.includes(files[0].type)) {
                          setPictureError('Only png, webp, jpg, and jpeg is allowed.');
                          return;
                        }
                      } else {
                        setPicture(files[0]);
                        setPictureError(undefined);
                        return;
                      }
                    }}
                  >
                    <input
                      accept="image/*"
                      className="hidden"
                      id="browse-Picture-file-button"
                      type="file"
                      onChange={handleChangePictureFile}
                    />
                    <label htmlFor="browse-Picture-file-button">
                      {picture ? (
                        <div className="flex justify-start items-center p-6 cursor-pointer">
                          <div>{truncateReverse(picture.name, 30)}</div>
                        </div>
                      ) : userDetails.data.picture ? (
                        <div className="flex justify-center items-center p-6 cursor-pointer">
                          <div className="h-24 w-24 ring-2 rounded-sm ring-primary-400 overflow-hidden">
                            <img
                              className="w-full h-full object-cover"
                              src={`${s3url}/${userDetails.data.picture}`}
                              alt={userDetails.data.picture}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-start space-x-6 items-center p-6 cursor-pointer">
                          <LucyIcon name="download" className="fill-current text-primary-400 h-10" />
                          <p className="text-sm">
                            Click here to upload <span className="font-semibold text-base">Image</span>
                          </p>
                        </div>
                      )}
                    </label>
                  </div>
                  {isPictureLoading && (
                    <div className="flex items-center mt-1">
                      <div className="flex-grow">
                        <LinearProgress variant="determinate" value={pictureProgress} />
                      </div>
                      <p className="text-typGreen text-sm ml-2 font-semibold">{`${pictureProgress}%`}</p>
                    </div>
                  )}
                  {/* <p className="text-gray-700 text-sm mt-4">
                One file only and {logoSizeLimit} MB limit. Allowed types: png, webp, jpg & jpeg.
              </p> */}
                  {pictureError && <p className="text-red-500 text-sm font-semibold mt-1">{pictureError}</p>}
                </div>

                <div>
                  <h2 className="text-lg text-primary-400 mb-4">Subscription Plan type</h2>
                  <ReactHookFormSelect
                    name="subscriptionPlan"
                    variant="outlined"
                    error={!!errors.subscriptionPlan}
                    control={control}
                  >
                    {Object.entries(subscriptionPlanValues).map((status, i) => (
                      <MenuItem key={i} value={status[0]}>
                        {status[1]}
                      </MenuItem>
                    ))}
                  </ReactHookFormSelect>
                </div>
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">Subscription start date</h2>
                  <TextField
                    type="datetime-local"
                    fullWidth
                    variant="outlined"
                    name="subscriptionStart"
                    inputRef={register}
                  />
                </div>
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">Subscription end date</h2>
                  <TextField
                    type="datetime-local"
                    fullWidth
                    variant="outlined"
                    name="subscriptionEnd"
                    inputRef={register}
                  />
                </div>
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">User type</h2>
                  <ReactHookFormSelect name="userType" variant="outlined" error={!!errors.userType} control={control}>
                    {Object.entries(userTypeValues).map((status, i) => (
                      <MenuItem key={i} value={status[0]}>
                        {status[1]}
                      </MenuItem>
                    ))}
                  </ReactHookFormSelect>
                </div>

                {watch('userType') === 'cms' && (
                  <div className="space-y-2">
                    <h2 className="text-lg text-primary-400">Roles</h2>
                    <FormGroup>
                      {roleMethods.data && roleMethods.data.data.length > 0
                        ? roleMethods.data.data.map((c) => (
                            <FormControlLabel
                              key={c.rId}
                              control={
                                <Checkbox
                                  color="primary"
                                  name={c.rId.toString()}
                                  checked={!!selectedRoles[c.rId]}
                                  onChange={handleRoleChange}
                                />
                              }
                              label={c.name}
                            />
                          ))
                        : !roleMethods.isLoading && <p className="text-red-500 text-sm">No roles found.</p>}
                    </FormGroup>
                  </div>
                )}
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">User active status</h2>
                  <ReactHookFormSelect name="isActive" variant="outlined" error={!!errors.isActive} control={control}>
                    {Object.entries(isActiveValues).map((status, i) => (
                      <MenuItem key={i} value={status[0]}>
                        {status[1]}
                      </MenuItem>
                    ))}
                  </ReactHookFormSelect>
                </div>

                <Button size="large" variant="contained" color="primary" type="submit">
                  Edit
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        !userDetails.isLoading && <p>No user found</p>
      )}
    </>
  );
};

export default UserEdit;
