import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import UserList from './User_List';
import CreateUser from './Create_User';
import UserLanding from './details';

const UserPage = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to={`${path}/create`} />}></Route>
      <Route exact path={`${path}/create`}>
        <CreateUser />
      </Route>
      <Route path={`${path}/list`}>
        <UserList />
      </Route>
      <Route path={`${path}/:uId`}>
        <UserLanding />
      </Route>
      <Route path="*" render={() => <Redirect to={`${path}/create`} />}></Route>
    </Switch>
  );
};

export default UserPage;
