import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer } from '@material-ui/core';
import { IPaginationInfo, IPrecedentResponse } from '../../../types';
import { useQuery } from 'react-query';
import { LucyApi, s3FileUrl } from '../../../config';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';
import LucyIcon from '../../../components/icons';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
});

interface IPrecedentList {
  data: IPrecedentResponse[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  pageSize: number;
  pageNumber: number;
}

const fetchPrecedents = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/precedent?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const Overview = () => {
  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const precedents = useQuery<IPrecedentList, Error>(
    ['precedent-list', { pageSize, pageNumber }],
    async (context) => {
      return await fetchPrecedents(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return (
    <div className="space-y-6 md:space-y-0 md:flex mx-auto">
      <div className="px-6 w-full">
        <div>
          <div className="space-y-5">
            <p className="text-xl">Overview of Precedent Backgrounds</p>
            <div className="flex flex-wrap justify-between items-baseline">
              {precedents.data && (
                <p className="text-sm text-gray-400">
                  Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                  {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                  {precedents.data.paginationInfo.totalElements}
                </p>
              )}
              {precedents.data && (
                <div className="flex">
                  <button
                    onClick={() => setPageNumber((prev) => prev - 1)}
                    disabled={!precedents.data.paginationInfo.previous}
                    className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                      precedents.data.paginationInfo.previous
                        ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    <LucyIcon name="arrow-left" className="fill-current h-6" />
                  </button>
                  <button
                    onClick={() => setPageNumber((prev) => prev + 1)}
                    disabled={!precedents.data.paginationInfo.next}
                    className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                      precedents.data.paginationInfo.next
                        ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    <LucyIcon name="arrow-right" className="fill-current h-6" />
                  </button>
                </div>
              )}
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header} align="left">
                      Precedent ID
                    </TableCell>
                    <TableCell className={classes.header} align="left">
                      Logo
                    </TableCell>
                    <TableCell className={classes.header} align="left">
                      Actor
                    </TableCell>
                    <TableCell className={classes.header} align="left">
                      Background
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {precedents.data && precedents.data.data.length > 0
                    ? precedents.data.data.map(({ precedentId, logo, actor, background, pId }) => {
                        return (
                          <TableRow key={pId}>
                            <TableCell component="th">
                              <Link
                                to={`/precedent/${pId}`}
                                className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                              >
                                {precedentId}
                              </Link>
                            </TableCell>
                            <TableCell align="left">
                              {logo ? (
                                <div className="h-14 w-14 ring-2 rounded-sm ring-primary-400 overflow-hidden">
                                  <img
                                    className="w-full h-full object-cover"
                                    src={`${s3FileUrl}${logo?.url}`}
                                    alt={logo?.name}
                                  />
                                </div>
                              ) : (
                                'N / A'
                              )}
                            </TableCell>
                            <TableCell align="left">{actor}</TableCell>
                            <TableCell align="left">{background}</TableCell>
                          </TableRow>
                        );
                      })
                    : !precedents.isLoading && (
                        <TableRow>
                          <TableCell align="left" colSpan={4}>
                            No precedents found.
                          </TableCell>
                        </TableRow>
                      )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="flex flex-wrap justify-between items-baseline">
              {precedents.data && (
                <p className="text-sm text-gray-400">
                  Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                  {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                  {precedents.data.paginationInfo.totalElements}
                </p>
              )}
              {precedents.data && (
                <div className="flex">
                  <button
                    onClick={() => setPageNumber((prev) => prev - 1)}
                    disabled={!precedents.data.paginationInfo.previous}
                    className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                      precedents.data.paginationInfo.previous
                        ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    <LucyIcon name="arrow-left" className="fill-current h-6" />
                  </button>
                  <button
                    onClick={() => setPageNumber((prev) => prev + 1)}
                    disabled={!precedents.data.paginationInfo.next}
                    className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                      precedents.data.paginationInfo.next
                        ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    <LucyIcon name="arrow-right" className="fill-current h-6" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
