import React, { useEffect, useMemo } from 'react';
import { Divider, MenuItem, TextField } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import LucyIcon from '../../../../components/icons';
import { LucyApi } from '../../../../config';
import { IUpdateTerm, ITerm, ITermResponse, IVocabularyResponse, IPaginationInfo } from '../../../../types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactHookFormSelect } from '../../../../components/hooks/ReactHookForm';
import { generateQueryString, getAccessToken } from '../../../../utils';
import { LucyToaster } from '../../../../components/Toaster';
import { LucyBackdrop } from '../../../../components/Backdrop';

interface IParams {
  vId: string;
  tId: string;
}

interface IVocabularyTermListResponse {
  data: ITerm[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  vId: number;
}

const validationSchema = Yup.object().shape({
  tTitle: Yup.string().trim().required('Required'),
  vId: Yup.number().required('Required'),
  machine_name: Yup.string().required('Required'),
  parentId: Yup.string().required('Required'),
  tDescription: Yup.string().trim(),
  weight: Yup.number().required('Required'),
});

const fetchVocabulary = async (vId: string) => {
  const response = await LucyApi.get(`/vocabulary/${vId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};
const fetchTerm = async (tId: string) => {
  const response = await LucyApi.get(`/term_data/${tId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};
const fetchTerms = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/term_data?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const TermEdit = () => {
  const history = useHistory();
  const params = useParams<IParams>();

  const vocabularyDetails = useQuery<IVocabularyResponse, Error>(
    ['vocabulary-details', params.vId],
    async (context) => {
      return await fetchVocabulary(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const termDetails = useQuery<ITermResponse, Error>(
    ['term', params.tId],
    async (context) => {
      return await fetchTerm(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const terms = useQuery<IVocabularyTermListResponse, Error>(
    ['terms', { vId: params.vId }],
    async (context) => {
      return await fetchTerms(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const termsData = useMemo(
    () => (): ITerm[] => {
      if (terms.data) {
        return terms.data.data.filter((t) => t.tId.toString() !== params.tId);
      }

      return [];
    },
    [params.tId, terms.data],
  );

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<IUpdateTerm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      parentId: '0',
      weight: 0,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await LucyApi.put(`/term_data/${params.tId}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      LucyToaster('Term updated.', 'success');
      history.push(`/vocabulary/${params.vId}`);
    } catch (error) {
      console.log('error', error);
    }
  });

  useEffect(() => {
    if (termDetails.data) {
      reset({
        tTitle: termDetails.data.tTitle,
        tDescription: termDetails.data.tDescription,
        vId: termDetails.data.vId,
        parentId: termDetails.data.parentId?.toString(),
        machine_name: termDetails.data.machine_name,
        attributes: termDetails.data.attributes,
        weight: termDetails.data.weight,
      });
    }
  }, [reset, termDetails.data]);

  return (
    <div>
      <div className="flex space-x-3 items-center mb-8">
        <p className="text-xl ">Edit Term</p>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>
      <LucyBackdrop open={isSubmitting} />
      <form onSubmit={onSubmit} className="max-w-2xl">
        <div className="space-y-6 mb-8">
          <input type="number" ref={register} name="vId" className="hidden" />
          <input type="text" ref={register} name="machine_name" className="hidden" />
          <div>
            <p className="text-lg text-primary-400 mb-4 ">Name *</p>
            <TextField fullWidth inputRef={register} error={!!errors.tTitle} name="tTitle" variant="outlined" />
            {errors.tTitle && <p className="text-red-600 text-sm font-semibold mt-1">{errors.tTitle.message}</p>}
          </div>
          <div>
            <p className="text-lg text-primary-400 mb-4">Description</p>
            <TextField
              fullWidth
              inputRef={register}
              error={!!errors.tDescription}
              name="tDescription"
              variant="outlined"
              multiline
              rows={2}
            />
          </div>
          <div>
            <p className="text-lg text-primary-400 mb-4 ">Weight</p>
            <TextField
              fullWidth
              inputRef={register}
              error={!!errors.weight}
              name="weight"
              variant="outlined"
              type="number"
            />
            {errors.weight && <p className="text-red-600 text-sm font-semibold mt-1">{errors.weight.message}</p>}
          </div>
          <div>
            <h2 className="text-lg text-primary-400 mb-4">Relation</h2>
            <ReactHookFormSelect name="parentId" variant="outlined" error={!!errors.parentId} control={control}>
              <MenuItem value="0">{'<root>'}</MenuItem>
              {termsData().map((t) => (
                <MenuItem key={t.tId} value={t.tId.toString()}>
                  {t.tTitle}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
          </div>
          <div>
            <h2 className="text-lg text-primary-400 mb-4">Fields</h2>
            <Divider />
            <div className="space-y-4 mt-4 ml-6">
              {vocabularyDetails.data?.attributes
                ? Object.entries(vocabularyDetails.data.attributes).map((f, i) => (
                    <div key={i}>
                      <p className="text-lg text-primary-400 mb-4">{f[0]}</p>
                      <TextField
                        fullWidth
                        inputRef={register}
                        name={`attributes.${f[0]}`}
                        variant="outlined"
                        type={f[1]}
                      />
                    </div>
                  ))
                : !vocabularyDetails.isLoading && <p>No fields are present yet.</p>}
            </div>
          </div>

          <div className="flex flex-row-reverse">
            <button
              disabled={isSubmitting}
              className="px-6 bg-primary-400 uppercase border font-medium text-white rounded-md focus:outline-none py-2"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TermEdit;
