import { useState } from 'react';

import { Link } from 'react-router-dom';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../utils';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer } from '@material-ui/core';
import { useQuery } from 'react-query';
import { LucyApi } from '../../config';
import { IPaginationInfo, IRoleResponse } from '../../types';
import LucyIcon from '../../components/icons';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
  formControl: {
    width: '100%',
  },
});

interface IRoleList {
  data: IRoleResponse[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  rid: number;
  name: string;
  pageSize: number;
  pageNumber: number;
}

const fetchRoles = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/roles?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const RoleList = () => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const roles = useQuery<IRoleList, Error>(
    ['role', { pageSize, pageNumber }],
    async (context) => {
      return await fetchRoles(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return (
    <div className="space-y-6 ">
      <div className="px-6">
        <div className="space-y-5">
          <p className="text-xl">List of available roles</p>
          <div className="flex flex-wrap justify-between items-baseline">
            {roles.data && (
              <p className="text-sm text-gray-400">
                Role {calculatePaginationFromTo(roles.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(roles.data.paginationInfo).to} of {roles.data.paginationInfo.totalElements}
              </p>
            )}
            {roles.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!roles.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    roles.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!roles.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    roles.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    Role Name
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Created by
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Created at
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Updated by
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Updated at
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roles.data && roles.data.data.length > 0 ? (
                  roles.data.data.map(({ rId, name, createdAt, updatedAt, createdBy, updatedBy }) => (
                    <TableRow key={rId}>
                      <TableCell component="th">
                        <Link
                          to={`/role/${rId}`}
                          className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                        >
                          {name}
                        </Link>
                      </TableCell>
                      <TableCell align="left">{createdBy}</TableCell>
                      <TableCell align="left">{format(new Date(createdAt), 'hh:mm a, do MMM, yyyy')}</TableCell>
                      <TableCell align="left">{updatedBy}</TableCell>
                      <TableCell align="left">{format(new Date(updatedAt), 'hh:mm a, do MMM, yyyy')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      {roles.isLoading ? 'Loading . . .' : 'No role found.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RoleList;
