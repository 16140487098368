import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { LucyApi, s3FileUrl } from '../../../config';
import { ISinglePrecedentResponse } from '../../../types';
import { completionStatusValues, fixedVocabularies, getAccessToken, precedentStatusValues } from '../../../utils';
import { Link } from 'react-router-dom';

interface params {
  pId: string;
}
const fetchPrecedent = async (pId: string) => {
  const response = await LucyApi.get(`/precedent/${pId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const PrecedentDetails = () => {
  const params = useParams<params>();
  const [tags, setTags] = useState<ISinglePrecedentResponse['terms']>([]);
  const [categories, setCategories] = useState<ISinglePrecedentResponse['terms']>([]);
  const [questions, setQuestions] = useState<ISinglePrecedentResponse['terms']>([]);

  const precedentDetails = useQuery<ISinglePrecedentResponse, Error>(
    ['precedent-details', params.pId],
    async (context) => {
      return await fetchPrecedent(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        let tempTags: ISinglePrecedentResponse['terms'] = [];
        let tempCategories: ISinglePrecedentResponse['terms'] = [];
        let tempQuestions: ISinglePrecedentResponse['terms'] = [];
        data.terms.forEach((t) => {
          if (t.machine_name === fixedVocabularies.tag.machine_name) {
            tempTags.push(t);
          }
          if (t.machine_name === fixedVocabularies.category.machine_name) {
            tempCategories.push(t);
          }
          if (t.machine_name === fixedVocabularies.question.machine_name) {
            tempQuestions.push(t);
          }
          setTags(tempTags);
          setCategories(tempCategories);
          setQuestions(tempQuestions);
        });
      },
    },
  );

  return (
    <div>
      {precedentDetails.data ? (
        <div className="space-y-4">
          <p className="text-sm text-gray-500">
            Submitted by{' '}
            <span className="text-primary-400 font-semibold underline">{precedentDetails.data.createdBy}</span> on{' '}
            <span className="font-semibold">
              {format(new Date(precedentDetails.data.createdAt), 'hh:mm a, do MMM, yyyy')}
            </span>
          </p>
          <div className="flex flex-wrap -m-3">
            <div className="m-3">
              <p className="font-semibold mb-2">Completion Status</p>
              <p>{completionStatusValues[precedentDetails.data.completionStatus]}</p>
            </div>
            <div className="m-3">
              <p className="font-semibold mb-2">Precedent Status</p>
              <p>{precedentStatusValues[precedentDetails.data.precedentStatus]}</p>
            </div>
          </div>
          <div className="flex flex-wrap justify-between -m-3">
            <div className="h-24 w-24 ring-2 rounded-sm ring-primary-400 overflow-hidden m-3">
              {precedentDetails.data.logo ? (
                <img
                  className="w-full h-full object-cover"
                  src={`${s3FileUrl}${precedentDetails.data.logo?.url}`}
                  alt={precedentDetails.data.logo?.name}
                />
              ) : (
                'N / A'
              )}
            </div>
            <div className="space-y-2 m-3">
              <p className="text-primary-400 font-semibold text-2xl">{precedentDetails.data.precedentTitle}</p>
              <p className="text-blue-400 font-semibold text-lg">{precedentDetails.data.actor}</p>
            </div>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Description</h2>
            <p className="bg-gray-200 border-2 border-gray-900 p-4">{precedentDetails.data.description}</p>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Background</h2>
            <p className="bg-gray-200 border-2 border-gray-900 p-4">{precedentDetails.data.background}</p>
          </div>
          <div className="bg-purple-100 border-2 border-purple-900 space-y-2 p-4">
            <div className="bg-green-100 border-2 border-green-600 space-y-2 p-4">
              <p className="font-semibold">Tag</p>
              <div className="flex flex-wrap bg-green-100 border-2 border-green-600 p-2">
                {tags.map((t) => (
                  <Link
                    key={t.tId}
                    to={`/vocabulary/term/${t.tId}`}
                    className="text-primary-400 text-sm font-semibold cursor-pointer underline m-2"
                  >
                    {t.tTitle}
                  </Link>
                ))}
              </div>
            </div>
            <div className="bg-yellow-100 border-2 border-yellow-600 space-y-2 p-4">
              <p className="font-semibold">Category</p>
              <div className="flex flex-wrap bg-yellow-100 border-2 border-yellow-600 p-2">
                {categories.map((t) => (
                  <Link
                    to={`/vocabulary/term/${t.tId}`}
                    key={t.tId}
                    className="text-primary-400 text-sm font-semibold cursor-pointer underline m-2"
                  >
                    {t.tTitle}
                  </Link>
                ))}
              </div>
            </div>
            <div className="bg-pink-100 border-2 border-pink-600 space-y-2 p-4">
              <p className="font-semibold">Question</p>
              <div className="flex flex-wrap bg-pink-100 border-2 border-pink-600 p-2">
                {questions.map((t) => (
                  <Link
                    key={t.tId}
                    to={`/vocabulary/term/${t.tId}`}
                    className="text-primary-400 text-sm font-semibold cursor-pointer underline m-2"
                  >
                    {t.tTitle}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Notes</h2>
            <p className="bg-gray-200 border-2 border-gray-900 p-4">{precedentDetails.data.notes}</p>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Insights</h2>
            <div className="space-y-2">
              {precedentDetails.data.insights && precedentDetails.data.insights.length > 0 ? (
                precedentDetails.data.insights.map((v, i) => (
                  <div key={i} className="bg-blue-200 border-2 border-blue-900 space-y-3 p-4">
                    <div className="font-semibold">{v.bold}</div>
                    <div>{v.text}</div>
                  </div>
                ))
              ) : (
                <div className="bg-blue-200 border-2 border-blue-900 space-y-3 p-4"></div>
              )}
            </div>
          </div>
          <div>
            <h2 className="font-semibold mb-2">Outcomes</h2>
            <div className="space-y-2">
              {precedentDetails.data.outcomes && precedentDetails.data.outcomes.length > 0 ? (
                precedentDetails.data.outcomes.map((v, i) => (
                  <div key={i} className="bg-green-200 border-2 border-green-900 space-y-3 p-4">
                    <div className="font-semibold">{v.bold}</div>
                    <div>{v.text}</div>
                    <div>{v.source}</div>
                  </div>
                ))
              ) : (
                <div className="bg-green-200 border-2 border-green-900 space-y-3 p-4"></div>
              )}
            </div>
          </div>
        </div>
      ) : precedentDetails.isLoading ? (
        <p>Loading...</p>
      ) : (
        <p>No Precedent Found.</p>
      )}
    </div>
  );
};

export default PrecedentDetails;
