import React from 'react';
import { useQuery } from 'react-query';
import { NavLink, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { LucyApi } from '../../../config';
import { CAN } from '../../../permissions';
import { IUserResponse } from '../../../types';
import { getAccessToken } from '../../../utils';
import ChangePassword from './ChangePassword';
import UserDelete from './UserDelete';
import UserDetails from './UserDetails';
import UserEdit from './UserEdit';

interface params {
  uId: string;
}
const fetchUser = async (uId: string) => {
  const response = await LucyApi.get(`/user/${uId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const UserLanding = () => {
  let { path } = useRouteMatch();
  const params = useParams<params>();

  const userDetails = useQuery<IUserResponse, Error>(
    ['user-details', params.uId],
    async (context) => {
      return await fetchUser(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div className="space-y-6 xl:flex xl:space-y-0 xl:space-x-6">
      <div className="space-y-4 xl:flex-1">
        <h1 className="font-medium text-3xl">{userDetails.data?.userName}</h1>
        <div className="space-y-8">
          <div className="flex">
            <NavLink
              exact
              to={`/user/${params.uId}`}
              activeClassName="text-secondary-400"
              className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
            >
              View
            </NavLink>
            {CAN('update', 'user') && (
              <>
                <NavLink
                  to={`/user/${params.uId}/edit`}
                  activeClassName="text-secondary-400"
                  className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
                >
                  Edit
                </NavLink>
                <NavLink
                  to={`/user/${params.uId}/change_password`}
                  activeClassName="text-secondary-400"
                  className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
                >
                  Change Password
                </NavLink>
              </>
            )}
            {CAN('delete', 'user') && (
              <NavLink
                to={`/user/${params.uId}/delete`}
                activeClassName="text-secondary-400"
                className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
              >
                Delete
              </NavLink>
            )}
          </div>
          <div>
            <Switch>
              <Route exact path={`${path}`} component={UserDetails} />
              <Route exact path={`${path}/edit`} component={UserEdit} />
              <Route exact path={`${path}/delete`} component={UserDelete} />
              <Route exact path={`${path}/change_password`} component={ChangePassword} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLanding;
