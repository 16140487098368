import React, { useState } from 'react';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LucyApi, s3FileUrl } from '../../../config';
import { useQuery } from 'react-query';
import LucyIcon from '../../../components/icons';
import { IPrecedent } from '../../../types';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
  table: {
    minWidth: 320,
  },
});

interface IPrecedentByComment {
  precedentId: IPrecedent['precedentId'];
  actor: IPrecedent['actor'];
  precedentTitle: IPrecedent['precedentTitle'];
  pId: IPrecedent['pId'];
  createdAt: string;
  comment_count: number;
  logo: IPrecedent['logo'];
}

interface IPrecedentList {
  data: IPrecedentByComment[];
  paginationInfo: {
    pageSize: number;
    totalPage: number;
    pageNumber: number;
    totalElements: number;
    numberOfElements: number;
    previous: boolean;
    next: boolean;
  };
}

interface IQueryData {
  count: number;
  pageSize: number;
  pageNumber: number;
}

const fetchPrecedentByComment = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`precedent/view-comments?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const FilterByCommentNumber = () => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);
  const [countQuery, setCountQuery] = useState<number>();
  const [count, setCount] = useState<number | string>('');

  const precedents = useQuery<IPrecedentList, Error>(
    ['precedents-by-comments', { count: countQuery, pageSize, pageNumber }],
    async (context) => {
      return await fetchPrecedentByComment(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const onSubmit = async () => {
    const value = typeof count === 'number' ? count : parseInt(count);
    setPageNumber(1);
    if (Number.isInteger(value)) {
      setCountQuery(value);
    } else {
      setCountQuery(undefined);
    }
  };

  const handleResetForm = () => {
    setCountQuery(undefined);
    setCount('');
    setPageNumber(1);
  };

  return (
    <div className="space-y-6 xl:space-y-0 xl:flex mx-auto">
      <div className="xl:w-64 space-y-5 flex-shrink-0 bg-gray-100 py-4 px-6">
        <p className="text-sm text-primary-400 font-semibold">Number of Comments</p>
        <div className="space-y-5">
          <div>
            <TextField
              fullWidth
              name="count"
              label="Comment Count"
              variant="outlined"
              type="number"
              value={count}
              onChange={(e) =>
                Number.isInteger(parseInt(e.target.value)) ? setCount(parseInt(e.target.value)) : setCount('')
              }
            />
            <p className="text-xs text-gray-700 mt-4 ">At least this number of comment</p>
          </div>
          <div className="flex flex-wrap justify-between">
            <Button onClick={onSubmit} variant="contained" color="primary">
              APPLY
            </Button>
            <Button onClick={handleResetForm} variant="outlined" color="primary">
              RESET
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-1 px-6">
        <div className="space-y-5">
          <p className="text-xl">Overview of Comments</p>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    Precedent
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    logo
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Actor
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Precedent Title
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Comment Count
                  </TableCell>
                  {/* <TableCell className={classes.header} align="left">
                    New Comment
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {precedents.data && precedents.data.data.length > 0 ? (
                  precedents.data.data.map(({ precedentId, precedentTitle, actor, comment_count, pId, logo }) => {
                    return (
                      <TableRow key={pId}>
                        <TableCell component="th">
                          <Link
                            to={`/precedent/${pId}`}
                            className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                          >
                            {precedentId}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          {logo ? (
                            <div className="h-14 w-14 ring-2 rounded-sm ring-primary-400 overflow-hidden">
                              <img
                                className="w-full h-full object-cover"
                                src={`${s3FileUrl}${logo?.url}`}
                                alt={logo?.name}
                              />
                            </div>
                          ) : (
                            'N / A'
                          )}
                        </TableCell>

                        <TableCell align="left">{actor}</TableCell>
                        <TableCell align="left">{precedentTitle}</TableCell>
                        <TableCell align="left">{comment_count}</TableCell>
                        {/* <TableCell align="left">-</TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="left" colSpan={6}>
                      {precedents.isLoading ? 'Loading . . .' : 'No precedents found.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterByCommentNumber;
