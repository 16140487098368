import React, { useState } from 'react';
import { IPaginationInfo, ITermResponse, IVocabularyResponse } from '../../../types';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { LucyApi } from '../../../config';
import LucyIcon from '../../../components/icons';
import {
  Button,
  makeStyles,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';

interface IQueryData {
  vId: number;
  pageSize: number;
  pageNumber: number;
}
interface IVocabularyTermListResponse {
  data: ITermResponse[];
  paginationInfo: IPaginationInfo;
}

const fetchVocabulary = async (vId: string) => {
  const response = await LucyApi.get(`/vocabulary/${vId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const fetchTerms = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/term_data?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

interface params {
  vId: string;
}

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
});

const TermList = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<params>();

  const [selectedTId, setSelectedTId] = useState<number>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(50);

  const handleClick = (tId: number) => (event: any) => {
    setSelectedTId(tId);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const vocabularyDetails = useQuery<IVocabularyResponse, Error>(
    ['vocabulary-details', params.vId],
    async (context) => {
      return await fetchVocabulary(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const terms = useQuery<IVocabularyTermListResponse, Error>(
    ['terms', { vId: params.vId, pageSize, pageNumber }],
    async (context) => {
      return await fetchTerms(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vocabularyDetails.data,
    },
  );

  return (
    <div className="space-y-6">
      <div className="md:flex justify-between md:space-x-10 items-center mt-4">
        <p className="text-sm">{vocabularyDetails.data?.vDescription}</p>
        <div className="mt-4 md:mt-0 flex justify-center">
          <Button
            variant="outlined"
            color="primary"
            endIcon={<LucyIcon name="plus" className="h-5 fill-current" />}
            onClick={() => history.push(`/vocabulary/${params.vId}/term/create`)}
          >
            Add Term
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap justify-between items-baseline">
        {terms.data && (
          <p className="text-sm text-gray-400">
            Precedent {calculatePaginationFromTo(terms.data.paginationInfo).from} -{' '}
            {calculatePaginationFromTo(terms.data.paginationInfo).to} of {terms.data.paginationInfo.totalElements}
          </p>
        )}
        {terms.data && (
          <div className="flex">
            <button
              onClick={() => setPageNumber((prev) => prev - 1)}
              disabled={!terms.data.paginationInfo.previous}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                terms.data.paginationInfo.previous
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-left" className="fill-current h-6" />
            </button>
            <button
              onClick={() => setPageNumber((prev) => prev + 1)}
              disabled={!terms.data.paginationInfo.next}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                terms.data.paginationInfo.next
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-right" className="fill-current h-6" />
            </button>
          </div>
        )}
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.header} align="left">
                Term Name
              </TableCell>
              <TableCell className={classes.header} align="left">
                Description
              </TableCell>
              <TableCell className={classes.header} align="center">
                Weight
              </TableCell>
              <TableCell className={classes.header} align="right">
                Operations
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {terms.data && terms.data.data && terms.data.data.length > 0
              ? terms.data.data.map((t) => (
                  <TableRow key={t.tId}>
                    <TableCell component="td">{t.tTitle}</TableCell>
                    <TableCell component="td">{t.tDescription}</TableCell>
                    <TableCell component="td" align="center">
                      {t.weight}
                    </TableCell>
                    <TableCell align="right">
                      <Popover
                        id="term-popover"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            history.push(`/vocabulary/${params.vId}/term/${selectedTId}/edit`);
                          }}
                        >
                          Edit
                        </MenuItem>
                      </Popover>
                      <div>
                        <button onClick={handleClick(t.tId)} className="focus:outline-none ">
                          <LucyIcon name="list" className="h-6 fill-current text-gray-700" />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : !terms.isLoading && (
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      No terms found.
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex flex-wrap justify-between items-baseline">
        {terms.data && (
          <p className="text-sm text-gray-400">
            Precedent {calculatePaginationFromTo(terms.data.paginationInfo).from} -{' '}
            {calculatePaginationFromTo(terms.data.paginationInfo).to} of {terms.data.paginationInfo.totalElements}
          </p>
        )}
        {terms.data && (
          <div className="flex">
            <button
              onClick={() => setPageNumber((prev) => prev - 1)}
              disabled={!terms.data.paginationInfo.previous}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                terms.data.paginationInfo.previous
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-left" className="fill-current h-6" />
            </button>
            <button
              onClick={() => setPageNumber((prev) => prev + 1)}
              disabled={!terms.data.paginationInfo.next}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                terms.data.paginationInfo.next
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-right" className="fill-current h-6" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TermList;
