import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import lucyImg from '../../resources/images/lucy.png';
import * as Yup from 'yup';
import { emailRegex } from '../../utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LucyApi } from '../../config';
import { LucyBackdrop } from '../../components/Backdrop';
import LucyIcon from '../../components/icons';
import { LucyToaster } from '../../components/Toaster';

interface IData {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
});

const ForgotPassword = () => {
  const [error, setError] = useState<string>();
  const [isSuccess, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError(undefined);
    setSuccess(false);
    try {
      await LucyApi.put('/common/reset-password', data);
      console.log(data);
      LucyToaster('Please check your email for new password', 'success');

      setSuccess(true);
    } catch (error) {
      setError(error.response.data.message);
    }
  });

  return (
    <div className="min-h-screen text-white bg-primary-400">
      <LucyBackdrop open={isSubmitting} />
      <div className="sticky top-0 bg-primary-400 z-10 border-b px-6 py-2">
        <Link to="/login">
          <img className="h-20" src={lucyImg} alt="lucy icon" />
        </Link>
      </div>
      <div className="px-6 py-28">
        <div
          className="max-w-xl mx-auto space-y-10 border-2 border-white rounded-3xl bg-white bg-opacity-10 p-8 lg:p-12"
          style={{
            backdropFilter: 'blur(4px)',
          }}
        >
          {isSuccess ? (
            <div className="flex flex-col items-center justify-center space-y-4 mt-8">
              <h2 className="font-bold text-3xl text-center">Password updated successfully!!</h2>
              <p className="font-bold text-2xl text-center my-5">Please check your email for updated password</p>

              <Link
                to="/login"
                className="mb-5 my-10 w-full text-center border-2 border-white rounded-xl px-8 py-3 font-medium hover:bg-secondary-400 hover:border-transparent hover:text-black"
              >
                Click here to login
              </Link>
            </div>
          ) : (
            <div>
              <h2 className="font-bold text-3xl text-center">Forgot Password</h2>
              <form onSubmit={onSubmit}>
                <div className="relative flex w-full flex-wrap items-stretch mb-5 my-10">
                  <input
                    ref={register}
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Please input your email"
                    className="px-3 py-3 bg-opacity-10 border-2 border-white outline-none focus:border-primary-400 placeholder-gray-100  text-white relative bg-white rounded-lg text-sm focus:outline-none focus:shadow-outline w-full pr-10"
                  />

                  {errors.email && (
                    <p className="text-secondary-400 ml-1 text-xs font-medium mt-1">{errors.email.message}</p>
                  )}
                  <span className="z-10 h-full leading-snug font-normal text-center fill-current placeholder-gray-100 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                    <LucyIcon name="user" className="h-5" />
                  </span>
                </div>
                <div className="mt-6">
                  {error && <p className="text-center text-secondary-400 ml-1 text-xs font-medium">{error}</p>}

                  <button
                    type="submit"
                    className="mt-2 w-full rounded-xl px-4 py-3 font-medium text-primary-400 bg-white text-primary-500 hover:bg-secondary-400 hover:text-black"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
