import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import FieldCreate from './FieldCreate';
import FieldEdit from './FieldEdit';

const FieldPage = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/create`}>
          <FieldCreate />
        </Route>
        <Route exact path={`${path}/:fname/edit`}>
          <FieldEdit />
        </Route>
        <Route path="*" render={() => <Redirect to={path} />}></Route>
      </Switch>
    </div>
  );
};

export default FieldPage;
