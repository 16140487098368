import { Route, Switch } from 'react-router-dom';
import VocabularyPage from './pages/vocabulary';
import PrecedentPage from './pages/precedent';
import UserPage from './pages/user';
import RolePage from './pages/role';

const SecondaryApp = () => {
  return (
    <div className="p-6 mx-auto">
      <Switch>
        <Route path="/precedent" component={PrecedentPage} />
        <Route path="/vocabulary" component={VocabularyPage} />
        <Route path="/user" component={UserPage} />
        <Route path="/role" component={RolePage} />
      </Switch>
    </div>
  );
};

export default SecondaryApp;
