import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { LucyApi } from '../../config';
import { IPaginationInfo, IPrecedentResponse, ITerm } from '../../types';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../utils';
import LucyIcon from '../../components/icons';

interface params {
  tId: string;
}

interface IPrecedentData extends IPrecedentResponse {
  tId: ITerm['tId'];
}

interface IPrecedentList {
  data: IPrecedentData[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  tId: ITerm['tId'];
  pageSize: number;
  pageNumber: number;
}

const fetchPrecedents = async (queryData: IQueryData) => {
  const { tId, ...data } = queryData;
  const response = await LucyApi.get(`/precedent/terms/${tId}?${generateQueryString(data)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const PrecedentsByTerm = () => {
  const tId = useParams<params>().tId;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const precedents = useQuery<IPrecedentList, Error>(
    ['term-by-precedent', { tId, pageSize, pageNumber }],
    async (context) => {
      return await fetchPrecedents(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div className="space-y-5">
      <h1 className="font-medium text-primary-400 text-2xl my-4">{tId}</h1>
      <div className="flex flex-wrap justify-between items-baseline">
        {precedents.data && (
          <p className="text-sm text-gray-400">
            Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
            {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
            {precedents.data.paginationInfo.totalElements}
          </p>
        )}
        {precedents.data && (
          <div className="flex">
            <button
              onClick={() => setPageNumber((prev) => prev - 1)}
              disabled={!precedents.data.paginationInfo.previous}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                precedents.data.paginationInfo.previous
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-left" className="fill-current h-6" />
            </button>
            <button
              onClick={() => setPageNumber((prev) => prev + 1)}
              disabled={!precedents.data.paginationInfo.next}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                precedents.data.paginationInfo.next
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-right" className="fill-current h-6" />
            </button>
          </div>
        )}
      </div>
      {precedents.data && precedents.data.data.length > 0 ? (
        precedents.data.data.map(({ precedentId, createdBy, createdAt, pId }) => {
          return (
            <div key={pId}>
              <Link to={`/precedent/${pId}`}>
                <h1 className="text-primary-400 font-medium text-xl cursor-pointer underline my-4">{precedentId}</h1>
                <p className="text-sm text-gray-500 mb-4">
                  Submitted by <span className="text-primary-400 font-semibold underline">{createdBy}</span> on{' '}
                  {format(new Date(createdAt), 'hh:mm a, do MMM, yyyy')}
                </p>
              </Link>
              <hr />
            </div>
          );
        })
      ) : precedents.isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>No Precedent Found.</div>
      )}
      <div className="flex flex-wrap justify-between items-baseline">
        {precedents.data && (
          <p className="text-sm text-gray-400">
            Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
            {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
            {precedents.data.paginationInfo.totalElements}
          </p>
        )}
        {precedents.data && (
          <div className="flex">
            <button
              onClick={() => setPageNumber((prev) => prev - 1)}
              disabled={!precedents.data.paginationInfo.previous}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                precedents.data.paginationInfo.previous
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-left" className="fill-current h-6" />
            </button>
            <button
              onClick={() => setPageNumber((prev) => prev + 1)}
              disabled={!precedents.data.paginationInfo.next}
              className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                precedents.data.paginationInfo.next
                  ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                  : 'opacity-50 cursor-not-allowed'
              }`}
            >
              <LucyIcon name="arrow-right" className="fill-current h-6" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default PrecedentsByTerm;
