import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import TermCreate from './TermCreate';
import TermEdit from './TermEdit';

const TermPage = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/create`}>
          <TermCreate />
        </Route>
        <Route exact path={`${path}/:tId/edit`}>
          <TermEdit />
        </Route>
        <Route path="*" render={() => <Redirect to={`${path}/create`} />}></Route>
      </Switch>
    </div>
  );
};

export default TermPage;
