import { useHistory, useParams } from 'react-router';
import { TextField, Button } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useQuery } from 'react-query';

import { LucyBackdrop } from '../../../components/Backdrop';
import LucyIcon from '../../../components/icons';
import { LucyToaster } from '../../../components/Toaster';
import { LucyApi } from '../../../config';
import { IUpdateRole, IRoleResponse } from '../../../types';
import { getAccessToken } from '../../../utils';

interface params {
  rId: string;
}

const fetchRole = async (rId: string) => {
  const response = await LucyApi.get(`/roles/${rId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required'),
});

const RoleEdit = () => {
  const params = useParams<params>();
  const history = useHistory();

  const roleDetails = useQuery<IRoleResponse, Error>(
    ['role-details', params.rId],
    async () => {
      return await fetchRole(params.rId);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (data) => {
        // * set default values
        reset({
          rId: data.rId,
          name: data.name,
        });
      },
    },
  );

  const {
    register,
    handleSubmit,
    errors,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm<IUpdateRole>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await LucyApi.put(`/roles/${params.rId}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });

      LucyToaster('Role updated.', 'success');
      history.push(`/role/${params.rId}`);
    } catch (error) {
      if (error?.response?.status === 409) {
        setError('name', {
          type: 'manual',
          message: 'Role Already exists with the given role name.',
          shouldFocus: true,
        });
      }
      if (error?.response?.status === 400) {
        setError('name', {
          type: 'manual',
          message: 'Bad request!!',
          shouldFocus: true,
        });
      }
    }
  });
  return (
    <>
      {roleDetails.data ? (
        <div>
          <div className="flex space-x-3 items-center">
            <h1 className="text-xl">Edit Role</h1>
            <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
          </div>
          <div className="max-w-3xl mt-10 mb-10">
            <LucyBackdrop open={isSubmitting} />
            <form onSubmit={onSubmit}>
              <div className="space-y-6">
                <div>
                  <h2 className="text-lg text-primary-400 mb-4">User Name</h2>
                  <TextField fullWidth variant="outlined" name="name" inputRef={register} error={!!errors.name} />
                  {errors.name && <p className="text-red-500 text-sm font-semibold mt-1">{errors.name.message}</p>}
                </div>
                <Button size="large" variant="contained" color="primary" type="submit">
                  Edit
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : roleDetails.isLoading ? (
        <p>Loading...</p>
      ) : (
        <p>No Roles Found.</p>
      )}
    </>
  );
};

export default RoleEdit;
