import React from 'react';
import { useQuery } from 'react-query';
import { NavLink, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { LucyApi } from '../../../config';
import { CAN } from '../../../permissions';
import { IRoleResponse } from '../../../types';
import { getAccessToken } from '../../../utils';
import RoleDelete from './RoleDelete';
import RoleDetails from './RoleDetails';
import RoleEdit from './RoleEdit';
import RolePermission from './RolePermission';

interface params {
  rId: string;
  //   rId: number;
}
const fetchRole = async (rId: string) => {
  const response = await LucyApi.get(`/roles/${rId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const RoleLanding = () => {
  let { path } = useRouteMatch();
  const params = useParams<params>();

  const roleDetails = useQuery<IRoleResponse, Error>(
    ['role-details', params.rId],
    async (context) => {
      return await fetchRole(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div className="space-y-6 xl:flex xl:space-y-0 xl:space-x-6">
      <div className="space-y-4 xl:flex-1">
        <h1 className="font-medium text-3xl">{roleDetails.data?.name}</h1>
        <div className="space-y-8">
          <div className="md:flex-row flex flex-col space-y-2 md:space-y-0 ">
            <NavLink
              exact
              to={`/role/${params.rId}`}
              activeClassName="text-secondary-400"
              className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
            >
              View
            </NavLink>
            {CAN('update', 'role') && (
              <>
                <NavLink
                  to={`/role/${params.rId}/edit`}
                  activeClassName="text-secondary-400"
                  className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
                >
                  Edit
                </NavLink>
                <NavLink
                  to={`/role/${params.rId}/permission`}
                  activeClassName="text-secondary-400"
                  className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
                >
                  Permission
                </NavLink>
              </>
            )}
            {CAN('delete', 'role') && (
              <NavLink
                to={`/role/${params.rId}/delete`}
                activeClassName="text-secondary-400"
                className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
              >
                Delete
              </NavLink>
            )}
          </div>
          <div>
            <Switch>
              <Route exact path={`${path}`} component={RoleDetails} />
              <Route exact path={`${path}/edit`} component={RoleEdit} />
              <Route exact path={`${path}/permission`} component={RolePermission} />
              <Route exact path={`${path}/delete`} component={RoleDelete} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleLanding;
