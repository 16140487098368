import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import FilterByCategoriesAndTags from './CategoryAndTag';
import FilterByCommentNumber from './Comments';
import FilterByQuestionInsightOutcome from './QuestionInsightOutcome';
import FilterByQuestion from './Question';
import FilterByQuestionTreeLinkedPrecedent from './QuestionTreeLinkedPrecedent';
import FilterByTitleAndActor from './TitleAndActor';

const FilterLanding = () => {
  return (
    <div className="space-y-8 mt-2">
      <div className="md:flex-row flex flex-col space-y-2 md:space-y-0">
        <NavLink
          exact
          to="/precedent/filter-by"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          Title and Actor
        </NavLink>
        <NavLink
          to="/precedent/filter-by/category-and-tags"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          Category and Tags
        </NavLink>
        <NavLink
          to="/precedent/filter-by/question"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          Questions
        </NavLink>
        <NavLink
          to="/precedent/filter-by/question-tree"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          Question Tree Linked Precedents
        </NavLink>
        <NavLink
          to="/precedent/filter-by/insight-outcome"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          Questions - Insights - Outcomes
        </NavLink>
        <NavLink
          to="/precedent/filter-by/comments"
          activeClassName="text-secondary-400"
          className="text-center bg-black text-sm px-4 py-2 text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none"
        >
          Number of Comments
        </NavLink>
      </div>
      <div>
        <Switch>
          <Route exact path="/precedent/filter-by" component={FilterByTitleAndActor} />
          <Route exact path="/precedent/filter-by/category-and-tags" component={FilterByCategoriesAndTags} />
          <Route exact path="/precedent/filter-by/question" component={FilterByQuestion} />
          <Route exact path="/precedent/filter-by/question-tree" component={FilterByQuestionTreeLinkedPrecedent} />
          <Route exact path="/precedent/filter-by/insight-outcome" component={FilterByQuestionInsightOutcome} />
          <Route exact path="/precedent/filter-by/comments" component={FilterByCommentNumber} />
        </Switch>
      </div>
    </div>
  );
};

export default FilterLanding;
