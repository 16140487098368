import React, { useState } from 'react';
import LucyIcon from '../../components/icons';
import {
  Button,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { LucyApi } from '../../config';
import { useQuery } from 'react-query';
import { IPaginationInfo, IVocabularyResponse } from '../../types';
import { getAccessToken } from '../../utils';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
});

interface IVocabularyList {
  data: IVocabularyResponse[];
  paginationInfo: IPaginationInfo;
}

const fetchVocabulary = async () => {
  const response = await LucyApi.get('/vocabulary', {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const VocabularyList = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [selectedVocabularyId, setSelectedVocabularyId] = useState<number>();
  const history = useHistory();
  const classes = useStyles();

  const handleClick = (vId: number) => (event: any) => {
    setSelectedVocabularyId(vId);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const vocabularies = useQuery<IVocabularyList, Error>(
    ['vocabulary-list'],
    async (context) => {
      return await fetchVocabulary();
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return (
    <div>
      <div className="flex space-x-3 items-center">
        <p className="text-xl">Vocabularies</p>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>

      <div className="md:flex md:justify-between md:space-x-10 mt-4">
        <p className="text-sm">
          Taxonomy is for categorizing content. Terms are grouped into vocabularies. For example, a vocabulary called
          "Fruit" would contain the terms "Apple" and "Banana".
        </p>
        <span className="flex-shrink-0">
          <Button
            variant="outlined"
            color="primary"
            endIcon={<LucyIcon name="plus" className="h-5 fill-current" />}
            onClick={() => history.push('/vocabulary/create')}
          >
            Add Vocabulary
          </Button>
        </span>
      </div>

      <div className="mt-6">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} align="left">
                  Title
                </TableCell>
                <TableCell className={classes.header} align="left">
                  Description
                </TableCell>
                <TableCell className={classes.header} align="left">
                  Machine Name
                </TableCell>
                <TableCell className={classes.header} align="left">
                  Hierarchy
                </TableCell>
                <TableCell className={classes.header} align="right">
                  Operations
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vocabularies.data && vocabularies.data.data.length > 0
                ? vocabularies.data.data.map(({ vId, vTitle, vDescription, vHierarchy, machine_name }) => {
                    return (
                      <TableRow key={vId}>
                        <TableCell component="th" scope="user">
                          <div className="flex">
                            <div className="h-6 mr-4">
                              <LucyIcon name="swap" className="h-6 fill-current text-gray-700" />
                            </div>
                            {vTitle}
                          </div>
                        </TableCell>
                        <TableCell align="left">{vDescription}</TableCell>
                        <TableCell align="left">{machine_name}</TableCell>
                        <TableCell align="left">{vHierarchy}</TableCell>
                        <TableCell align="right">
                          <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                history.push(`/vocabulary/${selectedVocabularyId}`);
                              }}
                            >
                              Term List
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                history.push(`/vocabulary/${selectedVocabularyId}/edit`);
                              }}
                            >
                              Edit vocabulary
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                history.push(`/vocabulary/${selectedVocabularyId}/term/create`);
                              }}
                            >
                              Add term
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                history.push(`/vocabulary/${selectedVocabularyId}/field-list`);
                              }}
                            >
                              Manage Fields
                            </MenuItem>
                          </Popover>
                          <div>
                            <button onClick={handleClick(vId)} className="focus:outline-none ">
                              <LucyIcon name="list" className="h-6 fill-current text-gray-700" />
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : !vocabularies.isLoading && (
                    <TableRow>
                      <TableCell align="left" colSpan={3}>
                        No vocabularies found.
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default VocabularyList;
