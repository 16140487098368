import React, { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import axios, { AxiosResponse } from 'axios';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import LucyIcon from '../../../components/icons';
import {
  completionStatusValues,
  fixedVocabularies,
  generateQueryString,
  getAccessToken,
  logoMimetypeValues,
  logoSizeLimit,
  precedentStatusValues,
  truncateReverse,
} from '../../../utils';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup, LinearProgress, MenuItem, Radio } from '@material-ui/core';
import {
  ICreatePrecedent,
  ICreateTerm,
  IPaginationInfo,
  IQuestionResponse,
  ITerm,
  ITermResponse,
  SelectOptionType,
} from '../../../types';
import { ReactHookFormRadio, ReactHookFormSelect } from '../../../components/hooks/ReactHookForm';
import { LucyBackdrop } from '../../../components/Backdrop';
import { LucyToaster } from '../../../components/Toaster';
import { LucyApi } from '../../../config';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import { GroupTypeBase, Styles } from 'react-select';
import { AddQuestionIndex } from './AddQuestionIndex';

const userId = v4();

const validationSchema = Yup.object().shape({
  precedentId: Yup.string().trim().required('Required'),
  actor: Yup.string().trim(),
  background: Yup.string().trim(),
  description: Yup.string().trim(),
  precedentTitle: Yup.string().trim(),
  notes: Yup.string().trim(),
  insights: Yup.array().of(
    Yup.object({
      bold: Yup.string().trim().required('Required'),
      text: Yup.string().trim().required('Required'),
    }),
  ),
  outcomes: Yup.array().of(
    Yup.object({
      bold: Yup.string().trim(),
      text: Yup.string().trim().required('Required'),
      source: Yup.string().trim(),
    }),
  ),
  completionStatus: Yup.string().oneOf(['low', 'medium', 'high', 'complete']),
  precedentStatus: Yup.string().oneOf(['n/a', 'needsWork', 'termForUx', 'approved']),
  isPublished: Yup.boolean().default(false),
  owner: Yup.string().required('Required'),
  createdBy: Yup.string().required('Required'),
  updatedBy: Yup.string().required('Required'),
});

interface ITermListResponse {
  data: ITermResponse[];
  paginationInfo: IPaginationInfo;
}

const fetchTerms = async (queryData: { vId: ITerm['vId'] }) => {
  const response = await LucyApi.get(`/term_data?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};
const fetchQuestions = async (queryData: { questionType: 'MC' | 'DC' | 'DA'; parentId: ITerm['parentId'] }) => {
  const response = await LucyApi.get(`/term_data/view-questions?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const colourStyles: Partial<Styles<SelectOptionType, true, GroupTypeBase<SelectOptionType>>> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#e9f3f9',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#2586c3',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#2586c3',
    ':hover': {
      backgroundColor: '#2586c3',
      color: 'white',
    },
  }),
};

const CreatePrecedent = () => {
  const history = useHistory();

  // * Logo
  const [isLogoLoading, setLogoLoading] = useState(false);
  const [logoProgress, setLogoProgress] = useState(0);
  const [isLogoHoverOnDrop, setLogoHoverOnDrop] = useState(false);
  const [logo, setLogo] = useState<File>();
  const [logoError, setLogoError] = useState<string>();
  const handleChangeLogoFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogo(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (!logoMimetypeValues.includes(image.type)) {
          setLogoError('Only png, webp, jpg, and jpeg is allowed.');
          return;
        } else if (image.size / 1024 / 1024 > logoSizeLimit) {
          setLogoError(`Logo should be less then ${logoSizeLimit} MB`);
          return;
        } else {
          setLogo(image);
          setLogoError(undefined);
          return;
        }
      }
    }
  };

  // * Tags
  const [isTagLoading, setTagLoading] = useState(false);
  const [tagsOption, setTagsOption] = useState<SelectOptionType[]>([]);
  const [selectedTags, setSelectedTags] = useState<SelectOptionType[]>();
  const tagMethods = useQuery<ITermListResponse, Error>(
    ['terms', { vId: fixedVocabularies.tag.vId }],
    async () => {
      return await fetchTerms({ vId: fixedVocabularies.tag.vId });
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (terms) => {
        let newData: SelectOptionType[] = [];
        if (terms.data.length > 0) {
          newData = terms.data.map((t) => {
            return {
              value: t.tId.toString(),
              label: t.tTitle,
            };
          });
        }

        setTagsOption(newData);
      },
    },
  );
  const handleTagChange = useCallback((values: any) => {
    setSelectedTags(values as SelectOptionType[]);
  }, []);
  const handleTagCreate = useCallback(
    async (inputValue: string) => {
      setTagLoading(true);
      try {
        // Create term: TAG
        const body: ICreateTerm = {
          tTitle: inputValue,
          vId: fixedVocabularies.tag.vId,
          parentId: 0,
          weight: 0,
          machine_name: fixedVocabularies.tag.machine_name,
        };

        const res = await LucyApi.post('/term_data', body, {
          headers: {
            Authorization: getAccessToken(),
          },
        });

        const newValue: SelectOptionType = {
          value: res.data.data.tId.toString(),
          label: res.data.data.tTitle,
        };
        setTagsOption([...tagsOption, newValue]);
        setSelectedTags(selectedTags ? [...selectedTags, newValue] : [newValue]);
      } catch (error) {
        console.log('error', error);
      }
      setTagLoading(false);
    },
    [selectedTags, tagsOption],
  );

  // * Categories
  const [selectedCategories, setSelectedCategories] = useState<{
    [key: number]: boolean;
  }>({});
  const categoryMethods = useQuery<ITermListResponse, Error>(
    ['terms', { vId: fixedVocabularies.category.vId }],
    async () => {
      return await fetchTerms({ vId: fixedVocabularies.category.vId });
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (categories) => {
        let newData = selectedCategories;
        if (categories.data.length > 0) {
          categories.data.forEach((c) => {
            newData[c.tId] = false;
          });
        }

        setSelectedCategories(newData);
      },
    },
  );
  const handleCategoryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategories((prev) => {
      return { ...prev, [parseInt(event.target.name)]: event.target.checked };
    });
  }, []);

  // * Questions
  const [selectedQuestions, setSelectedQuestions] = useState<{ id: string; tId: ITermResponse['tId'] }[]>([]);
  const [questionsError, setQuestionsError] = useState<Record<string, string>>({});
  const questionMethods = useQuery<IQuestionResponse[], Error>(
    ['question', { questionType: 'MC', parentId: 0 }],
    async () => {
      return await fetchQuestions({ questionType: 'MC', parentId: 0 });
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState: { isSubmitting },
  } = useForm<ICreatePrecedent>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      insights: [],
      outcomes: [],
      completionStatus: 'low',
      precedentStatus: 'n/a',
      isPublished: false,
      owner: userId,
      createdBy: userId,
      updatedBy: userId,
    },
  });
  const insightFieldArray = useFieldArray({
    control,
    name: 'insights',
  });
  const outcomeFieldArray = useFieldArray({
    control,
    name: 'outcomes',
  });

  const onSubmit = handleSubmit(async (data) => {
    // * Question error handling
    let tempQuestionError: Record<string, string> = {};
    selectedQuestions.forEach((q) => {
      if (q.tId === 0) {
        tempQuestionError[q.id] = 'Required';
      }
    });
    setQuestionsError(tempQuestionError);
    if (Object.entries(tempQuestionError).length === 0) {
      try {
        // * preparing data for indexing
        const tags: number[] = selectedTags ? selectedTags.map((v) => parseInt(v.value)) : [];
        const categories: number[] = Object.entries(selectedCategories)
          .filter((v) => v[1])
          .map((v) => parseInt(v[0]));
        const questions: number[] = selectedQuestions.filter((v) => v.tId !== 0).map((v) => v.tId);

        const termsArray = [...new Set([...tags, ...categories, ...questions])];

        setLogoProgress(0);
        if (logo) {
          setLogoLoading(true);
          const logoType = logo.name.split('.').pop();
          const filename = `precedent/${new Date().getTime()}.${logoType}`;
          const presignedurlRes = await LucyApi.post(
            '/presignedurl',
            { file: filename },
            {
              headers: {
                Authorization: getAccessToken(),
              },
            },
          );
          await axios.put(presignedurlRes.data.presigned_url, logo, {
            headers: {
              'Content-Type': logo.type,
            },
            onUploadProgress: (progress) => setLogoProgress(Math.round((progress.loaded / progress.total) * 100)),
          });
          data.logo = { name: `${data.precedentId}'s logo`, url: filename };
        }
        const res = await LucyApi.post('/precedent', data, {
          headers: {
            Authorization: getAccessToken(),
          },
        });

        // * Create indexes for tags, categories & questions
        if (termsArray.length > 0) {
          let indexPromiseArray: Promise<AxiosResponse<any>>[] = [];
          termsArray.forEach((tId) => {
            let indexPromise = LucyApi.post(
              '/index',
              {
                tId,
                pId: res.data.data.pId,
              },
              {
                headers: {
                  Authorization: getAccessToken(),
                },
              },
            );
            indexPromiseArray.push(indexPromise);
          });
          await Promise.all(indexPromiseArray);
        }

        LucyToaster('New precedent created.', 'success');
        history.push('/precedent/overview');
      } catch (error) {
        console.log('error', error);
        if (error?.response?.status === 409) {
          setError('precedentId', {
            type: 'manual',
            message: 'Precedent Already exists with the given PID.',
            shouldFocus: true,
          });
        }
      }
      setLogoLoading(false);
    }
  });

  return (
    <div>
      <div className="flex space-x-3 items-center">
        <h1 className="text-xl">Create Precedent</h1>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>

      <div className="max-w-3xl mt-10 mb-10">
        <LucyBackdrop open={isSubmitting} />
        <form onSubmit={onSubmit}>
          <div className="space-y-6">
            <input type="text" ref={register} name="owner" className="hidden" />
            <input type="text" ref={register} name="createdBy" className="hidden" />
            <input type="text" ref={register} name="updatedBy" className="hidden" />
            <div>
              <h2 className="text-lg text-primary-400 mb-4">PID *</h2>
              <TextField
                fullWidth
                variant="outlined"
                name="precedentId"
                inputRef={register}
                error={!!errors.precedentId}
              />
              <p className="text-gray-700 text-sm mt-4">
                You are authoring a Lucy Precedent. Ensure that the Precedent ID (PID) is unique. Revisions may be
                created when desired.
              </p>

              {errors.precedentId && (
                <p className="text-red-500 text-sm font-semibold mt-1">{errors.precedentId.message}</p>
              )}
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Actor</h2>
              <TextField fullWidth variant="outlined" name="actor" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">The primary actor in the precedent.</p>
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Background</h2>
              <TextField fullWidth variant="outlined" multiline rows={4} name="background" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">Background information on the precedent.</p>
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Description</h2>
              <TextField fullWidth variant="outlined" multiline rows={4} name="description" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">
                The descriptive text that helps to set the context for the precedent.
              </p>
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Precedent Title</h2>
              <TextField fullWidth variant="outlined" name="precedentTitle" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">A title that captures the essence of the precedent.</p>
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Precedent Logo</h2>
              <div
                className={`border ${isLogoHoverOnDrop ? 'border-dashed border-gray-300' : 'border-gray-300'} ${
                  logoError ? 'border-red-500' : ''
                } rounded-md`}
                onDragEnter={() => {
                  setLogoHoverOnDrop(true);
                }}
                onDragLeave={() => {
                  setLogoHoverOnDrop(false);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setLogoHoverOnDrop(true);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  setLogoHoverOnDrop(false);
                  setLogo(undefined);
                  const files = Array.from(e.dataTransfer.files);
                  if (files.length > 1) {
                    setLogoError('Drop only One file.');
                  } else if (files.length === 1) {
                    if (!logoMimetypeValues.includes(files[0].type)) {
                      setLogoError('Only png, webp, jpg, and jpeg is allowed.');
                      return;
                    } else if (files[0].size / 1024 / 1024 > logoSizeLimit) {
                      setLogoError(`Logo should be less then ${logoSizeLimit} MB`);
                      return;
                    } else {
                      setLogo(files[0]);
                      setLogoError(undefined);
                      return;
                    }
                  }
                }}
              >
                <input
                  accept="image/*"
                  className="hidden"
                  id="browse-Logo-file-button"
                  type="file"
                  onChange={handleChangeLogoFile}
                />
                <label htmlFor="browse-Logo-file-button">
                  {logo ? (
                    <div className="flex justify-start items-center p-6 cursor-pointer">
                      <div>{truncateReverse(logo.name, 30)}</div>
                    </div>
                  ) : (
                    <div className="flex justify-start space-x-6 items-center p-6 cursor-pointer">
                      <LucyIcon name="download" className="fill-current text-primary-400 h-10" />
                      <p className="text-sm">
                        Click here to upload <span className="font-semibold text-base">Logo</span>
                      </p>
                    </div>
                  )}
                </label>
              </div>
              {isLogoLoading && (
                <div className="flex items-center mt-1">
                  <div className="flex-grow">
                    <LinearProgress variant="determinate" value={logoProgress} />
                  </div>
                  <p className="text-typGreen text-sm ml-2 font-semibold">{`${logoProgress}%`}</p>
                </div>
              )}
              <p className="text-gray-700 text-sm mt-4">
                One file only and {logoSizeLimit} MB limit. Allowed types: png, webp, jpg & jpeg.
              </p>
              {logoError && <p className="text-red-500 text-sm font-semibold mt-1">{logoError}</p>}
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Notes</h2>
              <TextField fullWidth variant="outlined" multiline rows={4} name="notes" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">
                The descriptive text that helps to set the context for the precedent.
              </p>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg text-primary-400">Tags</h2>
              <CreatableSelect
                isClearable
                isMulti
                isSearchable
                isLoading={isTagLoading || tagMethods.isLoading}
                styles={colourStyles}
                value={selectedTags}
                options={tagsOption}
                onChange={handleTagChange}
                onCreateOption={handleTagCreate}
              />
              <p className="text-gray-700 text-sm">
                These are general tags of any type at all, to help you classify and find Lucy precedents according to
                any criteria you wish.
              </p>
            </div>
            <div className="space-y-2">
              <h2 className="text-lg text-primary-400">Categories</h2>
              <FormGroup>
                {categoryMethods.data && categoryMethods.data.data.length > 0
                  ? categoryMethods.data.data.map((c) => (
                      <FormControlLabel
                        key={c.tId}
                        control={
                          <Checkbox
                            color="primary"
                            name={c.tId.toString()}
                            checked={selectedCategories[c.tId] ? true : false}
                            onChange={handleCategoryChange}
                          />
                        }
                        label={c.tTitle}
                      />
                    ))
                  : !categoryMethods.isLoading && <p className="text-red-500 text-sm">No categories found.</p>}
              </FormGroup>
              <p className="text-gray-700 text-sm">
                These are the categories into which the Lucy precedent falls, in various dimensions.
              </p>
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Questions</h2>
              <Divider />
              <div className="space-y-4 mb-6 mt-4">
                {questionMethods.data &&
                  selectedQuestions.map((q) => (
                    <AddQuestionIndex
                      key={q.id}
                      currentQuestion={q}
                      selectedQuestions={selectedQuestions}
                      setSelectedQuestions={setSelectedQuestions}
                      level1Data={questionMethods.data}
                      isError={!!questionsError[q.id]}
                    />
                  ))}
              </div>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                endIcon={<LucyIcon name="plus" className="h-6 fill-current" />}
                onClick={() => {
                  setSelectedQuestions([
                    ...selectedQuestions,
                    {
                      id: v4(),
                      tId: 0,
                    },
                  ]);
                }}
              >
                Add Question
              </Button>
            </div>

            <div>
              <h2 className="text-lg text-primary-400 mb-4">Insights</h2>
              <Divider />
              <div className="space-y-4 mb-6 mt-4">
                {insightFieldArray.fields.map((item, index) => (
                  <div key={item.id} className="space-y-4 bg-gray-100 mb-6 p-6">
                    <div>
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-primary-400">Insight Bold</h2>
                        <button className="text-red-500 font-semibold" onClick={() => insightFieldArray.remove(index)}>
                          Remove
                        </button>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name={`insights[${index}].bold`}
                        inputRef={register}
                        error={!!errors?.['insights']?.[index]?.['bold']}
                      />
                      {errors?.['insights']?.[index]?.['bold']?.['message'] && (
                        <p className="text-red-500 text-sm font-semibold mt-1">
                          {errors?.['insights']?.[index]?.['bold']?.['message']}
                        </p>
                      )}
                    </div>
                    <div>
                      <h2 className="text-primary-400 mb-4">Insight Text</h2>
                      <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        name={`insights[${index}].text`}
                        inputRef={register}
                        error={!!errors?.['insights']?.[index]?.['text']}
                      />
                      {errors?.['insights']?.[index]?.['text']?.['message'] && (
                        <p className="text-red-500 text-sm font-semibold mt-1">
                          {errors?.['insights']?.[index]?.['text']?.['message']}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                endIcon={<LucyIcon name="plus" className="h-6 fill-current" />}
                onClick={() => {
                  insightFieldArray.append({
                    bold: '',
                    text: '',
                  });
                }}
              >
                Add Insight
              </Button>
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Outcomes</h2>
              <Divider />
              <div className="space-y-4 mb-6 mt-4">
                {outcomeFieldArray.fields.map((item, index) => (
                  <div key={item.id} className="space-y-4 bg-gray-100 mb-6 p-6">
                    <div>
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-primary-400">Outcome Bold</h2>
                        <button className="text-red-500 font-semibold" onClick={() => outcomeFieldArray.remove(index)}>
                          Remove
                        </button>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name={`outcomes[${index}].bold`}
                        inputRef={register}
                        error={!!errors?.['outcomes']?.[index]?.['bold']}
                      />
                      {errors?.['outcomes']?.[index]?.['bold']?.['message'] && (
                        <p className="text-red-500 text-sm font-semibold mt-1">
                          {errors?.['outcomes']?.[index]?.['bold']?.['message']}
                        </p>
                      )}
                    </div>
                    <div>
                      <h2 className="text-primary-400 mb-4">Outcome Text</h2>
                      <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        name={`outcomes[${index}].text`}
                        inputRef={register}
                        error={!!errors?.['outcomes']?.[index]?.['text']}
                      />
                      {errors?.['outcomes']?.[index]?.['text']?.['message'] && (
                        <p className="text-red-500 text-sm font-semibold mt-1">
                          {errors?.['outcomes']?.[index]?.['text']?.['message']}
                        </p>
                      )}
                    </div>
                    <div>
                      <h2 className="text-primary-400 mb-4">Source</h2>
                      <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        name={`outcomes[${index}].source`}
                        inputRef={register}
                        error={!!errors?.['outcomes']?.[index]?.['source']}
                      />
                      {errors?.['outcomes']?.[index]?.['source']?.['message'] && (
                        <p className="text-red-500 text-sm font-semibold mt-1">
                          {errors?.['outcomes']?.[index]?.['source']?.['message']}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                endIcon={<LucyIcon name="plus" className="h-6 fill-current" />}
                onClick={() => {
                  outcomeFieldArray.append({
                    bold: '',
                    text: '',
                    source: '',
                  });
                }}
              >
                Add Outcome
              </Button>
            </div>

            <div>
              <h2 className="text-lg text-primary-400 mb-4">Completion Status</h2>
              <ReactHookFormSelect
                name="completionStatus"
                variant="outlined"
                error={!!errors.completionStatus}
                control={control}
              >
                <MenuItem value="" disabled>
                  <em>None</em>
                </MenuItem>
                {Object.entries(completionStatusValues).map((status, i) => (
                  <MenuItem key={i} value={status[0]}>
                    {status[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
              <p className="text-gray-700 text-sm mt-4">This is the level of completion of the precedent.</p>
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Precedent Status</h2>
              <ReactHookFormRadio name="precedentStatus" error={!!errors.precedentStatus} control={control}>
                {Object.entries(precedentStatusValues).map((status, i) => (
                  <FormControlLabel key={i} value={status[0]} label={status[1]} control={<Radio color="primary" />} />
                ))}
              </ReactHookFormRadio>
              <p className="text-gray-700 text-sm mt-4">
                For use only by Lucy Team Leaders, for indicating various forms of status (e.g. Approved, Needs Work,
                etc.)
              </p>
            </div>
          </div>
          <div className="my-10">
            <Divider />
          </div>
          <div className="flex items-center justify-between">
            <FormControlLabel
              control={
                <Controller
                  name="isPublished"
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="Published"
            />
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || isLogoLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePrecedent;
