import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { LucyApi } from '../../../config';
import { IPaginationInfo, IPrecedentResponse } from '../../../types';
import { useQuery } from 'react-query';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';
import AllPrecedent from './All_Insight_Outcome/AllPrecedent';
import ViewInsights from './All_Insight_Outcome/ViewInsights';
import ViewOutcomes from './All_Insight_Outcome/ViewOutcomes';
import LucyIcon from '../../../components/icons';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

interface IPrecedentList {
  data: IPrecedentResponse[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  pageSize: number;
  pageNumber: number;
}

const fetchPrecedents = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/precedent?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const InsightsOutcomes = () => {
  const classes = useStyles();
  const [filterPrecedent, setFilterPrecedent] = useState('All');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterPrecedent(event.target.value as string);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const precedents = useQuery<IPrecedentList, Error>(
    ['precedent-list', { pageSize, pageNumber }],
    async (context) => {
      return await fetchPrecedents(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
  return (
    <div className="md:flex space-y-4 md:space-y-0">
      <div className=" bg-gray-100 py-4 px-6 space-y-5 h-full">
        <p className="text-sm text-primary-400 font-semibold">Filter by Insights / Outcomes</p>
        <FormControl color="primary" variant="outlined" className={classes.formControl}>
          <InputLabel id="view-select-filled-label">View</InputLabel>
          <Select
            labelId="view-select-filled-label"
            id="view-select-filled"
            name="view"
            label="View"
            value={filterPrecedent}
            onChange={handleChange}
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'View_Insights'}>Insights</MenuItem>
            <MenuItem value={'View_Outcomes'}>Outcomes</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="space-y-5 px-6 w-full">
        <p className="text-xl">Summary View of Insights and Outcomes</p>
        <div className="flex flex-wrap justify-between items-baseline">
          {precedents.data && (
            <p className="text-sm text-gray-400">
              Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
              {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
              {precedents.data.paginationInfo.totalElements}
            </p>
          )}
          {precedents.data && (
            <div className="flex">
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                disabled={!precedents.data.paginationInfo.previous}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                  precedents.data.paginationInfo.previous
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-left" className="fill-current h-6" />
              </button>
              <button
                onClick={() => setPageNumber((prev) => prev + 1)}
                disabled={!precedents.data.paginationInfo.next}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                  precedents.data.paginationInfo.next
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-right" className="fill-current h-6" />
              </button>
            </div>
          )}
        </div>
        <div className="max-w-full">
          {precedents.data && precedents.data.data.length > 0 ? (
            <div>
              {filterPrecedent === 'All' && <AllPrecedent precedents={precedents.data} />}
              {filterPrecedent === 'View_Insights' && <ViewInsights precedents={precedents.data} />}
              {filterPrecedent === 'View_Outcomes' && <ViewOutcomes precedents={precedents.data} />}
            </div>
          ) : (
            !precedents.isLoading && (
              <div>
                <p>No Precedents Found.</p>
              </div>
            )
          )}
        </div>
        <div className="flex flex-wrap justify-between items-baseline">
          {precedents.data && (
            <p className="text-sm text-gray-400">
              Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
              {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
              {precedents.data.paginationInfo.totalElements}
            </p>
          )}
          {precedents.data && (
            <div className="flex">
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                disabled={!precedents.data.paginationInfo.previous}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                  precedents.data.paginationInfo.previous
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-left" className="fill-current h-6" />
              </button>
              <button
                onClick={() => setPageNumber((prev) => prev + 1)}
                disabled={!precedents.data.paginationInfo.next}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                  precedents.data.paginationInfo.next
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-right" className="fill-current h-6" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightsOutcomes;
