import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import LucyIcon from '../../../components/icons';
import { LucyApi } from '../../../config';
import { IPaginationInfo } from '../../../types';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';

interface IQuestionLinkedPrecedent {
  tTitle: string;
  tId: number;
  precedent_count: number;
}

interface IPrecedentList {
  data: IQuestionLinkedPrecedent[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  pageSize: number;
  pageNumber: number;
  sort_order: string;
}

const fetchQuestion = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/term_data/filter/question-linked-precedent?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const FilterByQuestionTreeLinkedPrecedent = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const [sort_order, setQuestionOrder] = React.useState('DESC');

  const question = useQuery<IPrecedentList, Error>(
    ['question-tree-precedent-list', { pageSize, pageNumber, sort_order }],
    async (context) => {
      return await fetchQuestion(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return (
    <div className="space-y-6 md:space-y-0 md:flex mx-auto">
      <div className="bg-gray-100 py-4 px-6 space-y-5 md:w-96 h-full">
        <p className="text-sm text-primary-400 font-semibold">Filter by</p>
        <div className="flex flex-col divide-y divide-gray-400 space-y-6">
          <div className="space-y-5">
            <FormControl variant="filled" className="w-full">
              <InputLabel id="demo-simple-select-filled-label">Order</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={sort_order}
                onChange={(e) => {
                  setQuestionOrder(e.target.value as string);
                  setPageNumber(1);
                }}
              >
                <MenuItem value={'ASC'}>ASC </MenuItem>
                <MenuItem value={'DESC'}>DESC</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="w-full space-y-5 px-6">
        <p className="text-xl">Question Tree Linked Precedent</p>
        <div className="flex flex-wrap justify-between items-baseline">
          {question.data && (
            <p className="text-sm text-gray-400">
              Precedent {calculatePaginationFromTo(question.data.paginationInfo).from} -{' '}
              {calculatePaginationFromTo(question.data.paginationInfo).to} of{' '}
              {question.data.paginationInfo.totalElements}
            </p>
          )}
          {question.data && (
            <div className="flex">
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                disabled={!question.data.paginationInfo.previous}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                  question.data.paginationInfo.previous
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-left" className="fill-current h-6" />
              </button>
              <button
                onClick={() => setPageNumber((prev) => prev + 1)}
                disabled={!question.data.paginationInfo.next}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                  question.data.paginationInfo.next
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-right" className="fill-current h-6" />
              </button>
            </div>
          )}
        </div>
        <div>
          {question.data && question.data.data.length > 0 ? (
            question.data.data.map((v) => {
              return (
                <Link
                  key={v.tId}
                  to={`/vocabulary/term/${v.tId}`}
                  className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                >
                  <p className="text-primary-400 mb-4">
                    {v.tTitle} <span>({v.precedent_count})</span>
                  </p>
                </Link>
              );
            })
          ) : question.isLoading ? (
            <p>Loading . . .</p>
          ) : (
            <p>No question found.</p>
          )}
        </div>
        <div className="flex flex-wrap justify-between items-baseline">
          {question.data && (
            <p className="text-sm text-gray-400">
              Precedent {calculatePaginationFromTo(question.data.paginationInfo).from} -{' '}
              {calculatePaginationFromTo(question.data.paginationInfo).to} of{' '}
              {question.data.paginationInfo.totalElements}
            </p>
          )}
          {question.data && (
            <div className="flex">
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                disabled={!question.data.paginationInfo.previous}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                  question.data.paginationInfo.previous
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-left" className="fill-current h-6" />
              </button>
              <button
                onClick={() => setPageNumber((prev) => prev + 1)}
                disabled={!question.data.paginationInfo.next}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                  question.data.paginationInfo.next
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-right" className="fill-current h-6" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterByQuestionTreeLinkedPrecedent;
