import React from 'react';
import { Link } from 'react-router-dom';
import { s3FileUrl } from '../../../../config';
import { IPrecedentResponse } from '../../../../types';

interface IPrecedentList {
  data: IPrecedentResponse[];
  paginationInfo: {
    pageSize: number;
    totalPage: number;
    pageNumber: number;
    totalElements: number;
    numberOfElements: number;
    previous: boolean;
    next: boolean;
  };
}

interface props {
  precedents: IPrecedentList;
}

const ViewOutcomes = ({ precedents }: props) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="bg-gray-100 w-full">
        <thead>
          <tr className=" bg-gray-400 text-white text-left font-semibold italic">
            <th className="border-2 border-white pl-2 ">Precedent ID</th>
            <th className="border-2 border-white pl-2">Logo </th>
            <th className="border-2 border-white pl-2">Actor</th>
            <th className="border-2 border-white pl-2">Outcomes</th>
          </tr>
        </thead>
        <tbody>
          {precedents.data.map(({ precedentId, logo, actor, outcomes, pId }) => (
            <tr key={pId}>
              <td className="border-2 border-white p-3">
                <Link
                  to={`/precedent/${pId}`}
                  className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                >
                  {precedentId}
                </Link>
              </td>
              <td className="border-2 border-white p-3" align="center">
                <div className="h-20 w-20 ring-2 rounded-sm ring-primary-400 overflow-hidden">
                  {logo ? (
                    <img className="w-full h-full object-cover" src={`${s3FileUrl}${logo?.url}`} alt={logo?.name} />
                  ) : (
                    'N / A'
                  )}
                </div>
              </td>
              <td className="border-2 border-white p-3">{actor}</td>
              <td className="border-2 border-white p-3">
                <div>
                  {outcomes && outcomes.length > 0 ? (
                    outcomes.map((v, i) => (
                      <div key={i} className="p-4 bg-green-200 border-2 border-green-900 space-y-2 mb-4">
                        <div className="font-semibold">{v.bold}</div>
                        <div className="pt-4">{v.text}</div>
                      </div>
                    ))
                  ) : (
                    <div className="p-4 bg-green-200 border-2 h-5 border-green-900 space-y-2 mb-4"></div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewOutcomes;
