import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CreatePrecedent from './create';
import PrecedentLanding from './details';
import FilterLanding from './filter';
import OverviewLanding from './overview';

const PrecedentPage = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to={`${path}/create`} />}></Route>
      <Route exact path={`${path}/create`}>
        <CreatePrecedent />
      </Route>
      <Route path={`${path}/overview`}>
        <OverviewLanding />
      </Route>
      <Route path={`${path}/filter-by`}>
        <FilterLanding />
      </Route>
      <Route path={`${path}/:pId`}>
        <PrecedentLanding />
      </Route>
      <Route path="*" render={() => <Redirect to={`${path}/create`} />}></Route>
    </Switch>
  );
};

export default PrecedentPage;
