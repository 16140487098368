import React from 'react';
import { useQuery } from 'react-query';
import { NavLink, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { LucyApi } from '../../../config';
import { CAN } from '../../../permissions';
import { IVocabularyResponse } from '../../../types';
import { getAccessToken } from '../../../utils';
import FieldList from './FieldList';
import TermList from './TermList';
import VocabularyEdit from './VocabularyEdit';

interface params {
  vId: string;
}
const fetchVocabulary = async (vId: string) => {
  const response = await LucyApi.get(`/vocabulary/${vId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const VocabularyLanding = () => {
  const params = useParams<params>();
  let { path } = useRouteMatch();

  const vocabularyDetails = useQuery<IVocabularyResponse, Error>(
    ['vocabulary-details', params.vId],
    async (context) => {
      return await fetchVocabulary(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <div className="flex w-full">
      {vocabularyDetails.data ? (
        <div className="w-full">
          <h1 className="font-medium text-3xl">{vocabularyDetails.data?.vTitle}</h1>
          <div className="space-y-8 mt-4 mb-8">
            <div className="md:flex-row flex flex-col space-y-2 md:space-y-0 ">
              <NavLink
                exact
                to={`/vocabulary/${params.vId}`}
                activeClassName="text-secondary-400"
                className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
              >
                Term List
              </NavLink>
              {CAN('update', 'vocabulary') && (
                <>
                  <NavLink
                    to={`/vocabulary/${params.vId}/edit`}
                    activeClassName="text-secondary-400"
                    className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
                  >
                    Edit
                  </NavLink>
                  <NavLink
                    to={`/vocabulary/${params.vId}/field-list`}
                    activeClassName="text-secondary-400"
                    className="text-center bg-black text-sm text-white hover:bg-gray-900 active:bg-gray-700 focus:outline-none px-4 py-2"
                  >
                    Manage Fields
                  </NavLink>
                </>
              )}
            </div>
          </div>
          <div>
            <Switch>
              <Route exact path={path} component={TermList} />
              <Route exact path={`${path}/edit`} component={VocabularyEdit} />
              <Route exact path={`${path}/field-list`} component={FieldList} />
            </Switch>
          </div>
        </div>
      ) : (
        !vocabularyDetails.isLoading && <div>No vocabularies found.</div>
      )}
    </div>
  );
};

export default VocabularyLanding;
