import React from 'react';
import LucyIcon from '../../components/icons';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@material-ui/core';
import { LucyApi } from '../../config';
import { useHistory } from 'react-router';
import { LucyToaster } from '../../components/Toaster';
import { ICreateRole } from '../../types';
import { LucyBackdrop } from '../../components/Backdrop';
import { getAccessToken } from '../../utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required'),
});

const CreateRole = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm<ICreateRole>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await LucyApi.post('/roles', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      LucyToaster('New role created.', 'success');
      history.push('/role/list');
    } catch (error) {
      if (error?.response?.status === 409) {
        setError('name', {
          type: 'manual',
          message: 'Role already exists with the given name.',
          shouldFocus: true,
        });
      }
    }
  });
  return (
    <div>
      <div className="flex space-x-3 items-center mb-8">
        <p className="text-xl ">Create Role</p>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>
      <LucyBackdrop open={isSubmitting} />
      <form onSubmit={onSubmit}>
        <div className="max-w-3xl my-10 space-y-4">
          <div>
            <p className="text-lg text-primary-400 mb-4 ">Role Name *</p>
            <TextField fullWidth inputRef={register} error={!!errors.name} name="name" variant="outlined" />

            {errors.name && <p className="text-red-600 text-sm font-semibold mt-1">{errors.name.message}</p>}
          </div>
          <div className="flex flex-row-reverse mt-10">
            <Button size="large" variant="contained" color="primary" type="submit">
              Create
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateRole;
