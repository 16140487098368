import React from 'react';
import Fade from '@material-ui/core/Fade/Fade';
import Modal from '@material-ui/core/Modal/Modal';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import LucyIcon from './icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem 1rem',
    },
  }),
);

interface props {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
  title: string;
}

export const LucyModal: React.FC<props> = ({ open, onClose, children, title }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="bg-white rounded-md border focus:outline-none">
          <div className="max-w-lg p-8">
            <div className="flex justify-between items-center mb-6">
              <p className="font-medium text-lg ">{title}</p>
              <div
                className="cursor-pointer bg-white border border-gray-100 inline-block p-2.5 rounded-full shadow-md hover:shadow-lg transition duration-300 ease-in-out"
                onClick={onClose}
              >
                <LucyIcon name="cross" className="h-4 fill-current text-black stroke-current stroke-1" />
              </div>
            </div>
            <div className="overflow-auto" style={{ maxHeight: '70vh' }}>
              {children}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
