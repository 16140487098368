import React from 'react';
import LucyIcon from '../../components/icons';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem, TextField } from '@material-ui/core';
import { LucyApi } from '../../config';
import { useHistory } from 'react-router';
import { LucyToaster } from '../../components/Toaster';
import { ReactHookFormSelect } from '../../components/hooks/ReactHookForm';
import { ICreateVocabulary } from '../../types';
import { LucyBackdrop } from '../../components/Backdrop';
import { getAccessToken } from '../../utils';

const validationSchema = Yup.object().shape({
  vTitle: Yup.string().trim().required('Required'),
  vDescription: Yup.string().trim(),
  vHierarchy: Yup.number().oneOf([0, 1, 2]),
});

const CreateVocabulary = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState: { isSubmitting },
  } = useForm<ICreateVocabulary>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vHierarchy: 0,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await LucyApi.post('/vocabulary', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      LucyToaster('New vocabulary created.', 'success');
      history.push('/vocabulary');
    } catch (error) {
      console.log('error', error);
      if (error?.response?.status === 409) {
        setError('vTitle', {
          type: 'manual',
          message: 'Vocabulary already exists with the given NAME.',
          shouldFocus: true,
        });
      }
    }
  });
  return (
    <div>
      <div className="flex space-x-3 items-center mb-8">
        <p className="text-xl ">Add Vocabulary</p>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>
      <LucyBackdrop open={isSubmitting} />
      <form onSubmit={onSubmit}>
        <div className="max-w-3xl my-10 space-y-4">
          <div>
            <p className="text-lg text-primary-400 mb-4 ">Name *</p>
            <TextField fullWidth inputRef={register} error={!!errors.vTitle} name="vTitle" variant="outlined" />

            {errors.vTitle && <p className="text-red-600 text-sm font-semibold mt-1">{errors.vTitle.message}</p>}
          </div>

          <div className="">
            <p className="text-lg text-primary-400 mb-4">Description</p>
            <TextField
              fullWidth
              inputRef={register}
              error={!!errors.vDescription}
              name="vDescription"
              variant="outlined"
              multiline
              rows={2}
            />
            {errors.vDescription && (
              <p className="text-red-600 text-sm font-semibold mt-1">{errors.vDescription.message}</p>
            )}
          </div>
          <div>
            <h2 className="text-lg text-primary-400 mb-4">Hierarchy *</h2>
            <ReactHookFormSelect name="vHierarchy" variant="outlined" error={!!errors.vHierarchy} control={control}>
              <MenuItem value={0}>No</MenuItem>
              <MenuItem value={1}>Single</MenuItem>
              <MenuItem value={2}>Multiple</MenuItem>
            </ReactHookFormSelect>
          </div>

          <div className="flex flex-row-reverse mt-10">
            <Button size="large" variant="contained" color="primary" type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateVocabulary;
