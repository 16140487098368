import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { createStyles, FormControl, makeStyles, RadioGroup, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

// * Radio Field
interface IRadioProps {
  name: string;
  children: React.ReactNode;
  control: Control;
  error: boolean;
}
export const ReactHookFormRadio: React.FC<IRadioProps> = ({ name, children, control, error }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} component="fieldset" error={error}>
      <Controller name={name} control={control} as={<RadioGroup>{children}</RadioGroup>} />
    </FormControl>
  );
};

// * Select Field
interface ISelectProps {
  name: string;
  children: React.ReactNode;
  control: Control;
  error: boolean;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
  className?: string | undefined;
  readOnly?: boolean;
  defaultValue?: any;
}
export const ReactHookFormSelect: React.FC<ISelectProps> = ({
  name,
  control,
  defaultValue,
  children,
  error,
  className,
  readOnly,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <Controller
        as={
          <Select
            fullWidth
            error={error}
            inputProps={{ readOnly, 'aria-label': 'Without label' }}
            displayEmpty
            className={classes.selectEmpty}
            {...props}
          >
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        className={className}
      />
    </FormControl>
  );
};
