import React, { useState } from 'react';
import {
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { LucyApi } from '../../../config';
import { IPaginationInfo, IPrecedentResponse, IQuestionResponse, ITermResponse } from '../../../types';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';
import LucyIcon from '../../../components/icons';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
  formControl: {
    minWidth: '100%',
  },
});

interface IQueryData {
  pageSize: number;
  pageNumber: number;
  payload?: { parentId: IQuestionResponse['tId'][]; type: 'DC' | 'DA' };
}

interface IData {
  data: {
    pId: IPrecedentResponse['pId'];
    precedentId: IPrecedentResponse['precedentId'];
    actor: IPrecedentResponse['actor'];
    questions: {
      tId: ITermResponse['tId'];
      tTitle: ITermResponse['tTitle'];
      machine_name: ITermResponse['machine_name'];
    }[];
  }[];
  paginationInfo: IPaginationInfo;
}

const fetchData = async ({ payload, ...queryData }: IQueryData) => {
  if (payload && payload.parentId.length > 0 && payload.type) {
    const response = await LucyApi.post(`/term_data/filter/question?${generateQueryString(queryData)}`, payload, {
      headers: {
        Authorization: getAccessToken(),
      },
    });
    return response.data;
  } else {
    const response = await LucyApi.post(`/term_data/filter/question?${generateQueryString(queryData)}`, undefined, {
      headers: {
        Authorization: getAccessToken(),
      },
    });
    return response.data;
  }
};

const fetchQuestions = async (queryData: { questionType: 'MC' | 'DC' | 'DA'; parentId: IQuestionResponse['tId'] }) => {
  const response = await LucyApi.get(`/term_data/view-questions?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const FilterByQuestion = () => {
  const classes = useStyles();
  const [pageSize] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryPayload, setQueryPayload] = useState<IQueryData['payload']>();

  // * question filter
  const [level1, setLevel1] = useState('');
  const [level2, setLevel2] = useState('');
  const [level3, setLevel3] = useState('');

  // * level 1
  const level1QuestionMethods = useQuery<IQuestionResponse[], Error>(
    ['question', { questionType: 'MC', parentId: 0 }],
    async () => {
      return await fetchQuestions({ questionType: 'MC', parentId: 0 });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  const handleLevel1Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLevel1(event.target.value as string);
    setLevel2('');
    setLevel3('');
  };

  // * level 2
  const level2QuestionMethods = useQuery<IQuestionResponse[], Error>(
    ['question', { questionType: 'DC', parentId: parseInt(level1) }],
    async () => {
      return await fetchQuestions({ questionType: 'DC', parentId: parseInt(level1) });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!level1,
    },
  );
  const handleLevel2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLevel2(event.target.value as string);
    setLevel3('');
  };

  // * level 3
  const handleLevel3Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLevel3(event.target.value as string);
  };
  const level3QuestionMethods = useQuery<IQuestionResponse[], Error>(
    ['question', { questionType: 'DA', parentId: parseInt(level2) }],
    async () => {
      return await fetchQuestions({ questionType: 'DA', parentId: parseInt(level2) });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!level2,
    },
  );

  const dataMethods = useQuery<IData, Error>(
    ['filter-questions', { pageSize, pageNumber, queryPayload }],
    async () => {
      return await fetchData({ pageSize, pageNumber, payload: queryPayload });
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const onSubmit = async () => {
    let payload: IQueryData['payload'] = undefined;

    if (!!level1 && !level2 && !level3 && level2QuestionMethods.data && level2QuestionMethods.data.length > 0) {
      payload = {
        type: 'DC',
        parentId: level2QuestionMethods.data.map((v) => v.tId),
      };
    }
    if (!!level1 && !!level2 && !level3 && level3QuestionMethods.data && level3QuestionMethods.data.length > 0) {
      payload = {
        type: 'DA',
        parentId: level3QuestionMethods.data.map((v) => v.tId),
      };
    }
    if (!!level1 && !!level2 && !!level3) {
      payload = {
        type: 'DA',
        parentId: [parseInt(level3)],
      };
    }

    setPageNumber(1);
    setQueryPayload(payload);
  };
  const handleResetForm = () => {
    setLevel1('');
    setLevel2('');
    setLevel3('');
    setPageNumber(1);
    setQueryPayload(undefined);
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-100 space-y-4 py-4 px-6">
        <p className="text-primary-400 font-semibold">Questions</p>
        <div className="flex flex-wrap -m-1">
          <div className="m-1">
            <h2 className="text text-primary-400 mb-1">Meta Challenge</h2>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select fullWidth value={level1} displayEmpty onChange={handleLevel1Change}>
                <MenuItem value="">
                  <em>Please select</em>
                </MenuItem>
                {level1QuestionMethods.data &&
                  level1QuestionMethods.data.map((d) => (
                    <MenuItem key={d.tId} value={d.tId}>
                      {d.tTitle}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {!!level1 && level2QuestionMethods.data && (
            <div className="m-1">
              <h2 className="text text-primary-400 mb-1">Deconstructed Question</h2>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select fullWidth value={level2} displayEmpty onChange={handleLevel2Change}>
                  <MenuItem value="">
                    <em>Please select</em>
                  </MenuItem>
                  {level2QuestionMethods.data.map((d) => (
                    <MenuItem key={d.tId} value={d.tId}>
                      {d.tTitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {!!level2 && level3QuestionMethods.data && (
            <div className="m-1">
              <h2 className="text text-primary-400 mb-1">Disassociated Question</h2>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select fullWidth value={level3} displayEmpty onChange={handleLevel3Change}>
                  <MenuItem value="">
                    <em>Please select</em>
                  </MenuItem>
                  {level3QuestionMethods.data.map((d) => (
                    <MenuItem key={d.tId} value={d.tId}>
                      {d.tTitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
        <p className="text-xs text-gray-700 mt-4 ">Filters selected questions, including any children</p>
        <div className="flex space-x-4">
          <Button onClick={handleResetForm} variant="outlined" color="primary">
            RESET
          </Button>
          <Button onClick={onSubmit} variant="contained" color="primary">
            APPLY
          </Button>
        </div>
      </div>
      <div className="space-y-5 px-6">
        <p className="text-xl">Filter by Questions</p>
        <div className="flex flex-wrap justify-between items-baseline">
          {dataMethods.data && (
            <p className="text-sm text-gray-400">
              Precedent {calculatePaginationFromTo(dataMethods.data.paginationInfo).from} -{' '}
              {calculatePaginationFromTo(dataMethods.data.paginationInfo).to} of{' '}
              {dataMethods.data.paginationInfo.totalElements}
            </p>
          )}
          {dataMethods.data && (
            <div className="flex">
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                disabled={!dataMethods.data.paginationInfo.previous}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                  dataMethods.data.paginationInfo.previous
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-left" className="fill-current h-6" />
              </button>
              <button
                onClick={() => setPageNumber((prev) => prev + 1)}
                disabled={!dataMethods.data.paginationInfo.next}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                  dataMethods.data.paginationInfo.next
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-right" className="fill-current h-6" />
              </button>
            </div>
          )}
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} align="left">
                  Precedent
                </TableCell>
                <TableCell className={classes.header} align="left">
                  Actor
                </TableCell>
                <TableCell className={classes.header} align="left">
                  Questions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataMethods.data && dataMethods.data.data.length > 0 ? (
                dataMethods.data.data.map(({ pId, precedentId, actor, questions }) => (
                  <TableRow key={pId}>
                    <TableCell component="td">
                      <Link
                        to={`/precedent/${pId}`}
                        className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                      >
                        {precedentId}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{actor}</TableCell>
                    <TableCell align="left">
                      <div className="flex flex-wrap -m-1">
                        {questions &&
                          questions.map((v) => (
                            <Link
                              key={v.tId}
                              to={`/vocabulary/term/${v.tId}`}
                              className="text-primary-400 text-sm font-semibold cursor-pointer underline m-1"
                            >
                              {v.tTitle}
                            </Link>
                          ))}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="left" colSpan={4}>
                    {dataMethods.isLoading ? 'Loading . . .' : 'No precedents found.'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex flex-wrap justify-between items-baseline">
          {dataMethods.data && (
            <p className="text-sm text-gray-400">
              Precedent {calculatePaginationFromTo(dataMethods.data.paginationInfo).from} -{' '}
              {calculatePaginationFromTo(dataMethods.data.paginationInfo).to} of{' '}
              {dataMethods.data.paginationInfo.totalElements}
            </p>
          )}
          {dataMethods.data && (
            <div className="flex">
              <button
                onClick={() => setPageNumber((prev) => prev - 1)}
                disabled={!dataMethods.data.paginationInfo.previous}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                  dataMethods.data.paginationInfo.previous
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-left" className="fill-current h-6" />
              </button>
              <button
                onClick={() => setPageNumber((prev) => prev + 1)}
                disabled={!dataMethods.data.paginationInfo.next}
                className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                  dataMethods.data.paginationInfo.next
                    ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <LucyIcon name="arrow-right" className="fill-current h-6" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterByQuestion;
