import { USER_TYPES } from './Types';
import { removeAccessToken, setAccessToken } from '../../utils';
import { ICurrentUserUpdate, IUserResponse } from '../../types';

export const loginAction = (payload: IUserResponse, token: string) => {
  try {
    setAccessToken(token);
    return { type: USER_TYPES.LOGIN, payload };
  } catch (error) {
    console.log('token error', error);
  }
};

export const userDetailsAction = (payload: IUserResponse) => {
  return { type: USER_TYPES.DETAILS, payload };
};

export const userUpdateAction = (payload: ICurrentUserUpdate) => {
  return { type: USER_TYPES.USER_UPDATE, payload };
};

export const logoutAction = () => {
  removeAccessToken();
  return { type: USER_TYPES.LOGOUT };
};
