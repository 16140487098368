import React from 'react';
import { Link } from 'react-router-dom';
import { s3FileUrl } from '../../../../config';
import { IPrecedentResponse } from '../../../../types';

interface IPrecedentList {
  data: IPrecedentResponse[];
  paginationInfo: {
    pageSize: number;
    totalPage: number;
    pageNumber: number;
    totalElements: number;
    numberOfElements: number;
    previous: boolean;
    next: boolean;
  };
}

interface props {
  precedents: IPrecedentList;
}

const AllPrecedent = ({ precedents }: props) => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
      {precedents.data.map(({ precedentId, logo, actor, insights, outcomes, pId }) => {
        return (
          <div key={pId} className="space-y-5 border-b-2 pb-3">
            <Link to={`/precedent/${pId}`} className="text-primary-400 text-sm font-semibold cursor-pointer underline">
              {precedentId}
            </Link>
            <div className="h-20 w-20 ring-2 rounded-sm ring-primary-400 overflow-hidden">
              {logo ? (
                <img className="w-full h-full object-cover" src={`${s3FileUrl}${logo?.url}`} alt={logo?.name} />
              ) : (
                'N / A'
              )}
            </div>
            <p className="text-black text-lg font-semibold">{actor}</p>
            <div>
              <p className="italic">Insights</p>
              {insights && insights.length > 0 ? (
                insights.map((v, i) => (
                  <div key={i} className="p-4 bg-blue-200 border-2 border-blue-900 space-y-2 mb-4">
                    <div className="font-semibold">{v.bold}</div>
                    <div className="pt-4">{v.text}</div>
                  </div>
                ))
              ) : (
                <div className="p-4 bg-blue-200 border-2 h-5 border-blue-900 space-y-2 mb-4"></div>
              )}
            </div>
            <div>
              <p className="italic">Outcomes</p>
              {outcomes && outcomes.length > 0 ? (
                outcomes.map((v, i) => (
                  <div key={i} className="p-4 bg-green-200 border-2 border-green-900 space-y-2 mb-4">
                    <div className="font-semibold">{v.bold}</div>
                    <div className="pt-4">{v.text}</div>
                  </div>
                ))
              ) : (
                <div className="p-4 bg-green-200 border-2 h-5 border-green-900 space-y-2 mb-4"></div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AllPrecedent;
