import React, { useState } from 'react';
import { calculatePaginationFromTo, generateQueryString, getAccessToken } from '../../../utils';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LucyApi } from '../../../config';
import { useQuery } from 'react-query';
import { IPaginationInfo } from '../../../types';
import LucyIcon from '../../../components/icons';

interface IData {
  precedentId: string;
  actor: string;
  pId: number;
  questions: number;
  outcomes: number;
  insights: number;
}

interface IPrecedentList {
  data: IData[];
  paginationInfo: IPaginationInfo;
}

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
});

interface IQueryData {
  pageSize: number;
  pageNumber: number;
  question_thresholds?: number;
  insights_thresholds?: number;
  outcomes_thresholds?: number;
}

const fetchPrecedents = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/term_data/filter/question-insights-outcomes?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const FilterByQuestionInsightOutcome = () => {
  const classes = useStyles();
  const [pageSize] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryValues, setQueryValues] = useState<{
    question_thresholds: IQueryData['question_thresholds'];
    insights_thresholds: IQueryData['insights_thresholds'];
    outcomes_thresholds: IQueryData['outcomes_thresholds'];
  }>({
    question_thresholds: undefined,
    insights_thresholds: undefined,
    outcomes_thresholds: undefined,
  });

  const [questionThreshold, setQuestionThreshold] = useState<number | string>('');
  const [insightThreshold, setInsightThreshold] = useState<number | string>('');
  const [outcomeThreshold, setOutcomeThreshold] = useState<number | string>('');

  const precedents = useQuery<IPrecedentList, Error>(
    ['precedent-list-Question_Insight-Outcome', { pageSize, pageNumber, ...queryValues }],
    async (context) => {
      return await fetchPrecedents(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const onSubmit = async () => {
    const q = typeof questionThreshold === 'number' ? questionThreshold : parseInt(questionThreshold);
    const i = typeof insightThreshold === 'number' ? insightThreshold : parseInt(insightThreshold);
    const o = typeof outcomeThreshold === 'number' ? outcomeThreshold : parseInt(outcomeThreshold);

    let values: {
      question_thresholds: IQueryData['question_thresholds'];
      insights_thresholds: IQueryData['insights_thresholds'];
      outcomes_thresholds: IQueryData['outcomes_thresholds'];
    } = {
      question_thresholds: undefined,
      insights_thresholds: undefined,
      outcomes_thresholds: undefined,
    };
    setPageNumber(1);
    if (Number.isInteger(q)) {
      values.question_thresholds = q;
    }
    if (Number.isInteger(i)) {
      values.insights_thresholds = i;
    }
    if (Number.isInteger(o)) {
      values.outcomes_thresholds = o;
    }

    setQueryValues(values);
  };

  const handleResetForm = () => {
    setQuestionThreshold('');
    setInsightThreshold('');
    setOutcomeThreshold('');
    setPageNumber(1);
    setQueryValues({
      question_thresholds: undefined,
      insights_thresholds: undefined,
      outcomes_thresholds: undefined,
    });
  };

  return (
    <div className="space-y-6 xl:space-y-0 xl:flex mx-auto">
      <div className="xl:w-64 space-y-5 flex-shrink-0 bg-gray-100 py-4 px-6">
        <p className="text-sm text-primary-400 font-semibold">Threshold Filters</p>
        <div className="space-y-5">
          <div>
            <TextField
              fullWidth
              name="question_thresholds"
              label="Questions Threshold"
              variant="outlined"
              type="number"
              value={questionThreshold}
              onChange={(e) =>
                Number.isInteger(parseInt(e.target.value))
                  ? setQuestionThreshold(parseInt(e.target.value))
                  : setQuestionThreshold('')
              }
            />
            <p className="text-xs text-gray-700 mt-4 ">At max this number of questions.</p>
          </div>
          <div>
            <TextField
              fullWidth
              name="insights_thresholds"
              label="Insights Threshold"
              variant="outlined"
              type="number"
              value={insightThreshold}
              onChange={(e) =>
                Number.isInteger(parseInt(e.target.value))
                  ? setInsightThreshold(parseInt(e.target.value))
                  : setInsightThreshold('')
              }
            />
            <p className="text-xs text-gray-700 mt-4 ">At max this number of insights.</p>
          </div>
          <div>
            <TextField
              fullWidth
              name="outcomes_thresholds"
              label="Outcomes Threshold"
              variant="outlined"
              type="number"
              value={outcomeThreshold}
              onChange={(e) =>
                Number.isInteger(parseInt(e.target.value))
                  ? setOutcomeThreshold(parseInt(e.target.value))
                  : setOutcomeThreshold('')
              }
            />
            <p className="text-xs text-gray-700 mt-4 ">At max this number of outcomes.</p>
          </div>
          <div className="flex flex-wrap justify-between">
            <Button onClick={onSubmit} variant="contained" color="primary">
              APPLY
            </Button>
            <Button onClick={handleResetForm} variant="outlined" color="primary">
              RESET
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-1 px-6">
        <div className="space-y-5">
          <p className="text-xl">Filters on Questions / Insights / Outcomes</p>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    Precedent
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Actor
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Questions
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Insights
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Outcomes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {precedents.data && precedents.data.data.length > 0 ? (
                  precedents.data.data.map((v) => (
                    <TableRow key={v.pId}>
                      <TableCell component="td">
                        <Link
                          to={`/precedent/${v.pId}`}
                          className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                        >
                          {v.precedentId}
                        </Link>
                      </TableCell>

                      <TableCell align="left">{v.actor}</TableCell>
                      <TableCell align="left">{v.questions}</TableCell>
                      <TableCell align="left">{v.insights}</TableCell>
                      <TableCell align="left">{v.outcomes}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="left" colSpan={5}>
                      {precedents.isLoading ? 'Loading . . .' : 'No precedents found.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex flex-wrap justify-between items-baseline">
            {precedents.data && (
              <p className="text-sm text-gray-400">
                Precedent {calculatePaginationFromTo(precedents.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(precedents.data.paginationInfo).to} of{' '}
                {precedents.data.paginationInfo.totalElements}
              </p>
            )}
            {precedents.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!precedents.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    precedents.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!precedents.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    precedents.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterByQuestionInsightOutcome;
