import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LucyIcon from '../../components/icons';
import {
  userTypeValues,
  isActiveValues,
  emailRegex,
  lowercaseRegex,
  numericRegex,
  specialCharRegex,
  uppercaseRegex,
  getAccessToken,
  PictureMimetypeValues,
  truncateReverse,
} from '../../utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup } from '@material-ui/core';
import { IRoleResponse, IPaginationInfo } from '../../types';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, LinearProgress } from '@material-ui/core';
import { ICreateUser } from '../../types';
import { ReactHookFormSelect } from '../../components/hooks/ReactHookForm';
import { LucyBackdrop } from '../../components/Backdrop';
import { LucyApi } from '../../config';
import { useHistory } from 'react-router-dom';
import { LucyToaster } from '../../components/Toaster';
import { useState } from 'react';
import axios from 'axios';
// import { makeStyles } from '@material-ui/core/styles';
import { useCallback } from 'react';

const validationSchema = Yup.object().shape({
  userName: Yup.string().trim().required('Required'),
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
  name: Yup.string().trim(),
  userType: Yup.string().required('Required').oneOf(['client', 'cms']),
  isActive: Yup.boolean().required('Required').default(true),
});

interface IRoleList {
  data: IRoleResponse[];
  paginationInfo: IPaginationInfo;
}

const fetchRoles = async () => {
  const response = await LucyApi.get(`/roles`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const CreateUser = () => {
  const history = useHistory();
  const [error, setError] = useState<string>();

  // * Picture
  const [isPictureLoading, setPictureLoading] = useState(false);
  const [pictureProgress, setPictureProgress] = useState(0);
  const [isPictureHoverOnDrop, setPictureHoverOnDrop] = useState(false);
  const [picture, setPicture] = useState<File>();
  const [pictureError, setPictureError] = useState<string>();
  const handleChangePictureFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPicture(undefined);
    if (event.target.files) {
      const image = event.target.files[0];
      if (image) {
        if (!PictureMimetypeValues.includes(image.type)) {
          setPictureError('Only png, webp, jpg, and jpeg is allowed.');
          return;
        } else {
          setPicture(image);
          setPictureError(undefined);
          return;
        }
      }
    }
  };

  const [selectedRoles, setSelectedRoles] = useState<{
    [key: number]: boolean;
  }>({});
  const roleMethods = useQuery<IRoleList, Error>(
    ['role-list'],
    async () => {
      return await fetchRoles();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (roles) => {
        let newData = selectedRoles;
        if (roles.data.length > 0) {
          roles.data.forEach((c) => {
            newData[c.rId] = false;
          });
        }
        setSelectedRoles(newData);
      },
    },
  );
  const handleRoleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRoles((prev) => {
      return { ...prev, [parseInt(event.target.name)]: event.target.checked };
    });
  }, []);

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<ICreateUser>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      userType: 'client',
      isActive: true,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setPictureProgress(0);
      if (picture) {
        setPictureLoading(true);
        const pictureType = picture.name.split('.').pop();
        const filename = `user/${data.userName}.${pictureType}`;
        const presignedurlRes = await LucyApi.post(
          '/presignedurl',
          { file: filename },
          {
            headers: {
              Authorization: getAccessToken(),
            },
          },
        );
        await axios.put(presignedurlRes.data.presigned_url, picture, {
          headers: {
            'Content-Type': picture.type,
          },
          onUploadProgress: (progress) => setPictureProgress(Math.round((progress.loaded / progress.total) * 100)),
        });
        data.picture = filename;
      }

      const createUserResponse = await LucyApi.post('/user', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      const roles: number[] = Object.entries(selectedRoles)
        .filter((v) => v[1])
        .map((v) => parseInt(v[0]));
      if (roles.length > 0) {
        await LucyApi.put(
          '/user_roles',
          {
            uId: createUserResponse.data.data.uId,
            roles,
          },
          {
            headers: {
              Authorization: getAccessToken(),
            },
          },
        );
      }

      LucyToaster('New user created.', 'success');
      history.push('/user/list');
    } catch (error) {
      setError(error?.response?.data?.message || 'Something went wrong!');
    }
  });

  return (
    <div>
      <div className="flex space-x-3 items-center">
        <h1 className="text-xl">Create User</h1>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>

      <div className="max-w-3xl mt-10 mb-10">
        <LucyBackdrop open={isSubmitting} />
        <form onSubmit={onSubmit}>
          <div className="space-y-6">
            <div>
              <h2 className="text-lg text-primary-400 mb-4">User Name</h2>
              <TextField fullWidth variant="outlined" name="userName" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">User name of the user.</p>
              {errors.userName && <p className="text-red-500 text-sm font-semibold mt-1">{errors.userName.message}</p>}
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">First Name</h2>
              <TextField fullWidth variant="outlined" name="firstName" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">First Name of the user.</p>
              {errors.firstName && (
                <p className="text-red-500 text-sm font-semibold mt-1">{errors.firstName.message}</p>
              )}
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Last Name</h2>
              <TextField fullWidth variant="outlined" name="lastName" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">Last Name of the user.</p>
              {errors.lastName && <p className="text-red-500 text-sm font-semibold mt-1">{errors.lastName.message}</p>}
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Email</h2>
              <TextField fullWidth variant="outlined" name="email" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">Email of the user.</p>
              {errors.email && <p className="text-red-500 text-sm font-semibold mt-1">{errors.email.message}</p>}
            </div>
            <div>
              <h2 className="text-lg text-primary-400 mb-4">Password</h2>
              <TextField fullWidth variant="outlined" name="password" inputRef={register} />
              <p className="text-gray-700 text-sm mt-4">Password of the user.</p>
              {errors.password && <p className="text-red-500 text-sm font-semibold mt-1">{errors.password.message}</p>}
            </div>

            <div>
              <h2 className="text-lg text-primary-400 mb-4">User Image</h2>
              <div
                className={`border ${isPictureHoverOnDrop ? 'border-dashed border-gray-300' : 'border-gray-300'} ${
                  pictureError ? 'border-red-500' : ''
                } rounded-md`}
                onDragEnter={() => {
                  setPictureHoverOnDrop(true);
                }}
                onDragLeave={() => {
                  setPictureHoverOnDrop(false);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  setPictureHoverOnDrop(true);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  setPictureHoverOnDrop(false);
                  setPicture(undefined);
                  const files = Array.from(e.dataTransfer.files);
                  if (files.length > 1) {
                    setPictureError('Drop only One file.');
                  } else if (files.length === 1) {
                    if (!PictureMimetypeValues.includes(files[0].type)) {
                      setPictureError('Only png, webp, jpg, and jpeg is allowed.');
                      return;
                    }
                  } else {
                    setPicture(files[0]);
                    setPictureError(undefined);
                    return;
                  }
                }}
              >
                <input
                  accept="image/*"
                  className="hidden"
                  id="browse-Picture-file-button"
                  type="file"
                  onChange={handleChangePictureFile}
                />
                <label htmlFor="browse-Picture-file-button">
                  {picture ? (
                    <div className="flex justify-start items-center p-6 cursor-pointer">
                      <div>{truncateReverse(picture.name, 30)}</div>
                    </div>
                  ) : (
                    <div className="flex justify-start space-x-6 items-center p-6 cursor-pointer">
                      <LucyIcon name="download" className="fill-current text-primary-400 h-10" />
                      <p className="text-sm">
                        Click here to upload <span className="font-semibold text-base">Image</span>
                      </p>
                    </div>
                  )}
                </label>
              </div>
              {isPictureLoading && (
                <div className="flex items-center mt-1">
                  <div className="flex-grow">
                    <LinearProgress variant="determinate" value={pictureProgress} />
                  </div>
                  <p className="text-typGreen text-sm ml-2 font-semibold">{`${pictureProgress}%`}</p>
                </div>
              )}
              {/* <p className="text-gray-700 text-sm mt-4">
                One file only and {logoSizeLimit} MB limit. Allowed types: png, webp, jpg & jpeg.
              </p> */}
              {pictureError && <p className="text-red-500 text-sm font-semibold mt-1">{pictureError}</p>}
            </div>

            <div>
              <h2 className="text-lg text-primary-400 mb-4">Type of User</h2>
              <ReactHookFormSelect name="userType" variant="outlined" error={!!errors.userType} control={control}>
                {Object.entries(userTypeValues).map((status, i) => (
                  <MenuItem key={i} value={status[0]}>
                    {status[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
              <p className="text-gray-700 text-sm mt-4">This is the type of user.</p>
              {errors.userType && <p className="text-red-500 text-sm font-semibold mt-1">{errors.userType.message}</p>}
            </div>
            {watch('userType') === 'cms' && (
              <div className="space-y-2">
                <h2 className="text-lg text-primary-400">Roles</h2>
                <FormGroup>
                  {roleMethods.data && roleMethods.data.data.length > 0
                    ? roleMethods.data.data.map((c) => (
                        <FormControlLabel
                          key={c.rId}
                          control={
                            <Checkbox
                              color="primary"
                              name={c.rId.toString()}
                              checked={selectedRoles[c.rId] ? true : false}
                              onChange={handleRoleChange}
                            />
                          }
                          label={c.name}
                        />
                      ))
                    : !roleMethods.isLoading && <p className="text-red-500 text-sm">No roles found.</p>}
                </FormGroup>
              </div>
            )}
            <div>
              <h2 className="text-lg text-primary-400 mb-4">User active status</h2>
              <ReactHookFormSelect name="isActive" variant="outlined" error={!!errors.isActive} control={control}>
                {Object.entries(isActiveValues).map((status, i) => (
                  <MenuItem key={i} value={status[0]}>
                    {status[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
              <p className="text-gray-700 text-sm mt-4">This is user status.</p>
              {errors.isActive && <p className="text-red-500 text-sm font-semibold mt-1">{errors.isActive.message}</p>}
            </div>

            <br />
          </div>

          <div className="space-y-2">
            {error && <p className="text-red-500 mt-1 text-sm font-semibold">{error}</p>}
            <Button size="large" variant="contained" color="primary" type="submit" disabled={isSubmitting}>
              Create
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateUser;
