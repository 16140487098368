import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import LucyIcon from './components/icons';
import useBreakpoint from './components/hooks/useBreakpoint';
import LucyImg from './resources/images/Lucy_Logo.png';
import LucySmallImg from './resources/images/Lucy_Logo_sm.png';
import userImg from './resources/images/user.png';
import SecondaryApp from './SecondaryApp';
import { Breadcrumbs, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from './stores/IndexReducer';
import { logoutAction } from './stores/user/Actions';
import { LucyModal } from './components/Modal';
import { AccountEdit } from './components/AccountEdit';
import { ChangePassword } from './components/ChangePassword';
import { s3FileUrl } from './config';
import { CAN } from './permissions';

const Layout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const isStatic = useBreakpoint('lg');
  const ref = useRef<HTMLDivElement>(null);
  const [selectedPrecedent, setSelectedPrecedent] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false);
  const [selectedVocabulary, setSelectedVocabulary] = useState(false);
  const refPrecedent = useRef<HTMLDivElement>(null);
  const refUser = useRef<HTMLDivElement>(null);
  const refRole = useRef<HTMLDivElement>(null);
  const refVocabulary = useRef<HTMLDivElement>(null);
  const [breadcrumb, setBreadcrumb] = useState<string[]>([]);
  let location = useLocation().pathname;

  const user = useSelector((state: IReducer) => state.userReducer.user);

  const crumbs = useCallback(() => {
    setBreadcrumb(location.split('/').slice(1));
  }, [location]);

  useEffect(() => {
    crumbs();
  }, [crumbs]);

  // * Account Modal
  const [showAccountModal, setAccountShowModal] = useState(false);
  const handleAccountModalOnClose = useCallback(() => setAccountShowModal(false), []);
  const handleAccountModalOnClick = async () => {
    setAccountShowModal(true);
    handleMenuOnClose();
  };

  // * Change Password Modal
  const [showChangePasswordModal, setChangePasswordShowModal] = useState(false);
  const handleChangePasswordModalOnClose = useCallback(() => setChangePasswordShowModal(false), []);
  const handleChangePasswordModalOnClick = async () => {
    setChangePasswordShowModal(true);
    handleMenuOnClose();
  };

  // * User Menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleMenuOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuOnClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = async () => {
    handleMenuOnClose();
    dispatch(logoutAction());

    history.push('/');
  };

  return (
    <div className="h-screen flex bg-white">
      {open && !isStatic && <div onClick={() => setOpen(false)} className="fixed inset-0 bg-black opacity-60" />}
      <aside
        className={`${
          open ? 'ml-0' : '-ml-64'
        } fixed inset-0 z-40 lg:ml-0 lg:static lg:inset-auto w-64 overflow-hidden flex flex-col bg-primary-400 text-white`}
      >
        <div className="h-20 lg:h-36 flex-shrink-0 flex items-center justify-between lg:justify-center bg-primary-400 border-b border-primary-200 lg:border-transparent text-white px-4 mb-6 lg:mb-0">
          <Link to="/" className="inline-block">
            <img src={LucySmallImg} alt="Lucy small logo" className="lg:hidden" />
            <img src={LucyImg} alt="Lucy big logo" className="hidden lg:inline-block" />
          </Link>
          <button
            aria-label="close menu"
            title="close menu"
            onClick={() => setOpen(false)}
            className="lg:hidden border rounded-lg border-transparent focus:outline-none hover:border-white transition duration-300 ease-out cursor-pointer p-2"
          ></button>
        </div>
        <div className="overflow-auto flex-grow flex flex-col justify-between">
          <div className="flex h-full justify-left my-5">
            <div className="flex flex-col ml-6 w-full space-y-8">
              {/* PRECEDENT START */}
              {CAN('read', 'precedent') && (
                <div>
                  <div className="cursor-pointer" onClick={() => setSelectedPrecedent((v) => !v)}>
                    <div className="flex justify-between space-x-6 mr-6">
                      <div className="flex space-x-3 items-center">
                        <LucyIcon name="differentiation" className="text-white h-8" />
                        <p className="text-lg font-medium">Precedent</p>
                      </div>
                      <span
                        className={`transition transform duration-1000 ease-out ${
                          selectedPrecedent ? 'rotate-180' : 'rotate-0'
                        }`}
                      >
                        <LucyIcon name="arrow-down" className="fill-current h-8 opacity-60" />
                      </span>
                    </div>
                  </div>
                  <div
                    ref={ref}
                    className="relative overflow-hidden transition-all duration-1000"
                    style={{ maxHeight: selectedPrecedent && ref.current ? refPrecedent.current?.scrollHeight : 0 }}
                  >
                    <div ref={refPrecedent} className="ml-12 pt-2 ">
                      {CAN('create', 'precedent') && (
                        <NavLink
                          className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                          activeClassName="bg-white text-primary-400"
                          to="/precedent/create"
                        >
                          <p>Create Precedent</p>
                        </NavLink>
                      )}
                      <NavLink
                        className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                        activeClassName="bg-white text-primary-400"
                        to="/precedent/overview"
                      >
                        <p>Precedent Overview</p>
                      </NavLink>
                      <NavLink
                        className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                        activeClassName="bg-white text-primary-400"
                        to="/precedent/filter-by"
                      >
                        <p>Filter by</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
              {/* PRECEDENT END */}
              {/* VOCABULARY START */}
              {CAN('read', 'vocabulary') && (
                <div>
                  <div className="cursor-pointer" onClick={() => setSelectedVocabulary((v) => !v)}>
                    <div className="flex justify-between space-x-6 mr-6">
                      <div className="flex space-x-3 items-center">
                        <LucyIcon name="differentiation" className="text-white h-8" />
                        <p className="text-lg font-medium">Vocabulary</p>
                      </div>
                      <span
                        className={`transition transform duration-1000 ease-out ${
                          selectedVocabulary ? 'rotate-180' : 'rotate-0'
                        }`}
                      >
                        <LucyIcon name="arrow-down" className="fill-current h-8 opacity-60" />
                      </span>
                    </div>
                  </div>
                  <div
                    ref={ref}
                    className="relative overflow-hidden transition-all duration-700"
                    style={{ maxHeight: selectedVocabulary && ref.current ? refVocabulary.current?.scrollHeight : 0 }}
                  >
                    <div ref={refVocabulary} className="ml-12 pt-2">
                      {CAN('create', 'vocabulary') && (
                        <NavLink
                          className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                          activeClassName="bg-white text-primary-400"
                          to="/vocabulary/create"
                        >
                          <p>Create Vocabulary</p>
                        </NavLink>
                      )}
                      <NavLink
                        exact
                        className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                        activeClassName="bg-white text-primary-400"
                        to="/vocabulary"
                      >
                        <p>Vocabulary List</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
              {/* VOCABULARY END */}
              {/* USER Start */}
              {CAN('read', 'user') && (
                <div>
                  <div className="cursor-pointer" onClick={() => setSelectedUser((v) => !v)}>
                    <div className="flex justify-between space-x-6 mr-6">
                      <div className="flex space-x-3 items-center">
                        <LucyIcon name="differentiation" className="text-white h-8" />
                        <p className="text-lg font-medium">User</p>
                      </div>
                      <span
                        className={`transition transform duration-1000 ease-out ${
                          selectedUser ? 'rotate-180' : 'rotate-0'
                        }`}
                      >
                        <LucyIcon name="arrow-down" className="fill-current h-8 opacity-60" />
                      </span>
                    </div>
                  </div>
                  <div
                    ref={ref}
                    className="relative overflow-hidden transition-all duration-1000"
                    style={{ maxHeight: selectedUser && ref.current ? refUser.current?.scrollHeight : 0 }}
                  >
                    <div ref={refUser} className="ml-12 pt-2">
                      {CAN('create', 'user') && (
                        <NavLink
                          className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                          activeClassName="bg-white text-primary-400"
                          to="/user/create"
                        >
                          <p>Create User</p>
                        </NavLink>
                      )}
                      <NavLink
                        className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                        activeClassName="bg-white text-primary-400"
                        to="/user/list"
                      >
                        <p>User List</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
              {/* USER END */}
              {/* ROLE Start */}
              {CAN('read', 'role') && (
                <div>
                  <div className="cursor-pointer" onClick={() => setSelectedRole((v) => !v)}>
                    <div className="flex justify-between space-x-6 mr-6">
                      <div className="flex space-x-3 items-center">
                        <LucyIcon name="differentiation" className="text-white h-8" />
                        <p className="text-lg font-medium">Role</p>
                      </div>
                      <span
                        className={`transition transform duration-1000 ease-out ${
                          selectedRole ? 'rotate-180' : 'rotate-0'
                        }`}
                      >
                        <LucyIcon name="arrow-down" className="fill-current h-8 opacity-60" />
                      </span>
                    </div>
                  </div>
                  <div
                    ref={ref}
                    className="relative overflow-hidden transition-all duration-1000"
                    style={{ maxHeight: selectedRole && ref.current ? refRole.current?.scrollHeight : 0 }}
                  >
                    <div ref={refRole} className="ml-12 pt-2">
                      {CAN('create', 'role') && (
                        <NavLink
                          className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                          activeClassName="bg-white text-primary-400"
                          to="/role/create"
                        >
                          <p>Create Role</p>
                        </NavLink>
                      )}
                      <NavLink
                        className="block transition duration-300 ease-out py-3 pl-3 hover:bg-white hover:bg-opacity-20 hover:text-white"
                        activeClassName="bg-white text-primary-400"
                        to="/role/list"
                      >
                        <p>Role List</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
              {/* ROLE END */}
            </div>
          </div>
          <div className="sticky bottom-0 flex justify-between items-center bg-primary-400 py-4 pl-3">
            <img
              className="h-10 w-10 object-cover rounded-full"
              src={user?.picture ? `${s3FileUrl}${user?.picture}` : userImg}
              alt="user profile"
            />
            <span>{user?.userName}</span>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuOnClick}>
              <LucyIcon name="list" className="h-6 fill-current text-white" />
            </IconButton>
            <Menu id="long-menu" anchorEl={menuAnchorEl} keepMounted open={isMenuOpen} onClose={handleMenuOnClose}>
              <MenuItem onClick={handleAccountModalOnClick}>Account</MenuItem>
              <MenuItem onClick={handleChangePasswordModalOnClick}>Change password</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </aside>
      <main className="flex-1 flex flex-col overflow-auto">
        <div className="lg:hidden flex items-center bg-primary-400 px-6 py-3 space-x-8">
          <button
            aria-label="open menu"
            title="open menu"
            onClick={() => setOpen(true)}
            className="border rounded-lg border-transparent focus:outline-none hover:border-white transition duration-300 ease-out cursor-pointer p-2"
          >
            <LucyIcon name="menu" className="fill-current text-white h-8" />
          </button>
          <NavLink to="/" className="inline-block">
            <img src={LucySmallImg} alt="Lucy small logo" />
          </NavLink>
        </div>
        <div>
          <div className="flex justify-between items-center py-3 px-6 bg-gray-100">
            <Breadcrumbs
              separator={<LucyIcon name="arrow-right" className="h-5 fill-current text-gray-700" />}
              aria-label="breadcrumb"
            >
              <NavLink className="text-primary-400 font-semibold" to="/">
                Home
              </NavLink>
              {breadcrumb.map((v, i, arr) =>
                breadcrumb.length !== i + 1 ? (
                  <NavLink key={i} className="text-primary-400 font-semibold" to={`/${arr.slice(0, i + 1).join('/')}`}>
                    {v.charAt(0).toUpperCase() + v.slice(1)}
                  </NavLink>
                ) : (
                  <Typography key={i}>{v.charAt(0).toUpperCase() + v.slice(1)}</Typography>
                ),
              )}
            </Breadcrumbs>
            <LucyIcon name="bell" className="h-5 fill-current text-gray-800" />
          </div>
          <SecondaryApp />
        </div>
      </main>
      <LucyModal open={showAccountModal} onClose={handleAccountModalOnClose} title="Account Settings">
        <AccountEdit onClose={handleAccountModalOnClose} />
      </LucyModal>
      <LucyModal open={showChangePasswordModal} onClose={handleChangePasswordModalOnClose} title="Change Password">
        <ChangePassword onClose={handleChangePasswordModalOnClose} />
      </LucyModal>
    </div>
  );
};

export default Layout;
