import { createStyles, FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { LucyApi } from '../../../config';
import { IQuestionResponse, ITermResponse } from '../../../types';
import { generateQueryString, getAccessToken } from '../../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
  }),
);

const fetchQuestions = async (queryData: { questionType: 'MC' | 'DC' | 'DA'; parentId: IQuestionResponse['tId'] }) => {
  const response = await LucyApi.get(`/term_data/view-questions?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

interface IProps {
  level1Data: IQuestionResponse[];
  currentQuestion: {
    id: string;
    tId: ITermResponse['tId'];
    l2tId?: ITermResponse['tId'];
    l1tId?: ITermResponse['tId'];
  };
  selectedQuestions: {
    id: string;
    tId: ITermResponse['tId'];
    l2tId?: ITermResponse['tId'];
    l1tId?: ITermResponse['tId'];
  }[];
  setSelectedQuestions: React.Dispatch<
    React.SetStateAction<
      { id: string; tId: ITermResponse['tId']; l2tId?: ITermResponse['tId']; l1tId?: ITermResponse['tId'] }[]
    >
  >;
  isError: boolean;
}

export const AddQuestionIndex: React.FC<IProps> = ({
  level1Data,
  currentQuestion,
  setSelectedQuestions,
  selectedQuestions,
  isError,
}) => {
  const classes = useStyles();

  const [level1, setLevel1] = useState(() => (currentQuestion.l1tId ? currentQuestion.l1tId.toString() : ''));
  const [level2, setLevel2] = useState(() => (currentQuestion.l2tId ? currentQuestion.l2tId.toString() : ''));
  const [level3, setLevel3] = useState(() => (currentQuestion.tId > 0 ? currentQuestion.tId.toString() : ''));

  const handleLevel1Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLevel1(event.target.value as string);
    setLevel2('');
    setLevel3('');
    if (currentQuestion.tId !== 0) {
      const result = selectedQuestions.map((q) => {
        if (q.id === currentQuestion.id)
          return {
            ...q,
            tId: 0,
          };
        return q;
      });
      setSelectedQuestions(result);
    }
  };
  const handleLevel2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLevel2(event.target.value as string);
    setLevel3('');
    if (currentQuestion.tId !== 0) {
      const result = selectedQuestions.map((q) => {
        if (q.id === currentQuestion.id)
          return {
            ...q,
            tId: 0,
          };
        return q;
      });
      setSelectedQuestions(result);
    }
  };
  const handleLevel3Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLevel3(event.target.value as string);
    const result = selectedQuestions.map((q) => {
      if (q.id === currentQuestion.id)
        return {
          ...q,
          tId: event.target.value as number,
        };
      return q;
    });
    setSelectedQuestions(result);
  };

  const handleRemove = () => {
    const result = selectedQuestions.filter((q) => q.id !== currentQuestion.id);
    setSelectedQuestions(result);
  };

  const level2QuestionMethods = useQuery<IQuestionResponse[], Error>(
    ['question', { questionType: 'DC', parentId: parseInt(level1) }],
    async () => {
      return await fetchQuestions({ questionType: 'DC', parentId: parseInt(level1) });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!level1,
    },
  );
  const level3QuestionMethods = useQuery<IQuestionResponse[], Error>(
    ['question', { questionType: 'DA', parentId: parseInt(level2) }],
    async () => {
      return await fetchQuestions({ questionType: 'DA', parentId: parseInt(level2) });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!level2,
    },
  );

  return (
    <div className="flex justify-between items-center space-x-4 bg-gray-100 mb-6 p-6">
      <div className="flex-1 space-y-4">
        <div>
          <h2 className="text text-primary-400 mb-1">Meta Challenge</h2>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select fullWidth value={level1} displayEmpty onChange={handleLevel1Change}>
              <MenuItem value="" disabled>
                <em>Please select</em>
              </MenuItem>
              {level1Data.map((d) => (
                <MenuItem key={d.tId} value={d.tId}>
                  {d.tTitle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {!!level1 && level2QuestionMethods.data && (
          <div>
            <h2 className="text text-primary-400 mb-1">Deconstructed Question</h2>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select fullWidth value={level2} displayEmpty onChange={handleLevel2Change}>
                <MenuItem value="" disabled>
                  <em>Please select</em>
                </MenuItem>
                {level2QuestionMethods.data.map((d) => (
                  <MenuItem key={d.tId} value={d.tId}>
                    {d.tTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {!!level2 && level3QuestionMethods.data && (
          <div>
            <h2 className="text text-primary-400 mb-1">Disassociated Question</h2>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select fullWidth value={level3} displayEmpty onChange={handleLevel3Change}>
                <MenuItem value="" disabled>
                  <em>Please select</em>
                </MenuItem>
                {level3QuestionMethods.data.map((d) => (
                  <MenuItem key={d.tId} value={d.tId}>
                    {d.tTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {isError && <p className="text-red-500 text-sm font-semibold mt-1">Please select all fields or remove!</p>}
      </div>
      <button className="text-red-500 font-semibold" onClick={handleRemove}>
        Remove
      </button>
    </div>
  );
};
