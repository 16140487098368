import React, { useState } from 'react';
import {
  Button,
  makeStyles,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import LucyIcon from '../../../components/icons';
import { LucyApi } from '../../../config';
import { IVocabularyResponse } from '../../../types';
import { useQuery } from 'react-query';
import { getAccessToken } from '../../../utils';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
});
interface params {
  vId: string;
}
const fetchVocabulary = async (vId: string) => {
  const response = await LucyApi.get(`/vocabulary/${vId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const FieldList = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<params>();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [selectedFname, setSelectedFname] = useState<string>();

  const handleClick = (fname: string) => (event: any) => {
    setSelectedFname(fname);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const vocabularyDetails = useQuery<IVocabularyResponse, Error>(
    ['vocabulary-details', params.vId],
    async (context) => {
      return await fetchVocabulary(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div>
      <div className="md:flex justify-between md:space-x-10 items-center mt-4">
        <p className="text-sm">{vocabularyDetails.data?.vDescription}</p>
        <div className="mt-4 md:mt-0 flex justify-center">
          <Button
            variant="outlined"
            color="primary"
            endIcon={<LucyIcon name="plus" className="h-5 fill-current" />}
            onClick={() => history.push(`/vocabulary/${params.vId}/field/create`)}
          >
            Add Field
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} align="left">
                  Name
                </TableCell>
                <TableCell className={classes.header} align="left">
                  Type
                </TableCell>
                <TableCell className={classes.header} align="right">
                  Operations
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vocabularyDetails.data?.attributes
                ? Object.entries(vocabularyDetails.data.attributes).map((f, i) => (
                    <TableRow key={i}>
                      <TableCell component="td">{f[0]}</TableCell>
                      <TableCell component="td">{f[1]}</TableCell>
                      <TableCell align="right">
                        <Popover
                          id="fields-popover"
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              history.push(`/vocabulary/${params.vId}/field/${selectedFname}/edit`);
                            }}
                          >
                            Edit
                          </MenuItem>
                        </Popover>
                        <div>
                          <button onClick={handleClick(f[0])} className="focus:outline-none ">
                            <LucyIcon name="list" className="h-6 fill-current text-gray-700" />
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : !vocabularyDetails.isLoading && (
                    <TableRow>
                      <TableCell align="left" colSpan={3}>
                        No fields are present yet.
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default FieldList;
