import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateVocabulary from './Create';
import VocabularyLanding from './individual';
import FieldPage from './individual/field';
import TermPage from './individual/term';
import VocabularyList from './List';
import PrecedentsByTerm from './PrecedentsByTerm';

const VocabularyPage = () => {
  let { path } = useRouteMatch();

  return (
    <div className="">
      <Switch>
        <Route exact path={path}>
          <VocabularyList />
        </Route>
        <Route exact path={`${path}/create`}>
          <CreateVocabulary />
        </Route>
        <Route path={`${path}/term/:tId`} component={PrecedentsByTerm} />
        <Route path={`${path}/:vId/term`} component={TermPage} />
        <Route path={`${path}/:vId/field`} component={FieldPage} />
        <Route path={`${path}/:vId`}>
          <VocabularyLanding />
        </Route>
        <Route path="*" render={() => <Redirect to={path} />}></Route>
      </Switch>
    </div>
  );
};

export default VocabularyPage;
