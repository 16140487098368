import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateRole from './CreateRole';
import RoleLanding from './details';
import RoleList from './RoleList';

const RolePage = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to={`${path}/create`} />}></Route>
      <Route exact path={`${path}/create`}>
        <CreateRole />
      </Route>
      <Route path={`${path}/list`}>
        <RoleList />
      </Route>
      <Route path={`${path}/:rId`}>
        <RoleLanding />
      </Route>
      <Route path="*" render={() => <Redirect to={`${path}/create`} />}></Route>
    </Switch>
  );
};

export default RolePage;
