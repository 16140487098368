import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import decode from 'jwt-decode';
import { LucyToaster } from './components/Toaster';
import { LucyApi } from './config';
import Layout from './Layout';
import LoginPage from './pages/login';
import ForgotPassword from './pages/forgotPassword';
import { userDetailsAction, logoutAction } from './stores/user/Actions';
import { ITokenPayload } from './types';
import { getAccessToken, isAuthenticate, isTokenExpired } from './utils';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuthenticate() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: { pathname: location.pathname } },
          }}
        />
      )
    }
  />
);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUser() {
      const token = getAccessToken();
      if (token && !isTokenExpired(token)) {
        try {
          const decodedPayload = decode(token) as ITokenPayload;

          const res = await LucyApi.get(`/user/${decodedPayload.uId}`, {
            headers: {
              Authorization: token,
            },
          });
          const resPermissions = await LucyApi.post(
            '/cms/permissionsbyroles',
            { roles: res.data.roles.map((r: any) => r.rId) },
            {
              headers: {
                Authorization: token,
              },
            },
          );
          res.data.permissions = resPermissions.data;
          dispatch(userDetailsAction(res.data));
        } catch (error) {
          dispatch(logoutAction());
          LucyToaster('Please login, token expired.', 'danger');
        }
      } else {
        dispatch(logoutAction());
      }
    }

    getUser();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" render={() => (isAuthenticate() ? <Redirect to="/" /> : <LoginPage />)} />
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <PrivateRoute path="*">
          <Layout />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
