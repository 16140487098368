import React, { useEffect } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LucyIcon from '../../../../components/icons';
import { IUpdateField, IVocabulary, IVocabularyResponse } from '../../../../types';
import { ReactHookFormSelect } from '../../../../components/hooks/ReactHookForm';
import { fieldValues, getAccessToken } from '../../../../utils';
import { LucyApi } from '../../../../config';
import { LucyToaster } from '../../../../components/Toaster';
import { useHistory, useParams } from 'react-router';
import { LucyBackdrop } from '../../../../components/Backdrop';
import { useQuery } from 'react-query';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Only a-z or A-Z are allowed.')
    .required('Required'),
  type: Yup.string().oneOf(['text', 'number']).required('Required'),
});

const fetchVocabulary = async (vId: string) => {
  const response = await LucyApi.get(`/vocabulary/${vId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

interface IParams {
  vId: string;
  fname: string;
}

const FieldEdit = () => {
  const history = useHistory();
  const params = useParams<IParams>();

  const vocabularyDetails = useQuery<IVocabularyResponse, Error>(
    ['vocabulary-details', params.vId],
    async () => {
      return await fetchVocabulary(params.vId);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<IUpdateField>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      type: 'text',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (vocabularyDetails.data) {
      try {
        const body: { attributes: IVocabulary['attributes'] } = {
          attributes: vocabularyDetails.data.attributes || {},
        };
        body.attributes[data.name] = data.type;
        await LucyApi.put(`/vocabulary/${params.vId}`, body, {
          headers: {
            Authorization: getAccessToken(),
          },
        });
        LucyToaster('Field edited.', 'success');
        history.push(`/vocabulary/${params.vId}/field-list`);
      } catch (error) {
        console.log('error', error);
      }
    }
  });

  useEffect(() => {
    if (vocabularyDetails.data?.attributes) {
      if (vocabularyDetails.data.attributes[params.fname]) {
        reset({
          name: params.fname,
          type: vocabularyDetails.data.attributes[params.fname],
        });
        delete vocabularyDetails.data.attributes[params.fname];
      } else {
        LucyToaster('No field found.', 'danger');
        history.push(`/vocabulary/${params.vId}/field-list`);
      }
    }
  }, [params.fname, params.vId, history, reset, vocabularyDetails.data]);

  return (
    <div>
      <div className="flex space-x-3 items-center mb-8">
        <p className="text-xl ">Edit Field</p>
        <LucyIcon name="star" className="h-6 fill-current text-gray-400" />
      </div>
      <LucyBackdrop open={isSubmitting} />
      <form onSubmit={onSubmit} className="max-w-xl space-y-6">
        <div>
          <h2 className="text-lg text-primary-400 mb-4">Choose Type *</h2>
          <ReactHookFormSelect name="type" variant="outlined" error={!!errors.type} control={control}>
            {Object.entries(fieldValues).map((v, i) => (
              <MenuItem key={i} value={v[0]}>
                {v[1]}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
          {errors.type && <p className="text-red-600 text-sm font-semibold mt-1">{errors.type.message}</p>}
        </div>
        <div>
          <p className="text-lg text-primary-400 mb-4 ">Name *</p>
          <TextField fullWidth inputRef={register} error={!!errors.name} name="name" variant="outlined" />
          {errors.name && <p className="text-red-600 text-sm font-semibold mt-1">{errors.name.message}</p>}
        </div>
        <div className="flex flex-row-reverse">
          <button
            type="submit"
            className="px-6 bg-primary-400 uppercase border font-medium text-white rounded-md focus:outline-none py-2 cursor-pointer opacity-100"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default FieldEdit;
