import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { store } from '../stores';
import { IUserResponse, PermissionActions, PermissionSubjects } from '../types';

export type AppAbilityType = Ability<[PermissionActions, PermissionSubjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

let ability = new Ability<[PermissionActions, PermissionSubjects]>();

export const CAN = (action: PermissionActions, subject: PermissionSubjects) => ability.can(action, subject);

store.subscribe(() => {
  let user = store.getState().userReducer.user;
  ability = new Ability(defineRulesFor(user?.permissions || []));
});

const defineRulesFor = (permissions: IUserResponse['permissions']) => {
  const { can, rules } = new AbilityBuilder(AppAbility);

  // This logic depends on how the
  // server sends you the permissions array
  if (permissions) {
    permissions.forEach((p) => {
      let array = p.permission.split('_');
      if (array.length === 2) {
        const action = array[1] as PermissionActions;
        const subject = array[0] as PermissionSubjects;
        can(action, subject);
      }
    });
  }

  return rules;
};
