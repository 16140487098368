import { Button, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { LucyBackdrop } from '../../../components/Backdrop';
import { ReactHookFormSelect } from '../../../components/hooks/ReactHookForm';
import { LucyApi } from '../../../config';
import { IUpdateVocabulary, IVocabularyResponse } from '../../../types';
import { useQuery } from 'react-query';
import { LucyToaster } from '../../../components/Toaster';
import { getAccessToken } from '../../../utils';

interface params {
  vId: string;
}

const validationSchema = Yup.object().shape({
  vTitle: Yup.string().trim().required('Required'),
  vDescription: Yup.string().trim(),
  vHierarchy: Yup.number().oneOf([0, 1, 2]),
});

const fetchVocabulary = async (vId: string) => {
  const response = await LucyApi.get(`/vocabulary/${vId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const VocabularyEdit = () => {
  const params = useParams<params>();
  const history = useHistory();

  const vocabularyDetails = useQuery<IVocabularyResponse, Error>(
    ['vocabulary-details', params.vId],
    async () => await fetchVocabulary(params.vId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<IUpdateVocabulary>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vHierarchy: 0,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await LucyApi.put(`/vocabulary/${params.vId}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      });
      LucyToaster('Vocabulary updated.', 'success');
      history.push(`/vocabulary/${params.vId}`);
    } catch (error) {
      console.log('error', error);
    }
  });

  useEffect(() => {
    if (vocabularyDetails.data) {
      reset({
        vTitle: vocabularyDetails.data.vTitle,
        vDescription: vocabularyDetails.data.vDescription,
        vHierarchy: vocabularyDetails.data.vHierarchy,
      });
    }
  }, [reset, vocabularyDetails.data]);

  return (
    <div>
      {vocabularyDetails.data ? (
        <div>
          <LucyBackdrop open={isSubmitting} />
          <form onSubmit={onSubmit}>
            <div className="max-w-3xl my-10 space-y-4">
              <div>
                <p className="text-lg text-primary-400 mb-4 ">Name *</p>
                <TextField
                  fullWidth
                  inputRef={register}
                  error={!!errors.vTitle}
                  name="vTitle"
                  variant="outlined"
                  defaultValue={vocabularyDetails.data.vTitle}
                />

                {errors.vTitle && <p className="text-red-600 text-sm font-semibold mt-1">{errors.vTitle.message}</p>}
              </div>

              <div className="">
                <p className="text-lg text-primary-400 mb-4">Description</p>
                <TextField
                  fullWidth
                  inputRef={register}
                  error={!!errors.vDescription}
                  name="vDescription"
                  variant="outlined"
                  defaultValue={vocabularyDetails.data.vDescription}
                  multiline
                  rows={2}
                />
                {errors.vDescription && (
                  <p className="text-red-600 text-sm font-semibold mt-1">{errors.vDescription.message}</p>
                )}
              </div>
              <div>
                <h2 className="text-lg text-primary-400 mb-4">Hierarchy *</h2>
                <ReactHookFormSelect
                  name="vHierarchy"
                  variant="outlined"
                  error={!!errors.vHierarchy}
                  control={control}
                  defaultValue={vocabularyDetails.data.vHierarchy}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Single</MenuItem>
                  <MenuItem value={2}>Multiple</MenuItem>
                </ReactHookFormSelect>
              </div>

              <div className="flex flex-row-reverse mt-10">
                <Button size="large" variant="contained" color="primary" type="submit">
                  Edit
                </Button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default VocabularyEdit;
