import React from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { LucyApi } from '../config';
import { getAccessToken } from '../utils';
import { LucyToaster } from './Toaster';

interface IProps {
  onClose: () => void;
}
interface params {
  pId: string;
}
const deletePrecedentMutation = async (pId: string) => {
  await LucyApi.delete(`/precedent?pId=${pId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
};

export const DeletePrecedent: React.FC<IProps> = ({ onClose }) => {
  const history = useHistory();
  const params = useParams<params>();
  const { mutateAsync } = useMutation(deletePrecedentMutation);
  const handleSuccess = async () => {
    try {
      await mutateAsync(params.pId);
      LucyToaster('Precedent deleted.', 'success');
      history.push('/precedent/filter-by');
    } catch (error) {
      console.error(error);
      LucyToaster('Something went wrong.', 'success');
    }
    onClose();
  };
  return (
    <div>
      <div>
        <div className="text-2xl">Are you sure, delete this precedent?</div>
        <div className="py-11 max-w-xl md:space-y-0 flex justify-between items-right">
          <button onClick={onClose} className="bg-red-500 text-white text-lg px-16 py-2 rounded-md">
            No
          </button>
          <button onClick={handleSuccess} className="bg-green-500 text-white text-lg px-16 py-2 rounded-md">
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};
