import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import {
  calculatePaginationFromTo,
  generateQueryString,
  subscriptionPlanValues,
  isActiveValues,
  userTypeValues,
  getAccessToken,
} from '../../utils';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, TableContainer } from '@material-ui/core';
import { useQuery } from 'react-query';
import { LucyApi } from '../../config';
import { IPaginationInfo, IUserResponse } from '../../types';
import LucyIcon from '../../components/icons';

const useStyles = makeStyles({
  header: {
    fontWeight: 600,
  },
  formControl: {
    width: '100%',
  },
});

interface IUserList {
  data: IUserResponse[];
  paginationInfo: IPaginationInfo;
}

interface IQueryData {
  subscriptionPlan: string;
  isActive: string;
  userType: string;
  pageSize: number;
  pageNumber: number;
}

const fetchUsers = async (queryData: IQueryData) => {
  const response = await LucyApi.get(`/user?${generateQueryString(queryData)}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const UserList = () => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(15);

  const [subscriptionPlan, setSubscriptionPlan] = useState<string>('');
  const [isActive, setIsActive] = useState<string>('');
  const [userTypeState, setUserType] = useState<string>('');

  const handleIsActiveChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageNumber(1);
    setIsActive(event.target.value as string);
  };
  const handleSubscriptionPlan = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageNumber(1);
    setSubscriptionPlan(event.target.value as string);
  };
  const handleUserType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageNumber(1);
    setUserType(event.target.value as string);
  };

  const users = useQuery<IUserList, Error>(
    ['user', { pageSize, pageNumber, subscriptionPlan, isActive, userType: userTypeState }],
    async (context) => {
      return await fetchUsers(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const handleResetForm = () => {
    setPageNumber(1);
    setSubscriptionPlan('');
    setIsActive('');
    setUserType('');
  };

  return (
    <div className="space-y-6 ">
      <div className="space-y-5 bg-gray-100 py-4 px-6">
        <p className="text-sm text-primary-400 font-semibold">Filter user</p>
        <div className="space-y-3">
          <FormControl color="primary" variant="outlined" className={classes.formControl}>
            <InputLabel id="subscriptionPlan-select-filled-label">Subscription Plan</InputLabel>
            <Select
              labelId="subscriptionPlan-select-filled-label"
              id="subscriptionPlan-select-filled"
              name="subscriptionPlan"
              label="Subscription Plan"
              value={subscriptionPlan}
              onChange={handleSubscriptionPlan}
            >
              {Object.entries(subscriptionPlanValues).map((status, i) => (
                <MenuItem key={i} value={status[0]}>
                  {status[1]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Types of subscription Plant</FormHelperText>
          </FormControl>
          <FormControl color="primary" variant="outlined" className={classes.formControl}>
            <InputLabel id="isActive-select-filled-label">Active user</InputLabel>
            <Select
              labelId="isActive-select-filled-label"
              id="isActive-select-filled"
              name="isActive"
              label="Active user"
              value={isActive}
              onChange={handleIsActiveChange}
            >
              {Object.entries(isActiveValues).map((status, i) => (
                <MenuItem key={i} value={status[0]}>
                  {status[1]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Status of user activeness</FormHelperText>
          </FormControl>
          <FormControl color="primary" variant="outlined" className={classes.formControl}>
            <InputLabel id="userType-select-filled-label">Types of user</InputLabel>
            <Select
              labelId="userType-select-filled-label"
              id="userType-select-filled"
              name="userType"
              label="Types of user"
              value={userTypeState}
              onChange={handleUserType}
            >
              {Object.entries(userTypeValues).map((status, i) => (
                <MenuItem key={i} value={status[0]}>
                  {status[1]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Types of user</FormHelperText>
          </FormControl>
          {(subscriptionPlan.length > 0 || isActive.length > 0 || userTypeState.length > 0) && (
            <Button onClick={handleResetForm} variant="outlined" color="primary">
              RESET
            </Button>
          )}
        </div>
      </div>
      <div className="px-6">
        <div className="space-y-5">
          <p className="text-xl">User List</p>
          <div className="flex flex-wrap justify-between items-baseline">
            {users.data && (
              <p className="text-sm text-gray-400">
                User {calculatePaginationFromTo(users.data.paginationInfo).from} -{' '}
                {calculatePaginationFromTo(users.data.paginationInfo).to} of {users.data.paginationInfo.totalElements}
              </p>
            )}
            {users.data && (
              <div className="flex">
                <button
                  onClick={() => setPageNumber((prev) => prev - 1)}
                  disabled={!users.data.paginationInfo.previous}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-l ${
                    users.data.paginationInfo.previous
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-left" className="fill-current h-6" />
                </button>
                <button
                  onClick={() => setPageNumber((prev) => prev + 1)}
                  disabled={!users.data.paginationInfo.next}
                  className={`focus:outline-none bg-gray-300 py-2 px-4 rounded-r ${
                    users.data.paginationInfo.next
                      ? 'opacity-100 cursor-pointer hover:bg-gray-300'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                >
                  <LucyIcon name="arrow-right" className="fill-current h-6" />
                </button>
              </div>
            )}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    Username
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    First Name
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Last Name
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Email
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Subscription Plan
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Type
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Status
                  </TableCell>
                  {userTypeState === 'cms' && (
                    <TableCell className={classes.header} align="left">
                      Roles
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.data && users.data.data.length > 0 ? (
                  users.data.data.map(
                    ({ uId, firstName, lastName, email, userName, subscriptionPlan, userType, isActive, roles }) => (
                      <TableRow key={uId}>
                        <TableCell component="th">
                          <Link
                            to={`/user/${uId}`}
                            className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                          >
                            {userName}
                          </Link>
                        </TableCell>
                        <TableCell align="left">{firstName}</TableCell>
                        <TableCell align="left">{lastName}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{subscriptionPlan}</TableCell>
                        <TableCell align="left">{userType}</TableCell>
                        <TableCell align="left">{isActive ? 'Active' : 'Inactive'}</TableCell>
                        {userTypeState === 'cms' && (
                          <TableCell align="left">
                            {roles && roles.length > 0 ? (
                              roles.map((r, i) => (
                                <div key={i}>
                                  <Link
                                    to={`/role/${r.rId}`}
                                    className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                                  >
                                    {r.name}
                                  </Link>
                                </div>
                              ))
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ),
                  )
                ) : (
                  <TableRow>
                    <TableCell align="left" colSpan={4}>
                      {users.isLoading ? 'Loading . . .' : 'No user found.'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default UserList;
