import React, { useCallback, useState } from 'react';
import { Button, Checkbox } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { LucyApi } from '../../../config';
import { IPermissionResponse, IRoleResponse } from '../../../types';
import { getAccessToken, permissionsData } from '../../../utils';
import { LucyToaster } from '../../../components/Toaster';
import { LucyBackdrop } from '../../../components/Backdrop';

interface params {
  rId: string;
}

const fetchRole = async (rId: string) => {
  const response = await LucyApi.get(`/roles/${rId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const fetchPermissions = async (rId: string) => {
  const response = await LucyApi.get(`/role_permission/${rId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const RolePermission = () => {
  const params = useParams<params>();
  const [isLoading, setIsLoading] = useState(false);

  const roleMethods = useQuery<IRoleResponse, Error>(
    ['role-details', params.rId],
    async (context) => {
      return await fetchRole(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  // * Permissions
  const [selectedPermissions, setSelectedPermissions] = useState<{
    [key: string]: boolean;
  }>({});
  const handlePermissionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPermissions((prev) => {
      return { ...prev, [event.target.name]: event.target.checked };
    });
  }, []);
  useQuery<IPermissionResponse[], Error>(
    ['role-permission', params.rId],
    async (context) => {
      return await fetchPermissions(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!roleMethods.data,
      onSuccess: (permissions) => {
        let newData = selectedPermissions;
        if (permissions.length > 0) {
          permissions.forEach((p) => {
            newData[p.permission] = true;
          });
        }

        setSelectedPermissions(newData);
      },
    },
  );

  const handleSubmit = async () => {
    const permissions = Object.entries(selectedPermissions)
      .filter((v) => v[1])
      .map((v) => v[0]);
    if (permissions.length > 0) {
      setIsLoading(true);
      try {
        await LucyApi.post(
          '/role_permission',
          {
            rId: params.rId,
            permission: permissions,
          },
          {
            headers: {
              Authorization: getAccessToken(),
            },
          },
        );
        LucyToaster('New permissions added.', 'success');
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-5">
      <LucyBackdrop open={isLoading} />
      <p>
        Permissions let you control what users can do and see on your site. You can define a specific set of permissions
        for each role.
      </p>
      <div>
        <div className="sticky top-0 bg-gray-200 flex justify-between border-b-2 border-gray-300 px-6 py-3 z-10">
          <div className="font-semibold">Permissions</div>
          <div className="font-semibold">{roleMethods.data?.name}</div>
        </div>
        <div>
          {permissionsData.map((permission, i) => (
            <div
              key={i}
              className="bg-gray-100 flex justify-between items-center border-t border-b border-gray-300 px-6 py-3"
            >
              <div className="capitalize">
                {permission.node}: <span className="font-semibold">{permission.action}</span>
              </div>
              <Checkbox
                color="primary"
                name={`${permission.node}_${permission.action}`}
                checked={!!selectedPermissions[`${permission.node}_${permission.action}`]}
                onChange={handlePermissionChange}
              />
            </div>
          ))}
        </div>
        <div className="sticky bottom-0 flex flex-row-reverse bg-gray-200 border-t-2 border-gray-300 px-6 py-3 z-10">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RolePermission;
