import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { LucyApi } from '../../../config';
import { IUserResponse } from '../../../types';
import { getAccessToken } from '../../../utils';
import { Link } from 'react-router-dom';

interface params {
  uId: string;
}
const fetchUser = async (uId: string) => {
  const response = await LucyApi.get(`/user/${uId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const UserDetails = () => {
  const params = useParams<params>();
  const s3url = 'https://lucy-resources.s3.amazonaws.com';
  const userDetails = useQuery<IUserResponse, Error>(
    ['user-details', params.uId],
    async (context) => {
      return await fetchUser(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div>
      {userDetails.data ? (
        <div className="space-y-4">
          <div className="space-y-2">
            <p className="text-sm text-gray-500">
              Created at{' '}
              <span className="font-semibold">
                {format(new Date(userDetails.data.createdAt), 'hh:mm a, do MMM, yyyy')}
              </span>
            </p>
            <p className="text-sm text-gray-500">
              Updated at{' '}
              <span className="font-semibold">
                {format(new Date(userDetails.data.updatedAt), 'hh:mm a, do MMM, yyyy')}
              </span>
            </p>
          </div>
          <div className="h-24 w-24 ring-2 rounded-sm ring-primary-400 overflow-hidden m-3">
            <img
              className="w-full h-full object-cover"
              src={`${s3url}/${userDetails.data.picture}`}
              alt={userDetails.data.userName}
            />
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">First Name</h2>
            <p>{userDetails.data.firstName}</p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Last Name</h2>
            <p>{userDetails.data.lastName}</p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Email</h2>
            <p>{userDetails.data.email}</p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Subscription Plan</h2>
            <p>{userDetails.data.subscriptionPlan}</p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Subscription Start</h2>
            <p>{format(new Date(userDetails.data.subscriptionStart), 'hh:mm a, do MMM, yyyy')}</p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Subscription End</h2>
            <p>{format(new Date(userDetails.data.subscriptionEnd), 'hh:mm a, do MMM, yyyy')}</p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Type</h2>
            <p className="capitalize">{userDetails.data.userType}</p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Roles</h2>
            <div className="space-y-2">
              {userDetails.data.roles && userDetails.data.roles.length > 0 ? (
                userDetails.data.roles.map((r, i) => (
                  <div key={i}>
                    <Link
                      to={`/role/${r.rId}`}
                      className="text-primary-400 text-sm font-semibold cursor-pointer underline"
                    >
                      {r.name}
                    </Link>
                  </div>
                ))
              ) : (
                <div>No role</div>
              )}
            </div>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Status</h2>
            <p>{userDetails.data.isActive ? 'Active' : 'Inactive'}</p>
          </div>
        </div>
      ) : userDetails.isLoading ? (
        <p>Loading...</p>
      ) : (
        <p>No User Found.</p>
      )}
    </div>
  );
};

export default UserDetails;
