import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { LucyApi } from '../../../config';
import { IRoleResponse } from '../../../types';
import { getAccessToken } from '../../../utils';

interface params {
  rId: string;
}
const fetchRole = async (rId: string) => {
  const response = await LucyApi.get(`/roles/${rId}`, {
    headers: {
      Authorization: getAccessToken(),
    },
  });
  return response.data;
};

const RoleDetails = () => {
  const params = useParams<params>();
  const roleDetails = useQuery<IRoleResponse, Error>(
    ['role-details', params.rId],
    async (context) => {
      return await fetchRole(context.queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div>
      {roleDetails.data ? (
        <div className="space-y-4">
          <div className="space-y-2">
            <p className="text-sm text-gray-500">
              Created at{' '}
              <span className="font-semibold">
                {format(new Date(roleDetails.data.createdAt), 'hh:mm a, do MMM, yyyy')}
              </span>
            </p>
            <p className="text-sm text-gray-500">
              Updated at{' '}
              <span className="font-semibold">
                {format(new Date(roleDetails.data.updatedAt), 'hh:mm a, do MMM, yyyy')}
              </span>
            </p>
          </div>
          <div className="space-y-2">
            <h2 className="font-semibold">Role Name</h2>
            <p>{roleDetails.data.name}</p>
          </div>
        </div>
      ) : roleDetails.isLoading ? (
        <p>Loading...</p>
      ) : (
        <p>No Roles Found.</p>
      )}
    </div>
  );
};

export default RoleDetails;
