import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LucyIcon from '../../components/icons';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailRegex, lowercaseRegex, numericRegex, specialCharRegex, uppercaseRegex } from '../../utils';
import { LucyApi } from '../../config';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../stores/user/Actions';
import { LucyBackdrop } from '../../components/Backdrop';
import { LucyToaster } from '../../components/Toaster';

interface IData {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState<string>();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = await LucyApi.post('/cms/login', data);
      dispatch(loginAction(res.data.user, res.data.token));
      LucyToaster('Successfully login.', 'success');
      history.push('/');
    } catch (error) {
      setError(error?.response?.data?.message || 'Something went wrong!');
    }
  });

  return (
    <div className="min-h-screen text-white bg-primary-400 flex items-center justify-center relative overflow-auto">
      <LucyBackdrop open={isSubmitting} />
      <div
        className="w-full max-w-sm border-2 border-white rounded-3xl bg-white bg-opacity-10 p-8"
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <p className="font-bold text-2xl md:text-3xl mb-10">Sign in to Lucy™</p>
        <form onSubmit={onSubmit}>
          <div className="relative flex w-full flex-wrap items-stretch mb-5">
            <input
              ref={register}
              name="email"
              id="email"
              type="text"
              placeholder="Email"
              className="px-3 py-3 bg-opacity-10 border-2 border-white outline-none focus:border-primary-400 placeholder-gray-100  text-white relative bg-white rounded-lg text-sm focus:outline-none focus:shadow-outline w-full pr-10"
            />
            {errors.email && <p className="text-secondary-400 ml-1 text-xs font-medium mt-1">{errors.email.message}</p>}

            <span className="z-10 h-full leading-snug font-normal text-center fill-current placeholder-gray-100 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <LucyIcon name="user" className="h-5" />
            </span>
          </div>
          <div className="relative flex w-full flex-wrap items-stretch mb-5">
            <input
              ref={register}
              name="password"
              id="password"
              type={passwordShown ? 'text' : 'password'}
              placeholder="Password"
              className="px-3 py-3 bg-opacity-10 border-2 border-white outline-none focus:border-primary-400 placeholder-gray-100  text-white relative bg-white rounded-lg text-sm focus:outline-none focus:shadow-outline w-full pr-10"
            />
            {errors.password && (
              <p className="text-secondary-400 ml-1 text-xs font-medium mt-1">{errors.password.message}</p>
            )}
            <span className="z-10 h-full leading-snug font-normal text-center fill-current placeholder-gray-100 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
              <div className="cursor-pointer" onClick={togglePasswordVisibility}>
                {passwordShown ? (
                  <LucyIcon name="eye-hidden" className="h-5" />
                ) : (
                  <LucyIcon name="eye-visible" className="h-5" />
                )}
              </div>
            </span>
          </div>

          <div className="mt-6">
            {error && <p className="text-center text-secondary-400 ml-1 text-xs font-medium">{error}</p>}

            <button
              type="submit"
              className="mt-2 w-full rounded-xl px-4 py-3 font-medium text-primary-400 bg-white text-primary-500 hover:bg-secondary-400 hover:text-black"
            >
              Sign In
            </button>
          </div>
          <Link to="/forgot-password" className="block font-normal underline text-xs mt-2">
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
