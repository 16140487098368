import React from 'react';
import { IconProps } from '.';

const SVG = (props: IconProps) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    className={`w-auto ${props.className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 26 25"
    fill="none"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z" />{' '}
  </svg>
);

export default SVG;
